const LocalStorageManager = {
  getDeviceID: () => {
    let deviceID = localStorage.getItem('deviceID');
    if (!deviceID) {
      deviceID = generateDeviceID();
      localStorage.setItem('deviceID', deviceID);
    }
    return deviceID;
  },

  saveReminders: (deviceID, reminders) => {
    localStorage.setItem(deviceID, JSON.stringify(reminders));
  },

  getReminders: (deviceID) => {
    const remindersJSON = localStorage.getItem(deviceID);
    if (remindersJSON) {
      try {
        return JSON.parse(remindersJSON);
      } catch (error) {
        console.error('Error parsing stored reminders:', error);
        return [];
      }
    }
    return [];
  },

  deleteReminder: (deviceID, id) => {
    const reminders = LocalStorageManager.getReminders(deviceID);
    const updatedReminders = reminders.filter((reminder) => reminder.id !== id);
    LocalStorageManager.saveReminders(deviceID, updatedReminders);
  },
};

function generateDeviceID() {
  return `device_${Date.now()}_${Math.floor(Math.random() * 100000)}`;
}

export default LocalStorageManager;
