import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const Footer = () => {

  //For Get Location of the url
  const location = useLocation();
  // Define the active class conditionally
  const getLinkClass = (pageLink) =>
    `footer-link ${location.pathname === pageLink ? 'active' : ''}`;

  return (
    <div>
      <Box className="footer footer-bg">
        <Container maxWidth="xl">
          <Grid container className="row-min" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <div className="footer-section">
                <Typography className="footer-title">
                  Pages
                </Typography>
                <ul className="ul">
                  <li>
                    <Link to="/" className={getLinkClass('/')}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className={getLinkClass('/about')}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className={getLinkClass('/contact')}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="footer-section">
                <Typography className="footer-title">
                  Font Style's
                </Typography>
                <ul className="ul">
                  <li>
                    <Link to="/cool-fonts" className={getLinkClass('/cool-fonts')}>
                      Cool Fonts
                    </Link>
                  </li>
                  <li>
                    <Link to="/cursive-generator" className={getLinkClass('/cursive-generator')}>
                      Cursive Generator
                    </Link>
                  </li>
                  <li>
                    <Link to="/unicode-text-converter" className={getLinkClass('/unicode-text-converter')}>
                      Unicode Text Converter
                    </Link>
                  </li>
                  <li>
                    <Link to="/facebook-fonts" className={getLinkClass('/facebook-fonts')}>
                      Facebook Fonts
                    </Link>
                  </li>
                  <li>
                    <Link to="/fonts-style" className={getLinkClass('/fonts-style')}>
                      Fonts Style
                    </Link>
                  </li>
                  <li>
                    <Link to="/cool-number-fonts" className={getLinkClass('/cool-number-fonts')}>
                      Cool Number Fonts
                    </Link>
                  </li>
                  <li>
                    <Link to="/title-case-converter" className={getLinkClass('/title-case-converter')}>
                      Title Case Converter
                    </Link>
                  </li>
                  <li>
                    <Link to="/small-text-generator" className={getLinkClass('/small-text-generator')}>
                        Small Text Generator
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="footer-section">
                <Typography className="footer-title">
                  Tools
                </Typography>
                <ul className="ul">
                  <li>
                    <Link to="/online-notepad" className={getLinkClass('/online-notepad')}>
                      Online Notepad
                    </Link>
                  </li>
                  <li>
                    <Link to="/word-counter" className={getLinkClass('/word-counter')}>
                      Word Counter
                    </Link>
                  </li>
                  <li>
                    <Link to="/roman-number" className={getLinkClass('/roman-number')}>
                      Roman Number
                    </Link>
                  </li>
                  <li>
                    <Link to="/binary-code-translator" className={getLinkClass('/binary-code-translator')}>
                      Binary Code Translator
                    </Link>
                  </li>
                  <li>
                    <Link to="/nato-phonetic-alphabet" className={getLinkClass('/nato-phonetic-alphabet')}>
                      NATO Phonetic Alphabet
                    </Link>
                  </li>
                  <li>
                    <Link to="/morse-code-translator" className={getLinkClass('/morse-code-translator')}>
                      Morse Code Translator
                    </Link>
                  </li>
                  <li>
                    <Link to="/wingdings-translator" className={getLinkClass('/wingdings-translator')}>
                        Wingdings Translator
                    </Link>
                  </li>
                  <li>
                    <Link to="/online-alarm-clock" className={getLinkClass('/online-alarm-clock')}>
                      Online Alarm Clock
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="footer-section">
                <Typography className="footer-title">
                  Legal
                </Typography>
                <ul className="ul">
                  <li>
                    <Link to="/terms" className={getLinkClass('/terms')}>
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/policy" className={getLinkClass('/policy')}>
                       Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/cookie" className={getLinkClass('/cookie')}>
                      Cookie Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer" className={getLinkClass('/disclaimer')}>
                      Disclaimer
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="copyr_bg">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography className="copy-right" variant="body2" component="p">
                  &copy; {new Date().getFullYear()} Your Website. All rights reserved.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Footer;
