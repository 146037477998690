import axios from 'axios';

const API_URL = 'http://127.0.0.1:8000'; // Replace with your Django API URL

const api = axios.create({
    baseURL: API_URL,
});

// Test
export const getTestData = (message) => {
    const params = message ? {message} : {};

    return api.get('/manipulation/test/', {params});
};


export const postTestData = (message) => {
    return api.post('/manipulation/test/', {message});
};

// String Manipulation

export const postConvertCase = async (option, inputString) => {
  try {
    const response = await api.post('/manipulation/convert-case/', {
      option,
      input_string: inputString,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending request to API:', error);
    throw error;
  }
};

export const sendRequestToAPI = async (option, value) => {
  try {
    const response = await postConvertCase(option, value);
    return response;
  } catch (error) {
    console.error('Error sending request to API:', error);
    throw error;
  }
};