import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    background: "#f5f5f5",
  };

  const titleStyle = {
    fontSize: "3rem",
    marginBottom: "1rem",
  };

  const textStyle = {
    fontSize: "1.2rem",
    marginBottom: "2rem",
  };

  const linkStyle = {
    fontSize: "1.2rem",
    textDecoration: "none",
    color: "#007bff",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>404 - Not Found!</h1>
      <p style={textStyle}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" style={linkStyle}>
        Go to Home Page
      </Link>
    </div>
  );
};

export default NotFound;
