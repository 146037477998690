import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TextInnerDivHorizontal from '../../common/Layouts/TextInnerDivHorizontal';
import TextInnerDivVertical from '../../common/Layouts/TextInnerDivVertical';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import { WhiteCircles, BlackCircles } from '../../features/StringManipulation/TextToCircled'
import {
    mixCharacters1, mixCharacters2, mixCharacters3, mixCharacters4,
    mixCharacters5, mixCharacters6, mixCharacters7
} from '../../features/StringManipulation/TextToDecorative';
import { SquaredLatin, SquaredLatinFilled } from '../../features/StringManipulation/TextToSquare';
import { LatinDoubleStruck, LatinMonoSpace, LatinFraktur, LatinCalligraphy } from '../../features/StringManipulation/TextToLatin';
import { SmallCapsText, EnlargedText, Backtick, FontSytle1, FontStyle2 } from '../../features/StringManipulation/TextToStyle';

// for seo
import SEOComponent from '../../common/SEOComponent';

const FacebookFontGenerator = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
    const [individualCopiedIndex, setIndividualCopiedIndex] = useState(null);

    // All functions and Define the initial texts 
    const initialTexts = [
        "𝕋𝕖𝕩𝕥 𝕥𝕠 𝔽𝕒𝕔𝕖𝕓𝕠𝕠𝕜 𝔽𝕠𝕟𝕥𝕤..", "𝔗𝔢𝔵𝔱 𝔱𝔬 𝔉𝔞𝔠𝔢𝔟𝔬𝔬𝔨 𝔉𝔬𝔫𝔱𝔰..", "𝒯ℯ𝓍𝓉 𝓉ℴ ℱ𝒶𝒸ℯ𝒷ℴℴ𝓀 ℱℴ𝓃𝓉𝓈..", "Ⓣⓔⓧⓣ ⓣⓞ Ⓕⓐⓒⓔⓑⓞⓞⓚ Ⓕⓞⓝⓣⓢ..",
        "🅣🅔🅧🅣 🅣🅞 🅕🅐🅒🅔🅑🅞🅞🅚 🅕🅞🅝🅣🅢..", "🅃🄴🅇🅃 🅃🄾 🄵🄰🄲🄴🄱🄾🄾🄺 🄵🄾🄽🅃🅂..", "🆃🅴🆇🆃 🆃🅾 🅵🅰🅲🅴🅱🅾🅾🅺 🅵🅾🅽🆃🆂..", "ㄒ乇乂ㄒ ㄒㄖ 千卂匚乇乃ㄖㄖҜ 千ㄖ几ㄒ丂..",
        "₮ɆӾ₮ ₮Ø ₣₳₵Ɇ฿ØØ₭ ₣Ø₦₮₴..", "꓄ꏂꉼ꓄ ꓄ꉻ ꄟꋬꏳꏂꍗꉻꉻꀘ ꄟꉻꂚ꓄ꑄ..", "ꋖꏹꋚꋖ ꋖꁏ ꄙꁲꇃꏹꃃꁏꁏꈵ ꄙꁏꋊꋖꌗ..", "Ｔｅｘｔ ｔｏ Ｆａｃｅｂｏｏｋ Ｆｏｎｔｓ..",
        "ᴛᴇxᴛ ᴛᴏ ꜰᴀᴄᴇʙᴏᴏᴋ ꜰᴏɴᴛꜱ..", "`T`e`x`t` `t`o` `F`a`c`e`b`o`o`k` `F`o`n`t`s..", "𝚃𝚎𝚡𝚝 𝚝𝚘 𝙵𝚊𝚌𝚎𝚋𝚘𝚘𝚔 𝙵𝚘𝚗𝚝𝚜..", "𝓣𝐞𝔁𝓽 𝓽σ Ƒā¢𝐞𝓫σσ𝓀 Ƒσ𝓃𝓽𝓼..",
        "Ṭҽ×է էօ Ƒąçҽҍօօҟ Ƒօղէʂ..", "тєχт тσ ƒα¢євσσк ƒσηтѕ..", "†𝐞𝐱𝐭 𝐭𝐨 Ϝą¢𝐞𝓫𝐨𝐨𝐤 Ϝ𝐨𝐧𝐭𝐬..", "†Ǝ✘† †ø ƒДℂƎ♭øøƘ ƒøИ†Ꭶ..",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);
    const TextConversion = (inputText) => {
        const AllFunctionValues = [
            LatinDoubleStruck(inputText),
            LatinFraktur(inputText),
            LatinCalligraphy(inputText),
            WhiteCircles(inputText),
            BlackCircles(inputText),
            SquaredLatin(inputText),
            SquaredLatinFilled(inputText),
            FontSytle1(inputText),
            FontStyle2(inputText),
            mixCharacters6(inputText),
            mixCharacters7(inputText),
            EnlargedText(inputText),
            SmallCapsText(inputText),
            Backtick(inputText),
            LatinMonoSpace(inputText),
            mixCharacters1(inputText),
            mixCharacters2(inputText),
            mixCharacters3(inputText),
            mixCharacters4(inputText),
            mixCharacters5(inputText),

        ];
        // Return the output
        return AllFunctionValues;
    };

    // // Automatically scroll the output textarea to the bottom when outputText changes
    // useEffect(() => {
    //     const outputTextarea = outputTextareaRef.current;
    //     if (outputTextarea) {
    //         outputTextarea.scrollTop = outputTextarea.scrollHeight;
    //     }
    // }, [outputText]);

    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
        // Function Call
        const AllFunctionValues = TextConversion(text);
        // Check if the inputText is empty or null and set outputText accordingly
        setOutputText(text.trim() === null || text.trim() === '' ? [] : AllFunctionValues);
        // Function Call
        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText([]);
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const range = document.createRange();
            range.selectNode(outputDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
            setIndividualCopiedIndex(null);
        }
    };
    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string and join with line breaks
        const fileContent = outputText.map(text => text.replace(/,/g, '')).join('\n\n');
        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'FacebookFont.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);
        setIndividualCopiedIndex(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    const resetIsCopied = () => {
        setIsCopied(false);
    };

    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Make a Bold Statement with Facebook Fonts and Text Styling</h1>
                        <p>Stand out on social media with our Facebook fonts generator. Create eye-catching posts, profile names, and messages using bold and unique text styles that Facebook supports.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TextInnerDivHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                    setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivHorizontal
                    individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivHorizontal
                    resetIsCopied={resetIsCopied}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TextInnerDivVertical
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivVertical
                        individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivVertical
                        resetIsCopied={resetIsCopied}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Facebook Fonts - Elevate Your Social Media Text</h2>
                        <p>Want to make a bold statement on Facebook? Look no further than our Facebook Fonts and Text Styling tools. With our Facebook fonts generator, you can create eye-catching posts, unique profile names, and captivating messages using a variety of bold and stylish text styles that are fully supported by Facebook.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default FacebookFontGenerator;
