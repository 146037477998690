import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import { convertToSingleUnderlined } from '../../features/StringManipulation/TextManipuation';
import TexthtmlDivAutoHorizontal from '../../common/Layouts/TexthtmlDivAutoHorizontal';
import TexthtmlDivAutoVertical from '../../common/Layouts/TexthtmlDivAutoVertical';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
// for seo
import SEOComponent from '../../common/SEOComponent';


const UnderlineText = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(true);

    // All functions and Define the initial texts 
    const initialTexts = [
        "T̲e̲x̲t̲ t̲o̲ U̲n̲d̲e̲r̲l̲i̲n̲e̲ T̲e̲x̲t̲..",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);
    const TextConversion = (inputText) => {
        const AllFunctionValues = [
            convertToSingleUnderlined(inputText)
        ];
        // Return the output
        return AllFunctionValues;
    };
    // Automatically scroll the output textarea to the bottom when outputText changes
    useEffect(() => {
        const outputTextarea = outputTextareaRef.current;
        if (outputTextarea) {
            outputTextarea.scrollTop = outputTextarea.scrollHeight;
        }
    }, [outputText]);
    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
        // Function Call
        const AllFunctionValues = TextConversion(text);
        // Check if the inputText is empty or null and set outputText accordingly
        setOutputText(text.trim() === null || text.trim() === '' ? [] : AllFunctionValues);
        // Function Call
        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
    };
    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText([]);
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const range = document.createRange();
            range.selectNode(outputDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
        }
    };
    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string and join with line breaks
        const fileContent = outputText.map(text => text.replace(/,/g, '')).join('\n\n');
        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'convertCase.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);

        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };

    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Highlight Text with Underline Styling</h1>
                        <p>Easily emphasize text with underlines using our text generator. Create engaging content with added emphasis.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TexthtmlDivAutoHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TexthtmlDivAutoVertical
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Underline Text Generator - Highlight Text Styling</h2>
                        <p>Make your text stand out with underlines using our Text Generator. Whether you want to emphasize certain words or create engaging content with added emphasis, our tool makes it simple to apply underlines to your text.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default UnderlineText;
