import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SeoMeta from './SeoMeta';

const SchemaComponent = () => {
    const location = useLocation();
    const currentPath = location.pathname.replace('/', '');

    const pageSchemaData = SeoMeta.find(data => data.slug === currentPath);
    // DefultSchema
    const defaultSchema = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "World of Fonts | Font Generator, Copy and Paste, Instagram Fonts, and More",
        "description": "Discover a wide array of fonts for every occasion! Our font generator offers copy and paste fonts, Instagram fonts, calligraphy fonts, and more. Get creative with different lettering styles, from cursive fonts to bold and modern fonts. Elevate your content with aesthetic fonts, perfect for Instagram and beyond. Explore our collection now!",
        "url": `${window.location.origin}`,
        "publisher": {
            "@type": "Organization",
            "name": "fontgenerator.co",
            "logo": {
                "@type": "ImageObject",
                "url": "https://fontgenerator.co/static/images/Adds/logo.png",
                "alt": "fontgenerator.co" // Add a descriptive alt text for the logo
            }
        }
    };

    // if (!pageSchemaData) {
    //     return DefultSchema;
    // }

    const schema = pageSchemaData
        ? {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": pageSchemaData.title,
            "description": pageSchemaData.description,
            "url": `${window.location.origin}/${pageSchemaData.slug}`,
            "publisher": {
                "@type": "Organization",
                "name": "fontgenerator.co",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://fontgenerator.co/static/images/Adds/logo.png",
                    "alt": "fontgenerator.co" // Add a descriptive alt text for the logo
                }
            }
        }
        : defaultSchema;

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
                {/* Add canonical link element */}
                {/* <link rel="canonical" href={`${window.location.origin}/${pageSchemaData.slug}`} /> */}
            </Helmet>
        </>
    );
};

export default SchemaComponent;
