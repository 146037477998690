import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TexthtmlDivHorizontal from '../../common/Layouts/TexthtmlDivHorizontal';
import TexthtmlDivVerticale from '../../common/Layouts/TexthtmlDivVerticale';
import Dropdownlist from '../../common/Layouts/TextDropdownlist';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
//Fonts
import {
    FontStyle3, FontStyle5, FontStyle6, FontStyle7, FontStyle8, FontStyle9, GlitchText, TimesNewRomanText,
    SquaredText, NegativeSquaredText, CircledTextBlack, CircledText, FontSytle1, FontStyle2,
    BoldText, ItalicText, BoldItalicText,
    BoldItalicMonospaceText, SansSerifText,

} from '../../features/StringManipulation/TextToStyle';
import {
    plainToIPA, MirrorVisibleText, BackwardsText,
    strikethroughText, SmallCapsText, SuperscriptGenerator,
    convertToSingleUnderlined, convertToDoubleUnderlined, fullWidthCharacters
} from '../../features/StringManipulation/TextManipuation';
import {
    mixCharacters1, mixCharacters2, mixCharacters3, mixCharacters4,
    mixCharacters5, mixCharacters6, mixCharacters7
} from '../../features/StringManipulation/TextToDecorative';
import { LatinDoubleStruck, LatinMonoSpace, LatinFraktur, LatinCalligraphy } from '../../features/StringManipulation/TextToLatin';

// for seo
import SEOComponent from '../../common/SEOComponent';

const TypingStyleFonts = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
    //other input
    const [selectedFontStyle, setSelectedFontStyle] = useState(''); // Initialize with an empty string or a default value


    // All functions and Define the initial texts 
    const initialTexts = [
        "Select Fonts for Text to Font Style... ",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);


    // Automatically scroll the output textarea to the bottom when outputText changes
    useEffect(() => {
        const outputTextarea = outputTextareaRef.current;
        if (outputTextarea) {
            outputTextarea.scrollTop = outputTextarea.scrollHeight;
        }
    }, [outputText]);
    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    // const handleInputChange = (event) => {
    //     const text = event.target.value;
    //     setInputText(text);
    //     if (text.length >= 30000) {
    //         setError('Maximum character limit (30000) reached.');
    //     } else {
    //         setError(null);
    //     }
    //     // Call TextConversion function with repetitions and separator
    //     const fontstyletext = text;
    //     // Check if the inputText is empty or null and set outputText accordingly
    //     setOutputText(text.trim() === '' ? [] : [fontstyletext]);
    //     // Function Call
    //     setShowClearButton(text.length > 0);
    //     setIsCopied(false); // Reset the copied state when input text changes
    // };
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }

        // Check if a dropdown is selected, and call applyFontStyle accordingly
        if (selectedFontStyle) {
            const fontStyledText = applyFontStyle(text, selectedFontStyle);
            setOutputText([fontStyledText]);
        } else {
            // If no dropdown is selected, return plain text
            setOutputText(text.trim() === '' ? [] : [text]);
        }

        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
    };

    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText([]);
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const range = document.createRange();
            range.selectNode(outputDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
        }
    };
    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string and join with line breaks
        const fileContent = outputText.map(text => text.replace(/,/g, '')).join('\n\n');
        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'RepeatText.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);

        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };

    //DropDown List
    // Create a mapping object
    const fontStyles = {
        'FontStyle3': FontStyle3,
        'FontStyle5': FontStyle5,
        'FontStyle6': FontStyle6,
        'FontStyle7': FontStyle7,
        'FontStyle8': FontStyle8,
        'FontStyle9': FontStyle9,
        'GlitchText': GlitchText,
        'TimesNewRomanText': TimesNewRomanText,
        'SquaredText': SquaredText,
        'NegativeSquaredText': NegativeSquaredText,
        'CircledTextBlack': CircledTextBlack,
        'CircledText': CircledText,
        'FontStyle1': FontSytle1,
        'FontStyle2': FontStyle2,
        'plainToIPA': plainToIPA,
        'MirrorVisibleText': MirrorVisibleText,
        'BackwardsText': BackwardsText,
        'strikethroughText': strikethroughText,
        'SmallCapsText': SmallCapsText,
        'SuperscriptGenerator': SuperscriptGenerator,
        'mixCharacters1': mixCharacters1,
        'mixCharacters2': mixCharacters2,
        'mixCharacters3': mixCharacters3,
        'mixCharacters4': mixCharacters4,
        'mixCharacters5': mixCharacters5,
        'mixCharacters6': mixCharacters6,
        'mixCharacters7': mixCharacters7,
        'LatinDoubleStruck': LatinDoubleStruck,
        'LatinMonoSpace': LatinMonoSpace,
        'LatinFraktur': LatinFraktur,
        'LatinCalligraphy': LatinCalligraphy,
        'BoldText': BoldText,
        'ItalicText': ItalicText,
        'BoldItalicText': BoldItalicText,
        'BoldItalicMonospaceText': BoldItalicMonospaceText,
        'SansSerifText': SansSerifText,
        'convertToSingleUnderlined': convertToSingleUnderlined,
        'convertToDoubleUnderlined': convertToDoubleUnderlined,
        'fullWidthCharacters': fullWidthCharacters,

    };

    const DropdownId = 'textstle'; // Set your desired ID here
    const DropdownLabel = 'Select Fonts';
    // Function to handle dropdown change
    // Function to handle dropdown change

    // Function to handle dropdown change
    const handleDropdownChange = (event) => {
        const selectedFont = event.target.value;
        setSelectedFontStyle(selectedFont);
        // Clear the selection after changing the dropdown
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }

        // Reset the copy button state
        setIsCopied(false);
        // Call TextConversion function with selected font style
        const fontStyledText = applyFontStyle(inputText, selectedFont);
        setOutputText([fontStyledText]);

    };

    // Function to apply the selected font style to the input text
    const applyFontStyle = (inputText, selectedFontStyle) => {
        const fontStyleFunction = fontStyles[selectedFontStyle];
        if (fontStyleFunction) {
            // Apply the selected font style to the input text
            return fontStyleFunction(inputText);
        } else {
            // Handle the case when the selected font style is not found
            return inputText;
        }
    };




    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Dive into the World of Font Styles and Design Possibilities</h1>
                        <p>Discover an array of font styles and design options to enhance your text. Our free font styles offer a creative twist to your writing and design projects.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <Dropdownlist
                            handleDropdownChange={handleDropdownChange}
                            DropdownId={DropdownId}
                            Dropdownlistvalue={selectedFontStyle}
                            DropdownLabel={DropdownLabel}
                            selectedFontStyle={selectedFontStyle} // Pass the selected font style as a prop
                            applyFontStyle={applyFontStyle} // Pass the applyFontStyle function as a prop
                        />
                        {/* Other content in your page */}
                    </Box>
                </Grid>
            </Grid>
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <Box>
                    <TexthtmlDivHorizontal
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                    />
                </Box>
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <Box>
                        <TexthtmlDivVerticale
                            showClearButton={showClearButton}
                            handleClearClick={handleClearClick}
                            handleInputChange={handleInputChange}
                            inputText={inputText}
                            error={error}
                            showCopyButton={showCopyButton}
                            outputText={outputText}
                            initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                            handleDownloadClick={handleDownloadClick}
                            handleCopyClick={handleCopyClick}
                            isCopied={isCopied}
                            outputTextareaRef={outputTextareaRef}
                        />
                    </Box>
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Fonts Style - Explore Creative Text Designs</h2>
                        <p>Are you ready to dive into the exciting world of font styles and design possibilities? Our collection of free font styles opens up a world of creative opportunities for your writing and design projects. Explore and discover the perfect style to enhance your text and make your projects truly unique.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default TypingStyleFonts;
