// ToolItem.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const toolItemStyle = {
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  padding: '20px',
  // margin: '10px',
  maxWidth: '350px',
  height: '100%', // Set a fixed height for the grid item
  overflow: 'hidden', // Hide content that overflows
  position: 'relative', // Set the position to relative for absolute positioning of ellipsis
  backgroundColor: '#16160f',
};

const titleStyle = {
  fontSize: '1.2rem',
  margin: '0',
  overflow: 'hidden', // Hide overflowing text
  textOverflow: 'ellipsis', // Display an ellipsis for overflowing text
  color: 'rgb(245 187 2)',
  // whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
};

const descriptionStyle = {
  fontSize: '1rem',
  color: '#555',
  overflow: 'hidden', // Hide overflowing text
  textOverflow: 'ellipsis', // Display an ellipsis for overflowing text
  color: 'rgb(217 212 212)',
  // whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
};

function ToolItem({ to, title, description }) {


  //For Get Location of the url
  const location = useLocation();
  // Define the active class conditionally
  const getallmenuClass = (pageLink) =>
    `home-all-link ${location.pathname === pageLink ? 'active' : ''}`;

  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'royalblue' }}>
      <div style={toolItemStyle}>
        <h3 style={titleStyle} className={`home-all-link ${getallmenuClass(to)}`} >{title}</h3>
        <p style={descriptionStyle}>{description}</p>
      </div>
    </Link>
  );
}

export default ToolItem;
