import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TextInnerDivHorizontal from '../../common/Layouts/TextInnerDivHorizontal';
import TextInnerDivVertical from '../../common/Layouts/TextInnerDivVertical';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';

import {
    LatinSansSerifText, LatinSansSerifTextToBold, LatinSansSerifItalic, LatinSansSerifItalicBold,
    LatinSerifTextToBold, LatinSerifTextToItalic, LatinSerifTextToItalicBold,
    LatinCalligraphy, LatinCalligraphyBold, LatinFraktur, LatinFrakturBold, LatinMonoSpace,
    LatinDoubleStruck
} from '../../features/StringManipulation/TextToLatin';
import { WhiteCircles, BlackCircles } from '../../features/StringManipulation/TextToCircled';
import { SquaredLatin, SquaredLatinFilled } from '../../features/StringManipulation/TextToSquare';
import { GreekPlain } from '../../features/StringManipulation/TextToGreek';
import { fullWidthCharacters, SmallCapsText, SuperscriptGenerator, PlaintoEmoji } from '../../features/StringManipulation/TextManipuation';

// for seo
import SEOComponent from '../../common/SEOComponent';

const UnicodeText = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
    const [individualCopiedIndex, setIndividualCopiedIndex] = useState(null);

    // All functions and Define the initial texts 
    const initialTexts = [
        "𝒯ℯ𝓍𝓉 𝓉ℴ 𝒞𝒶𝓁𝓁𝒾ℊ𝓇𝒶𝓅𝒽𝓎 ..", "𝓣𝓮𝔁𝓽 𝓽𝓸 𝓒𝓪𝓵𝓵𝓲𝓰𝓻𝓪𝓹𝓱𝔂 (𝓑𝓸𝓵𝓭) ..", "𝔗𝔢𝔵𝔱 𝔱𝔬 𝔉𝔯𝔞𝔨𝔱𝔲𝔯 ..", "𝕿𝖊𝖝𝖙 𝖙𝖔 𝕱𝖗𝖆𝖐𝖙𝖚𝖗 (𝕭𝖔𝖑𝖉) ..",
        "𝕋𝕖𝕩𝕥 𝕥𝕠 𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜 ..", "𝚃𝚎𝚡𝚝 𝚝𝚘 𝙼𝚘𝚗𝚘𝚂𝚙𝚊𝚌𝚎 ..", "Ⓣⓔⓧⓣ ⓣⓞ Ⓦⓗⓘⓣⓔ Ⓒⓘⓡⓒⓛⓔⓢ ..", "🅣🅔🅧🅣 🅣🅞 🅑🅛🅐🅒🅚 🅒🅘🅡🅒🅛🅔🅢 ...",
        "🅃🄴🅇🅃 🅃🄾 🅂🅀🅄🄰🅁🄴🄳 ..", "🆃🅴🆇🆃 🆃🅾 🆂🆀🆄🅰🆁🅴🅳 🅵🅸🅻🅻🅴🅳 ..", "𝖳𝖾𝗑𝗍 𝗍𝗈 𝖲𝖺𝗇𝗌 𝖲𝖾𝗋𝗂𝖿 ..",
        "𝗧𝗲𝘅𝘁 𝘁𝗼 𝗦𝗮𝗻𝘀 𝗦𝗲𝗿𝗶𝗳 (𝗕𝗼𝗹𝗱) ..", "𝘛𝘦𝘹𝘵 𝘵𝘰 𝘚𝘢𝘯𝘴 𝘚𝘦𝘳𝘪𝘧 (𝘐𝘵𝘢𝘭𝘪𝘤) ..", "𝙏𝙚𝙭𝙩 𝙩𝙤 𝙎𝙖𝙣𝙨 𝙎𝙚𝙧𝙞𝙛 𝙄𝙩𝙖𝙡𝙞𝙘 (𝘽𝙤𝙡𝙙) ..", "Ｔｅｘｔ ｔｏ Ｆｕｌｌ Ｗｉｄｔｈ ..", "ᴛᴇxᴛ ᴛᴏ ᴄᴀᴘɪᴛᴀʟɪᴢᴇᴅ ..", "ᵀᵉˣᵗ ᵗᵒ ˢᵘᵖᵉʳ ˢᶜʳⁱᵖᵗ ..",
        "𝐓𝐞𝐱𝐭 𝐭𝐨 𝐒𝐞𝐫𝐢𝐟 (𝐁𝐨𝐥𝐝) ..",
        "𝑇𝑒𝑥𝑡 𝑡𝑜 𝑆𝑒𝑟𝑖𝑓 𝐼𝑡𝑎𝑙𝑖𝑐 ..", "𝑻𝒆𝒙𝒕 𝒕𝒐 𝑺𝒆𝒓𝒊𝒇 𝑰𝒕𝒂𝒍𝒊𝒄 (𝑩𝒐𝒍𝒅) ..", "🇹🇪❌🇹 🇹🅾️ 🇪🇲🅾️🇯ℹ️💲 ..", "Τεξτ το Γρεεκ ..",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);
    const TextConversion = (inputText) => {
        const AllFunctionValues = [

            LatinCalligraphy(inputText),
            LatinCalligraphyBold(inputText),
            LatinFraktur(inputText),
            LatinFrakturBold(inputText),
            LatinDoubleStruck(inputText),
            LatinMonoSpace(inputText),
            WhiteCircles(inputText),
            BlackCircles(inputText),
            SquaredLatin(inputText),
            SquaredLatinFilled(inputText),
            LatinSansSerifText(inputText),
            LatinSansSerifTextToBold(inputText),
            LatinSansSerifItalic(inputText),
            LatinSansSerifItalicBold(inputText),
            fullWidthCharacters(inputText),
            SmallCapsText(inputText),
            SuperscriptGenerator(inputText),
            LatinSerifTextToBold(inputText),
            LatinSerifTextToItalic(inputText),
            LatinSerifTextToItalicBold(inputText),
            PlaintoEmoji(inputText),
            GreekPlain(inputText),
        ];
        // Return the output
        return AllFunctionValues;
    };

    // // Automatically scroll the output textarea to the bottom when outputText changes
    // useEffect(() => {
    //     const outputTextarea = outputTextareaRef.current;
    //     if (outputTextarea) {
    //         outputTextarea.scrollTop = outputTextarea.scrollHeight;
    //     }
    // }, [outputText]);

    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
        // Function Call
        const AllFunctionValues = TextConversion(text);
        // Check if the inputText is empty or null and set outputText accordingly
        setOutputText(text.trim() === null || text.trim() === '' ? [] : AllFunctionValues);
        // Function Call
        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText([]);
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const range = document.createRange();
            range.selectNode(outputDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
            setIndividualCopiedIndex(null);
        }
    };
    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string and join with line breaks
        const fileContent = outputText.map(text => text.replace(/,/g, '')).join('\n\n');
        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'FacebookFont.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);
        setIndividualCopiedIndex(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    const resetIsCopied = () => {
        setIsCopied(false);
    };

    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Unlock Text Magic with Our Unicode Text Converter</h1>
                        <p> Explore the world of unique characters and symbols using our Unicode text converter. Convert your text into fancy and decorative fonts for a captivating presentation.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TextInnerDivHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                    setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivHorizontal
                    individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivHorizontal
                    resetIsCopied={resetIsCopied}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TextInnerDivVertical
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivVertical
                        individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivVertical
                        resetIsCopied={resetIsCopied}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Unicode Text Converter - Unlock Text Magic</h2>
                        <p>Dive into the world of text magic with our Unicode Text Converter. This tool allows you to explore a vast array of unique characters and symbols, enabling you to convert your text into fancy and decorative fonts that will captivate your audience and make your presentation truly captivating.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default UnicodeText;
