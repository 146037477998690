import React from 'react';
import { Grid } from '@mui/material';
import ToolItem from './ToolItem'; // Import your ToolItem component

function SimilarLinkComponent() {
  // Sample data for ToolItem components
  const toolItems = [
    {
      to: '/online-notepad',
      title: 'Online Notepad',
      description: 'Description of Notepad',
    },
    {
      to: '/word-counter',
      title: 'Word Counter',
      description: 'Description of Word Counter',
    },
    {
      to: '/case-converter',
      title: 'Case Converter',
      description: 'Description of Case Converter',
    },
    {
      to: '/cool-fonts',
      title: 'Cool Fonts',
      description: 'Description of Fonts Style',
    },
    {
      to: '/facebook-fonts',
      title: 'Facebook Fonts',
      description: 'Description of Facebook Font Generator',
    },

    // {
    //   to: '/test_component',
    //   title: 'Test Component',
    //   description: 'Description of Test Component',
    // },

    {
      to: '/cursive-generator',
      title: 'Cursive Generator',
      description: 'Description of Cursed Text Generator',
    },
    {
      to: '/unicode-text-converter',
      title: 'Unicode Text Converter',
      description: 'Description of Unicode Text',
    },

    {
      to: '/fonts-style',
      title: 'Fonts Style',
      description: 'Description of Typing Style Fonts',
    },

    {
      to: '/cool-number-fonts',
      title: 'Cool Number Fonts',
      description: 'Description of Digits Style',
    },
    {
      to: '/roman-number',
      title: 'Roman Number',
      description: 'Description of Roman Numeral Date Converter',
    },
    {
      to: '/url-slug',
      title: 'Url Slug',
      description: 'Description of Slugify URL Generator',
    },
    {
      to: '/replace-text',
      title: 'Replace Text',
      description: 'Description of Text Replacer',
    },

    {
      to: '/bubble-font',
      title: 'Bubble Font',
      description: 'Description of Bubble Text Generator',
    },

    {
      to: '/strikethrough-text',
      title: 'Strikethrough Text',
      description: 'Description of Strikethrough Text Generator',
    },

    {
      to: '/plain-text',
      title: 'Plain Text',
      description: 'Description of Plain Text Converter',
    },

    {
      to: '/bold-text-generator',
      title: 'Bold Text Generator',
      description: 'Description of Bold Text Converter',
    },

    {
      to: '/italic-font',
      title: 'Italic Font',
      description: 'Description of Italic Text Generator',
    },

    {
      to: '/sentence-case',
      title: 'Sentence Case',
      description: 'Description of Sentence Case Converter',
    },

    {
      to: '/underline-text',
      title: 'Underline Text',
      description: 'Description of Underline Text',
    },

    {
      to: '/title-case-converter',
      title: 'Title Case Converter',
      description: 'Description of Title Case Converter',
    },

    {
      to: '/upside-down-text',
      title: 'Upside Down Text',
      description: 'Description of Upside Down Text Generator',
    },
    {
      to: '/small-text-generator',
      title: 'Small Text Generator',
      description: 'Description of Small Text Generator',
    },

    {
      to: '/vaporwave-text-generator',
      title: 'Vaporwave Text Generator',
      description: 'Description of Wide Text Generator',
    },
    {
      to: '/wingdings-translator',
      title: 'Wingdings Translator',
      description: 'Description of Wingdings Converter',
    },

    {
      to: '/text-repeater',
      title: 'Text Repeater',
      description: 'Description of Repeat Text',
    },

    {
      to: '/mirror-text-generator',
      title: 'Mirror Text Generator',
      description: 'Description of Mirror Text Generator',
    },

    {
      to: '/backwards-text-generator',
      title: 'Backwards Text Generator',
      description: 'Description of Reverse Text Generator',
    },

    {
      to: '/invisible-character',
      title: 'Invisible Character',
      description: 'Description of Invisible Text Generator',
    },


    {
      to: '/binary-code-translator',
      title: 'Binary Code Translator',
      description: 'Description of Binary Code Translator',
    },

    {
      to: '/hex-to-text',
      title: 'Hex To Text',
      description: 'Description of Hex to Text Converter',
    },


    {
      to: '/json-stringify-online',
      title: 'JSON Stringify Online',
      description: 'Description of JSON Stringify Text Generator',
    },

    {
      to: '/morse-code-translator',
      title: 'Morse Code Translator',
      description: 'Description of Morse Code Translator',
    },
    {
      to: '/nato-phonetic-alphabet',
      title: 'NATO Phonetic Alphabet',
      description: 'Description of NATO Phonetic Alphabet',
    },

    {
      to: '/utf-8-encoding',
      title: 'UTF-8 Encoding/Decoding',
      description: 'Description of UTF-8 Encoder/Decoder',
    },

    {
      to: '/online-alarm-clock',
      title: 'Online Alarm Clock',
      description: 'Description of Reminder Alert',
    }
  ];

  return (
    <Grid  container spacing={2} style={{ marginTop: '6px', marginBottom: '12px' }}>
      {toolItems.map((tool, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <ToolItem to={tool.to} title={tool.title} description={tool.description} />
        </Grid>
      ))}
    </Grid>
  );
}

export default SimilarLinkComponent;
