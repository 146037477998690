export const DecorativeStyle1 = (text) => {
    const decorativeCharacters = '҉';
    const decorativeMapper = {
        'A': 'a҉', 'B': 'b҉', 'C': 'c҉', 'D': 'd҉', 'E': 'e҉', 'F': 'f҉', 'G': 'g҉',
        'H': 'h҉', 'I': 'i҉', 'J': 'j҉', 'K': 'k҉', 'L': 'l҉', 'M': 'm҉', 'N': 'n҉',
        'O': 'o҉', 'P': 'p҉', 'Q': 'q҉', 'R': 'r҉', 'S': 's҉', 'T': 't҉', 'U': 'u҉',
        'V': 'v҉', 'W': 'w҉', 'X': 'x҉', 'Y': 'y҉', 'Z': 'z҉',
        'a': 'a҉', 'b': 'b҉', 'c': 'c҉', 'd': 'd҉', 'e': 'e҉', 'f': 'f҉', 'g': 'g҉',
        'h': 'h҉', 'i': 'i҉', 'j': 'j҉', 'k': 'k҉', 'l': 'l҉', 'm': 'm҉', 'n': 'n҉',
        'o': 'o҉', 'p': 'p҉', 'q': 'q҉', 'r': 'r҉', 's': 's҉', 't': 't҉', 'u': 'u҉',
        'v': 'v҉', 'w': 'w҉', 'x': 'x҉', 'y': 'y҉', 'z': 'z҉',
    };

    return text
        .split('')
        .map((char) => decorativeMapper[char] || char)
        .join('');
};
// Output: "H҉e҉l҉l҉o҉ ҉W҉o҉r҉l҉d҉"
export const AddSymbolAroundLetters = (text) => {
    return text
        .split('')
        .map((char) => `≋${char}≋`)
        .join('');
};
//≋a≋≋b≋≋c≋≋d≋≋e≋≋f≋≋g≋≋h≋≋i≋≋j≋≋k≋≋l≋≋m≋≋n≋≋o≋≋p≋≋q≋≋r≋≋s≋≋t≋≋u≋≋v≋≋w≋≋x≋≋y≋≋z≋≋≋A≋≋B≋≋C≋≋D≋≋E≋≋F≋≋G≋≋H≋≋I≋≋J≋≋K≋≋L≋≋M≋≋N≋≋O≋≋P≋≋Q≋≋R≋≋S≋≋T≋≋U≋≋V≋≋W≋≋X≋≋Y≋≋Z≋
export const AddDoubleSquareBracketsAroundLetters = (text) => {
    return text
        .split('')
        .map((char) => `『${char}』`)
        .join('');
};
//『a』『b』『c』『d』『e』『f』『g』『h』『i』『j』『k』『l』『m』『n』『o』『p』『q』『r』『s』『t』『u』『v』『w』『x』『y』『z』『A』『B』『C』『D』『E』『F』『G』『H』『I』『J』『K』『L』『M』『N』『O』『P』『Q』『R』『S』『T』『U』『V』『W』『X』『Y』『Z』

export const AddSquareBracketsAroundLetters = (text) => {
    return text
        .split('')
        .map((char) => `【${char}】`)
        .join('');
};

//【a】【b】【c】【d】【e】【f】【g】【h】【i】【j】【k】【l】【m】【n】【o】【p】【q】【r】【s】【t】【u】【v】【w】【x】【y】【z】【A】【B】【C】【D】【E】【F】【G】【H】【I】【J】【K】【L】【M】【N】【O】【P】【Q】【R】【S】【T】【U】【V】【W】【X】【Y】【Z】
export const AddHeartSymbolAfterLetters = (text) => {
    return text
        .split('')
        .map((char) => `${char}♥`)
        .join('');
};
//a♥b♥c♥d♥e♥f♥g♥h♥i♥j♥k♥l♥m♥n♥o♥p♥q♥r♥s♥t♥u♥y♥w♥x♥y♥z♥♥A♥B♥C♥D♥E♥F♥G♥H♥I♥J♥K♥L♥M♥N♥O♥P♥Q♥R♥S♥T♥U♥Y♥W♥X♥Y♥Z♥
export const AddFancyDecoration = (text) => {
    const fancyDecoration = '˜”*°•.˜”*°•';
    return `${fancyDecoration} ${text} ${fancyDecoration}`;
};
//˜”*°•.˜”*°• abcdefghijklmnopqrstuywxyzABCDEFGHIJKLMNOPQRSTUYWXYZ •°*”˜.•°*”˜

export const AddDecoration = (text) => {
    return text
        .split('')
        .map((char) => `[̲̅${char}]`)
        .join('');
};
//[̲̅a][̲̅b][̲̅c][̲̅d][̲̅e][̲̅f][̲̅g][̲̅h][̲̅i][̲̅j][̲̅k][̲̅l][̲̅m][̲̅n][̲̅o][̲̅p][̲̅q][̲̅r][̲̅s][̲̅t][̲̅u][̲̅y][̲̅w][̲̅x][̲̅y][̲̅z][̲̅A][̲̅B][̲̅C][̲̅D][̲̅E][̲̅F][̲̅G][̲̅H][̲̅I][̲̅J][̲̅K][̲̅L][̲̅M][̲̅N][̲̅O][̲̅P][̲̅Q][̲̅R][̲̅S][̲̅T][̲̅U][̲̅Y][̲̅W][̲̅X][̲̅Y][̲̅Z]
export const AddDecoration1 = (text) => {
    return `★꧁༒☆•${text}•☆༒꧂★`;
};

//★꧁༒☆•Hello World•☆༒꧂★

export const AddDecoration2 = (text) => {
    return `╰‿╯${text}`;
};

//╰‿╯Hello World

export const AddDecoration3 = (text) => {
    return `༒⋆ ${text} 𖣐`;
};

// ༒⋆ Hello World 𖣐


export const AddDecoration4 = (text) => {
    return `꧁✘${text}✔꧂`;
};

//꧁✘Hello World✔꧂


export const AddDecoration6 = (text) => {
    return `𒆜${text}𒆜`;
};

//𒆜Hello World𒆜

export const AddDecoration7 = (text) => {
    return `—͟͟͞͞𖣘${text}`;
};

// —͟͟͞͞𖣘Hello World
export const AddDecoration8 = (text) => {
    return `༒☮${text}🖤`;
};

// ༒☮Hello World🖤


export const AddDecoration9 = (text) => {
    return `ঔৣ${text}ঔৣ`;
};
//ঔৣHello Worldঔৣ            
export const AddDecoration10 = (text) => {
    return `꧁ঔৣ☬✞${text}✞☬ঔৣ꧂`;
};
//꧁ঔৣ☬✞Hello World✞☬ঔৣ꧂


export const AddDecoration11 = (text) => {
    return `༒۝${text}۝༒`;
};
//༒۝Hello World۝༒
export const AddDecoration12 = (text) => {
    return `🕳${text}🕳`;
};
//🕳Hello World🕳


export const AddDecoration14 = (text) => {
    return `⫷${text}⫸`;
};
//⫷Hello World⫸
export const AddDecoration15 = (text) => {
    return `❚█══█❚${text}❚█══█❚`;
};
//█══█❚Hello World❚█══█❚
export const AddDecoration16 = (text) => {
    return `▄︻̷̿┻̿═━一${text}`;
};
//▄︻̷̿┻̿═━一Hello World

export const AddDecoration17 = (text) => {
    return `〘${text}〙`;
};
// Output: 〘Hello World〙
export const AddDecoration18 = (text) => {
    return `«${text}»`;
};
// Output: «Hello World»
export const AddDecoration19 = (text) => {
    return `《${text}》`;
};
// Output: 《Hello World》
export const AddDecoration20 = (text) => {
    return `💘${text}💘`;
};
export const AddDecoration21 = (text) => {
    return `💓${text}💔`;
};
export const AddDecoration22 = (text) => {
    return `❣${text}❣`;
};

export const AddDecoration23 = (text) => {
    return `♕${text}♕`;
};

///

export const AddDecoration24 = (text) => {
    return `•♥•♥•♥ ${text} ♥•♥•♥•`;
};

export const AddDecoration25 = (text) => {
    return `♡ᶫᵒᵛᵉᵧₒᵤ♡ ${text} ♡ᶫᵒᵛᵉᵧₒᵤ♡`;
};

export const AddDecoration26 = (text) => {
    return `█▓▒▒░░░${text}░░░▒▒▓█`;
};


export const AddDecoration27 = (text) => {
    return `'*•.¸♡ ${text} ♡¸.•*'`;
};

export const AddDecoration28 = (text) => {
    return `▼${text}△`;
};


export const AddDecorationg2 = (text) => {
    return `☭${text}☭`;
};

export const AddDecorationg3 = (text) => {
    return `✡🔱✡${text}✡🔱✡`;
};

export const AddDecorationg4 = (text) => {
    return `☪${text}☪`;
};
export const AddDecorationg5 = (text) => {
    return `✞${text}✞`;
};


export const AddDecoration35 = (text) => {
    return `💀☠${text}☠💀`;
};

export const AddDecoration36 = (text) => {
    return `${text} 💯`;
};
export const AddDecoration37 = (text) => {
    return `🎉🎂🎁${text}🎉🎂🎁`;
};
export const AddDecoration38 = (text) => {
    return `유${text}웃`;
};
export const AddDecoration39 = (text) => {
    return `💗❤♥💝💓❣${text}💗❤♥💝💓❣`;
};

export const AddDecoration40 = (text) => {
    return `✔${text}✔`;
};
export const AddDecoration41 = (text) => {
    return `✘${text}✘`;
};

export const mixCharacters1 = (text) => {
    const characterMap = {
        'a': 'ā', 'b': '𝓫', 'c': '¢', 'd': '∂', 'e': '𝐞', 'f': 'ƒ', 'g': '𝓰',
        'h': '𝓱', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃',
        'o': 'σ', 'p': 'ρ', 'q': '૮', 'r': '𝓻', 's': '𝓼', 't': '𝓽', 'u': '𝓾',
        'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃',
        'A': 'Ā', 'B': '𝓑', 'C': '¢', 'D': '∂', 'E': '𝐄', 'F': 'Ƒ', 'G': '𝓖',
        'H': '𝓗', 'I': '𝓘', 'J': '𝓙', 'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝',
        'O': 'Ō', 'P': 'Ρ', 'Q': '૮', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣', 'U': '𝓤',
        'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
    };


    return text.split('').map((char) => characterMap[char] || char).join('');
};
// 𝓣𝐞𝔁𝓽 𝓣σ Ƒā¢𝐞𝓫σσ𝓀 Ƒσ𝓃𝓽
export const mixCharacters2 = (text) => {
    const characterMap = {
        'a': 'ą', 'b': 'ҍ', 'c': 'ç', 'd': 'ժ', 'e': 'ҽ', 'f': 'ƒ', 'g': 'ց',
        'h': 'հ', 'i': 'ì', 'j': 'ʝ', 'k': 'ҟ', 'l': 'Ӏ', 'm': 'ʍ', 'n': 'ղ',
        'o': 'օ', 'p': 'ք', 'q': 'զ', 'r': 'ɾ', 's': 'ʂ', 't': 'է', 'u': 'մ',
        'v': 'վ', 'w': 'ա', 'x': '×', 'y': 'ꂖ', 'z': 'Հ',

        'A': 'Ą', 'B': 'Ҍ', 'C': 'Ç', 'D': 'Ḍ', 'E': 'Ҽ', 'F': 'Ƒ', 'G': 'Ց',
        'H': 'Ḥ', 'I': 'Ì', 'J': 'ʝ', 'K': 'Ҟ', 'L': 'L̥', 'M': 'Ӎ', 'N': 'Ṇ',
        'O': 'Ō', 'P': 'ք', 'Q': 'Ԛ', 'R': 'Ի', 'S': 'ʂ', 'T': 'Ṭ', 'U': 'Մ',
        'V': 'Վ', 'W': 'ա', 'X': '×', 'Y': 'ꂖ', 'Z': 'Զ',
    };

    const mappedText = text
        .split('')
        .map((char) => characterMap[char] || char)
        .join('');

    return mappedText;
};

// Ṭҽ×է Ṭօ Ƒąçҽҍօօҟ Ƒօղէ
export const mixCharacters3 = (text) => {
    const characterMap = {
        'a': 'α', 'b': 'в', 'c': '¢',
        'd': '∂', 'e': 'є', 'f': 'ƒ',
        'g': 'g', 'h': 'н', 'i': 'ι',
        'j': 'נ', 'k': 'к', 'l': 'ℓ',
        'm': 'м', 'n': 'η', 'o': 'σ',
        'p': 'ρ', 'q': 'q', 'r': 'я',
        's': 'ѕ', 't': 'т', 'u': 'υ',
        'v': 'ν', 'w': 'ω', 'x': 'χ',
        'y': 'у', 'z': 'z',
        'A': 'α', 'B': 'в', 'C': '¢',
        'D': '∂', 'E': 'є', 'F': 'ƒ',
        'G': 'g', 'H': 'н', 'I': 'ι',
        'J': 'נ', 'K': 'к', 'L': 'ℓ',
        'M': 'м', 'N': 'η', 'O': 'σ',
        'P': 'ρ', 'Q': 'q', 'R': 'я',
        'S': 'ѕ', 'T': 'т', 'U': 'υ',
        'V': 'ν', 'W': 'ω', 'X': 'χ',
        'Y': 'у', 'Z': 'z',
    };

    const mappedText = text
        .split('')
        .map((char) => characterMap[char] || char)
        .join('');

    return mappedText;
};
// тєχт тσ ƒα¢євσσк ƒσηт

export const mixCharacters4 = (text) => {
    const characterMap = {
        'A': 'ꍏ', 'a': 'ą', 'B': 'Ᏸ', 'b': '𝓫', 'C': '☾', 'c': '¢',
        'D': 'ᕲ', 'd': '∂', 'E': '€', 'e': '𝐞', 'F': 'Ϝ', 'f': '𝔉',
        'G': '❡', 'g': '𝐠', 'H': '♄', 'h': '𝓱', 'I': '♗', 'i': '𝐢',
        'J': '♪', 'j': '𝐣', 'K': 'ϰ', 'k': '𝐤', 'L': '↳', 'l': '𝓵',
        'M': 'ᗰ', 'm': '𝐦', 'N': '♫', 'n': '𝐧', 'O': '⊙', 'o': '𝐨',
        'P': 'ρ', 'p': '𝐩', 'Q': 'ᵠ', 'q': '𝓺', 'R': '☈', 'r': '𝐫',
        'S': '∫', 's': '𝐬', 'T': '†', 't': '𝐭', 'U': '☋', 'u': '𝐮',
        'V': '⚧', 'v': '𝐯', 'W': 'ω', 'w': '𝐰', 'X': '⌘', 'x': '𝐱',
        'Y': 'ꂖ', 'y': '𝐲', 'Z': '☡', 'z': '𝐳',
    };

    const mappedText = text
        .split('')
        .map((char) => characterMap[char] || char)
        .join('');

    return mappedText;
};
// †𝐞𝐱𝐭 †𝐨 Ϝą¢𝐞𝓫𝐨𝐨𝐤 Ϝ𝐨𝐧𝐭   

export const mixCharacters5 = (text) => {
    const characterMap = {
        'A': 'Д', 'B': 'Ᏸ', 'C': 'ℂ', 'D': '∂', 'E': 'Ǝ', 'F': 'ƒ',
        'G': 'Ꮆ', 'H': 'ℍ', 'I': 'î', 'J': 'ʝ', 'K': 'Ƙ', 'L': 'ℓ',
        'M': 'ℳ', 'N': 'И', 'O': 'ø', 'P': 'Ƥ', 'Q': 'ℚ', 'R': 'Я',
        'S': 'Ꭶ', 'T': '†', 'U': 'υ', 'V': '∇', 'W': 'ω', 'X': '✘',
        'Y': '¥', 'Z': 'Ẕ',
        'a': 'Д', 'b': '♭', 'c': 'ℂ', 'd': '∂', 'e': 'Ǝ', 'f': 'ƒ',
        'g': 'Ꮆ', 'h': 'ℍ', 'i': 'î', 'j': 'ʝ', 'k': 'Ƙ', 'l': 'ℓ',
        'm': 'ℳ', 'n': 'И', 'o': 'ø', 'p': 'Ƥ', 'q': 'ℚ', 'r': 'Я',
        's': 'Ꭶ', 't': '†', 'u': 'υ', 'v': '∇', 'w': 'ω', 'x': '✘',
        'y': '¥', 'z': 'Ẕ',
    };

    const mappedText = text
        .split('')
        .map((char) => characterMap[char] || char)
        .join('');

    return mappedText;
};

//†Ǝ✘† †ø ƒДℂƎ♭øøƘ ƒøИ†

export const mixCharacters6 = (text) => {
    const characterMap = {
        'a': 'ꋬ', 'b': 'ꍗ', 'c': 'ꏳ', 'd': 'ꂟ', 'e': 'ꏂ', 'f': 'ꄟ',
        'g': 'ꍌ', 'h': 'ꃬ', 'i': '꒐', 'j': '꒻', 'k': 'ꀘ', 'l': '꒒',
        'm': 'ꂵ', 'n': 'ꂚ', 'o': 'ꉻ', 'p': 'ꉣ', 'q': 'ꋠ', 'r': 'ꋪ',
        's': 'ꑄ', 't': '꓄', 'u': 'ꀎ', 'v': 'ꐞ', 'w': 'ꅐ', 'x': 'ꉼ',
        'y': 'ꂖ', 'z': 'ꑓ',
        'A': 'ꋬ', 'B': 'ꍗ', 'C': 'ꏳ', 'D': 'ꂟ', 'E': 'ꏂ', 'F': 'ꄟ',
        'G': 'ꍌ', 'H': 'ꃬ', 'I': '꒐', 'J': '꒻', 'K': 'ꀘ', 'L': '꒒',
        'M': 'ꂵ', 'N': 'ꂚ', 'O': 'ꉻ', 'P': 'ꉣ', 'Q': 'ꋠ', 'R': 'ꋪ',
        'S': 'ꑄ', 'T': '꓄', 'U': 'ꀎ', 'V': 'ꐞ', 'W': 'ꅐ', 'X': 'ꉼ',
        'Y': 'ꂖ', 'Z': 'ꑓ'
    };

    const mappedText = text
        .split('')
        .map((char) => characterMap[char] || char)
        .join('');

    return mappedText;
};

// ꓄ꏂꉼ꓄ ꓄ꉻ ꄟꋬꏳꏂꍗꉻꉻꀘ ꄟꉻꂚ꓄   
export const mixCharacters7 = (text) => {
    const characterMap = {
        'a': 'ꁲ', 'b': 'ꃃ', 'c': 'ꇃ', 'd': 'ꂡ', 'e': 'ꏹ', 'f': 'ꄙ',
        'g': 'ꁍ', 'h': 'ꀍ', 'i': 'ꀤ', 'j': 'ꀭ', 'k': 'ꈵ', 'l': '꒒',
        'm': 'ꂵ', 'n': 'ꋊ', 'o': 'ꁏ', 'p': 'ꉣ', 'q': 'ꆰ', 'r': 'ꋪ',
        's': 'ꌗ', 't': 'ꋖ', 'u': 'ꌈ', 'v': 'ꐞ', 'w': 'ꅏ', 'x': 'ꋚ',
        'y': 'ꂖ', 'z': 'ꁴ',
        'A': 'ꁲ', 'B': 'ꃃ', 'C': 'ꇃ', 'D': 'ꂡ', 'E': 'ꏹ', 'F': 'ꄙ',
        'G': 'ꁍ', 'H': 'ꀍ', 'I': 'ꀤ', 'J': 'ꀭ', 'K': 'ꈵ', 'L': '꒒',
        'M': 'ꂵ', 'N': 'ꋊ', 'O': 'ꁏ', 'P': 'ꉣ', 'Q': 'ꆰ', 'R': 'ꋪ',
        'S': 'ꌗ', 'T': 'ꋖ', 'U': 'ꌈ', 'V': 'ꐞ', 'W': 'ꅏ', 'X': 'ꋚ',
        'Y': 'ꂖ', 'Z': 'ꁴ'
    };

    const mappedText = text
        .split('')
        .map((char) => characterMap[char] || char)
        .join('');

    return mappedText;
};
// ꋖꏹꋚꋖ ꋖꁏ ꄙꁲꇃꏹꃃꁏꁏꈵ ꄙꁏꋊꋖ
