import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TextInnerDivHorizontal from '../../common/Layouts/TextInnerDivHorizontal';
import TextInnerDivVertical from '../../common/Layouts/TextInnerDivVertical';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';

import {
    FontStyle3, FontStyle5, FontStyle6, FontStyle7, FontStyle8, FontStyle9, GlitchText, TimesNewRomanText,
    SquaredText, NegativeSquaredText, CircledTextBlack, CircledText
} from '../../features/StringManipulation/TextToStyle';
import {
    plainToIPA, MirrorVisibleText, BackwardsText,
    strikethroughText, SmallCapsText, SuperscriptGenerator, VaporwaveText
} from '../../features/StringManipulation/TextManipuation';

// for seo
import SEOComponent from '../../common/SEOComponent';

const CursedText = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
    const [individualCopiedIndex, setIndividualCopiedIndex] = useState(null);

    // All functions and Define the initial texts 
    const initialTexts = [
        "𝕿𝖊𝖝𝖙 𝖙𝖔 𝕮𝖚𝖗𝖘𝖊𝖉 𝕿𝖊𝖝𝖙..", "TE᙭T TO ᑕᑌᖇᔕEᗪ TE᙭T..", "ꋖꈼꇒꋖ ꋖꂦ ꀯꐇꌅꌚꈼꂠ ꋖꈼꇒꋖ..", "꓄ꏂꉧ꓄ ꓄ꄲ ꉔ꒤ꋪꇙꏂ꒯ ꓄ꏂꉧ꓄..",
        "𝓉ℯ𝓍𝓉 𝓉ℴ 𝒸𝓊𝓇𝓈ℯ𝒹 𝓉ℯ𝓍𝓉..", "ƚҽxƚ ƚσ ƈυɾʂҽԃ ƚҽxƚ..", "卞ヨメ卞 卞回 亡凵尺己ヨ句 卞ヨメ卞..", "T̸e̸x̸t̸ t̸o̸ C̸u̸r̸s̸e̸d̸ T̸e̸x̸t̸..",
        "T̶e̶x̶t̶ ̶t̶o̶ ̶C̶u̶r̶s̶e̶d̶ ̶T̶e̶x̶t̶.̶.̶", "ᴛᴇxᴛ ᴛᴏ ᴄᴜʀꜱᴇᴅ ᴛᴇxᴛ..", "ᵀᵉˣᵗ ᵗᵒ ᶜᵘʳˢᵉᵈ ᵀᵉˣᵗ..", "ʈɛχʈ ʈɒ çʊɹʃɛɖ ʈɛχʈ..",
        "..txeT desruC ot txeT", "..ʇxǝ⊥ bǝsɹnƆ oʇ ʇxǝ⊥", "Ｔｅｘｔ　ｔｏ　Ｃｕｒｓｅｄ　Ｔｅｘｔ..", "🅃🄴🅇🅃 🅃🄾 🄲🅄🅁🅂🄴🄳 🅃🄴🅇🅃..",
        "🆃🅴🆇🆃 🆃🅾 🅲🆄🆁🆂🅴🅳 🆃🅴🆇🆃..", "🅣🅔🅧🅣 🅣🅞 🅒🅤🅡🅢🅔🅓 🅣🅔🅧🅣..", "ⓉⒺⓍⓉ ⓉⓄ ⒸⓊⓇⓈⒺⒹ ⓉⒺⓍⓉ..",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);
    const TextConversion = (inputText) => {
        const AllFunctionValues = [
            TimesNewRomanText(inputText),
            FontStyle8(inputText),
            FontStyle7(inputText),
            FontStyle6(inputText),
            FontStyle9(inputText),
            FontStyle5(inputText),
            FontStyle3(inputText),
            GlitchText(inputText),
            strikethroughText(inputText),
            SmallCapsText(inputText),
            SuperscriptGenerator(inputText),
            plainToIPA(inputText),
            BackwardsText(inputText),
            MirrorVisibleText(inputText),
            VaporwaveText(inputText),
            SquaredText(inputText),
            NegativeSquaredText(inputText),
            CircledTextBlack(inputText),
            CircledText(inputText),
        ];
        // Return the output
        return AllFunctionValues;
    };

    // // Automatically scroll the output textarea to the bottom when outputText changes
    // useEffect(() => {
    //     const outputTextarea = outputTextareaRef.current;
    //     if (outputTextarea) {
    //         outputTextarea.scrollTop = outputTextarea.scrollHeight;
    //     }
    // }, [outputText]);

    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
        // Function Call
        const AllFunctionValues = TextConversion(text);
        // Check if the inputText is empty or null and set outputText accordingly
        setOutputText(text.trim() === null || text.trim() === '' ? [] : AllFunctionValues);
        // Function Call
        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText([]);
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const range = document.createRange();
            range.selectNode(outputDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
            setIndividualCopiedIndex(null);
        }
    };
    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string and join with line breaks
        const fileContent = outputText.map(text => text.replace(/,/g, '')).join('\n\n');
        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'FacebookFont.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);
        setIndividualCopiedIndex(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    const resetIsCopied = () => {
        setIsCopied(false);
    };

    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Add Elegance to Your Text with Our Cursive Font Generator</h1>
                        <p>Transform your writing into elegant cursive fonts effortlessly. Our cursive text generator offers a variety of styles to suit your creative needs.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TextInnerDivHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                    setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivHorizontal
                    individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivHorizontal
                    resetIsCopied={resetIsCopied}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TextInnerDivVertical
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivVertical
                        individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivVertical
                        resetIsCopied={resetIsCopied}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Cursive Font Generator - Add Elegance to Your Text</h2>
                        <p>Ready to add a touch of elegance to your text? Our Cursive Font Generator allows you to effortlessly transform your writing into beautiful cursive fonts. With a variety of styles to choose from, you can easily customize your text to suit your creative needs.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default CursedText;
