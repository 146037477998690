import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { StyledTextareaAutosize, StyledClearButton, StyledButtonIcon, StyledCopyButton } from '../TagsStyle';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TextTextAreaHorizontal = ({
    showClearButton,
    handleClearClick,
    handleInputChange,
    inputText,
    error,
    showCopyButton,
    outputText,
    outputTextareaRef,
    initialOutputText,
    handleDownloadClick,
    handleCopyClick,
    isCopied,
    handleOutputChange,
    inputplaceholder,
}) => {

    const isOutputTextEmpty = outputText.length === 0 || outputText.trim() === '';

    return (
        <>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {showClearButton && (
                            <StyledClearButton className="clear-button" onClick={handleClearClick} endIcon={<ClearIcon />} size="small">
                                Clear
                            </StyledClearButton>
                        )}
                        <StyledTextareaAutosize
                            className="input-textarea text-input"
                            placeholder={inputplaceholder || 'Start typing, or copy and paste your document here...'}
                            value={inputText}
                            maxLength={30000}
                            onChange={handleInputChange}
                        />
                        {error && <p className="error-message">{error}</p>}
                        {/* The input text will appear in real-time in the output textarea */}
                    </Box>
                </Grid>
            </Grid>
            <Grid container className="buttons-case row" style={{ paddingBottom: '10px' }} spacing={{ xs: 0.5, sm: 1, md: 2, lg: 3 }}>
                {/* Place any additional buttons or elements here if needed */}
            </Grid>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {(showCopyButton || !isOutputTextEmpty) && (
                            <Box>
                                <StyledButtonIcon className="download-button" variant="contained" size="small" onClick={handleDownloadClick}>
                                    <FileDownloadIcon />
                                </StyledButtonIcon>
                                <StyledCopyButton
                                    className={`copy-button ${isCopied && !isOutputTextEmpty ? 'copied' : ''}`}
                                    onClick={handleCopyClick}
                                    endIcon={<CopyAllIcon />}
                                    size="small"
                                >
                                    {isCopied && !isOutputTextEmpty ? 'Copied' : 'Copy'}
                                </StyledCopyButton>
                            </Box>
                        )}
                        <StyledTextareaAutosize
                            className="output-textarea text-input"
                            id="copied"
                            ref={outputTextareaRef}
                            onChange={handleOutputChange}
                            placeholder={initialOutputText}
                            value={outputText}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TextTextAreaHorizontal;
