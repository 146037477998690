import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingBarComponent from './LoadingBarComponent';
import ScrollToTopOnNavigation from './ScrollToTopOnNavigation';

// Import your components
import MasterPage from './MasterPage';
import NotFound from './components/pages/NotFound';
import HomePage from './components/pages/HomePage';
import TestComponent from './components/Backup/Testing/TestComponent';
import BoldTextConverter from './components/pages/StringManipulation/BoldTextConverter';
import CaseConverter from './components/pages/StringManipulation/CaseConverter';
import BubbleTextGenerator from './components/pages/StringManipulation/BubbleTextGenerator';
import BinaryCodeTranslator from './components/pages/StringManipulation/BinaryCodeTranslator';
import FacebookFontGenerator from './components/pages/StringManipulation/FacebookFontGenerator';
import HextoTextConverter from './components/pages/StringManipulation/HextoTextConverter';
import InvisibleTextGenerator from './components/pages/StringManipulation/InvisibleTextGenerator';
import ItalicTextGenerator from './components/pages/StringManipulation/ItalicTextGenerator';
import JSONStringifyTextGenerator from './components/pages/StringManipulation/JSONStringifyTextGenerator';
import MirrorTextGenerator from './components/pages/StringManipulation/MirrorTextGenerator';
import MorseCodeTranslator from './components/pages/StringManipulation/MorseCodeTranslator';
import NATOPhoneticAlphabet from './components/pages/StringManipulation/NATOPhoneticAlphabet';
import PlainTextConverter from './components/pages/StringManipulation/PlainTextConverter';
import RepeatText from './components/pages/StringManipulation/RepeatText';
import TextReplacer from './components/pages/StringManipulation/TextReplacer';
import ReverseTextGenerator from './components/pages/StringManipulation/ReverseTextGenerator';
import RomanNumeralDateConverter from './components/pages/StringManipulation/RomanNumeralDateConverter';
import SlugifyURLGenerator from './components/pages/StringManipulation/SlugifyURLGenerator';
import SentenceCaseConverter from './components/pages/StringManipulation/SentenceCaseConverter';
import StrikethroughTextGenerator from './components/pages/StringManipulation/StrikethroughTextGenerator';
import TitleCaseConverter from './components/pages/StringManipulation/TitleCaseConverter';
import UnderlineText from './components/pages/StringManipulation/UnderlineText';
import UpsideDownTextGenerator from './components/pages/StringManipulation/UpsideDownTextGenerator';
import SmallTextGenerator from './components/pages/StringManipulation/SmallTextGenerator';
import UTF8EncoderDecoder from './components/pages/StringManipulation/UTF8EncoderDecoder';
import WideTextGenerator from './components/pages/StringManipulation/WideTextGenerator';
import WingdingsConverter from './components/pages/StringManipulation/WingdingsConverter';
import CursedText from './components/pages/StringManipulation/CursedText';
import UnicodeText from './components/pages/StringManipulation/UnicodeText';
import FontsStyle from './components/pages/StringManipulation/FontsStyle';
import DigitsStyle from './components/pages/StringManipulation/DigitsStyle';
import TypingStyleFonts from './components/pages/StringManipulation/TypingStyleFonts';
import Notepad from './components/pages/StringManipulation/Notepad';
import WordCounter from './components/pages/StringManipulation/WordCounter';
import ReminderAlert from './components/pages/ReminderAlert/ReminderAlert';

//For Seo,
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <LoadingBarComponent />
        <ScrollToTopOnNavigation />
        <MasterPage>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/online-notepad" element={<Notepad />} />
            <Route path="/word-counter" element={<WordCounter />} />
            <Route path="/case-converter" element={<CaseConverter />} />
            <Route path="/cool-fonts" element={<FontsStyle />} />
            <Route path="/facebook-fonts" element={<FacebookFontGenerator />} />
            <Route path="/cursive-generator" element={<CursedText />} />
            <Route path="/unicode-text-converter" element={<UnicodeText />} />
            <Route path="/fonts-style" element={<TypingStyleFonts />} />
            <Route path="/cool-number-fonts" element={<DigitsStyle />} />
            <Route path="/roman-number" element={<RomanNumeralDateConverter />} />
            <Route path="/url-slug" element={<SlugifyURLGenerator />} />
            <Route path="/replace-text" element={<TextReplacer />} />
            <Route path="/bubble-font" element={<BubbleTextGenerator />} />
            <Route path="/strikethrough-text" element={<StrikethroughTextGenerator />} />
            <Route path="/plain-text" element={<PlainTextConverter />} />
            <Route path="/bold-text-generator" element={<BoldTextConverter />} />
            <Route path="/italic-font" element={<ItalicTextGenerator />} />
            <Route path="/sentence-case" element={<SentenceCaseConverter />} />
            <Route path="/underline-text" element={<UnderlineText />} />
            <Route path="/title-case-converter" element={<TitleCaseConverter />} />
            <Route path="/upside-down-text" element={<UpsideDownTextGenerator />} />
            <Route path="/small-text-generator" element={<SmallTextGenerator />} />
            <Route path="/vaporwave-text-generator" element={<WideTextGenerator />} />
            <Route path="/wingdings-translator" element={<WingdingsConverter />} />
            <Route path="/text-repeater" element={<RepeatText />} />
            <Route path="/mirror-text-generator" element={<MirrorTextGenerator />} />
            <Route path="/backwards-text-generator" element={<ReverseTextGenerator />} />
            <Route path="/invisible-character" element={<InvisibleTextGenerator />} />
            <Route path="/binary-code-translator" element={<BinaryCodeTranslator />} />
            <Route path="/hex-to-text" element={<HextoTextConverter />} />
            <Route path="/json-stringify-online" element={<JSONStringifyTextGenerator />} />
            <Route path="/morse-code-translator" element={<MorseCodeTranslator />} />
            <Route path="/nato-phonetic-alphabet" element={<NATOPhoneticAlphabet />} />
            <Route path="/utf-8-encoding" element={<UTF8EncoderDecoder />} />
            <Route path="/online-alarm-clock" element={<ReminderAlert />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MasterPage>
      </Router>
    </HelmetProvider>
  );
};

export default App;

