import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TextTextAreaHorizontal from '../../common/Layouts/TextTextAreaHorizontal';
import TextTextAreaVerticale from '../../common/Layouts/TextTextAreaVerticale';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import { NatoPhoneticAlphabet, ReverseNatoPhoneticAlphabet } from '../../features/StringManipulation/TextManipuation';

// for seo
import SEOComponent from '../../common/SEOComponent';

const NATOPhoneticAlphabet = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(true);

    // All functions and Define the initial text
    const Placeholderoutput = "Start typing NATO Phonetic Alphabet, Ex. ";
    const [initialOutputText, setInitialOutputText] = useState(Placeholderoutput);

    const TextConversion = (inputText) => {
        const convertedText = NatoPhoneticAlphabet(inputText);
        return convertedText;
    };

    const TextConversionOutput = (inputText) => {
        const convertedOutput = ReverseNatoPhoneticAlphabet(inputText);
        return convertedOutput;
    };
    // Automatically scroll the output textarea to the bottom when outputText changes
    useEffect(() => {
        const outputTextarea = outputTextareaRef.current;
        if (outputTextarea) {
            outputTextarea.scrollTop = outputTextarea.scrollHeight;
        }
    }, [outputText]);
    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        // Perform conversion for input text
        const convertedInput = TextConversion(text);
        // Update outputText state based on the conversion
        setOutputText(convertedInput);
        setShowClearButton(text.length > 0);
        setIsCopied(false);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
    };
    // Handle output text changes function
    const handleOutputChange = (event) => {
        const text = event.target.value;
        setOutputText(text);
        // Perform conversion for output text
        const convertedOutput = TextConversionOutput(text);
        // Update inputText state based on the conversion, only if there's content
        if (text.trim() === '') {
            setInputText('');
        } else {
            setInputText(convertedOutput);
        }
        setShowClearButton(text.length > 0);
        setIsCopied(false);
    };

    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText('');
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const outputText = outputDiv.textContent.trim(); // Remove leading/trailing spaces and newlines
            if (outputText) {
                const textArea = document.createElement('textarea');
                textArea.value = outputText;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                setIsCopied(true);
            }
        }
    };

    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string
        const fileContent = outputText.replace(/,/g, '');

        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'NatoPhoneticText.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);

        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };


    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;
    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Explore Phonetic Alphabets for Clear Communication</h1>
                        <p>Enhance communication with phonetic spelling using the International Phonetic Alphabet (IPA) and NATO Phonetic Alphabet. Discover clarity in pronunciation.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TextTextAreaHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : ""}
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                    handleOutputChange={handleOutputChange}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TextTextAreaVerticale
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : ""}
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        handleOutputChange={handleOutputChange}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                       <h2>Phonetic Alphabet - Enhance Communication Clarity</h2>
                       <p>Enhance communication and ensure clarity in pronunciation with Phonetic Alphabets. Explore phonetic spelling using the International Phonetic Alphabet (IPA) and NATO Phonetic Alphabet. Our tools make it easy to achieve clear and accurate communication.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default NATOPhoneticAlphabet;
