import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

const LoadingBarComponent = () => {
    const [loadingProgress, setLoadingProgress] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 0;
                }
                return prevProgress + 80;
            });
        }, 300);

        return () => clearInterval(interval);
    }, [location]);

    useEffect(() => {
        // Reset loading progress when the location changes
        setLoadingProgress(0);
    }, [location]);

    return (
        <LoadingBar
            color="rgb(52 52 47)"
            height={2}
            progress={loadingProgress}
            onLoaderFinished={() => setLoadingProgress(0)}
        />
    );
};

export default LoadingBarComponent;
