// PagesDataSeo.js
const SeoMeta = [
    {
        slug: "/",
        title: "ꅏꂦꌅ꒒ꂠ ꂦꄞ ꄞꂦꋊꋖꌚ | Font Generator, Copy and Paste, Instagram Fonts, and More",
        description: "𝗗𝗶𝘀𝗰𝗼𝘃𝗲𝗿 𝗮 𝘄𝗶𝗱𝗲 array of fonts for every occasion! Our font generator offers copy and paste fonts, Instagram fonts, calligraphy fonts, and more. Get creative with different lettering styles, from cursive fonts to bold and modern fonts. Elevate your content with aesthetic fonts, perfect for Instagram and beyond. Explore our collection now!",
        keywords: "cool fonts, copy and paste fonts, fonts copy and paste, instagram fonts, calligraphy fonts, fonts copy paste, cursive fonts, ig fonts, fonts generator, discord fonts, different fonts, lettering fonts, cute fonts, fonts style, copy paste fonts, aesthetic fonts, fonts for instagram, fun fonts, script fonts, insta fonts, pretty fonts, christmas fonts, fonts alphabet, sans serif fonts, facebook fonts, bold fonts, free script fonts, writing fonts, embroidery fonts, aesthetic fonts copy and paste, block fonts, best fonts, cute fonts copy and paste, modern fonts"
    },
    {
        slug: "online-notepad",
        title: "Free Online Notepad - Best Online Notepad Tool",
        description: "𝗘𝘅𝗽𝗹𝗼𝗿𝗲 𝗼𝘂𝗿 free online notepad, the best tool for jotting down notes. Write, save, and access your notes from anywhere.",
        keywords: "online notepad, notepad online, free online notepad, notepad online free, online notepad free best online notepad"
    },
    {
        slug: "word-counter",
        title: "Online Word Counter Tool - Essay Word Counter",
        description: "Count words 𝗮𝗻𝗱 keyword density calculation with our online word counter tool. Perfect for essays and websites. Accurate, easy-to-use, and free.",
        keywords: "word counter, word counter online, word counter tool, online word counter, essay word counter, free word counter, website word counter, word counter website"
    },
    {
        slug: "case-converter",
        title: "Case Converter Online - Convert Text to Your Case",
        description: "𝗘𝗮𝘀𝗶𝗹𝘆 convert text to various cases with our online case converter. Transform text to title case, upper case, lower case, sentence case, and more.",
        keywords: "case converter, title case converter, sentence case converter, upper case converter, lower case converter, case converter online, capital case converter, alternating case converter"
    },
    {
        slug: "cool-fonts",
        title: "Cool Fonts - ꀯꂦꂦ꒒ ꄞꂦꋊꋖꌚ 𝓖𝓮𝓷𝓮𝓻𝓪𝓽𝓸𝓻 | Copy and Paste",
        description: "𝕲𝖊𝖙 𝖈𝖗𝖊𝖆𝖙𝖎𝖛𝖊 with our 𝕔𝕠𝕠𝕝 𝕗𝕠𝕟𝕥𝕤 generator. 亡回尸と and ραʂƚҽ stylish text for Instagram, Facebook, and more. Stand out with unique text styles.",
        keywords: "cool fonts, cool fonts copy and paste, cool fonts generator, cool fonts for instagram, text style, cool text, cool text generator, cool text copy and paste, instagram fonts, instagram fonts for bio, fonts style, free fonts style, copy paste fonts, copy paste text, facebook fonts, aesthetic fonts copy and paste, facebook fonts, facebook fonts generator, instagram text, instagram text generator, instagram text font, fancy fonts copy and paste, fancy fonts generator, stylish text generator"
    },
    {
        slug: "facebook-fonts",
        title: "Facebook Fonts 🅕🅞🅡 𝕊𝕥𝕪𝕝𝕚𝕤𝕙 𝕋𝕖𝕩𝕥 | Customize Your Posts",
        description: "𝗠𝗮𝗸𝗲 𝘆𝗼𝘂𝗿 Facebook posts 𝖕𝖔𝖕 𝖜𝖎𝖙𝖍 our bold text generator. Create eye-catching content easily with bold and stylish text.",
        keywords: "facebook fonts, facebook fonts generator, unicode text converter facebook, facebook bold text, facebook bold text generator, bold text facebook, bold facebook text, facebook text, bold facebook text, bold facebook text"
    },
    {
        slug: "cursive-generator",
        title: "Cursive Generator - 𝕮𝖗𝖊𝖆𝖙𝖊 𝕰𝖑𝖊𝖌𝖆𝖓𝖙 Cursive Fonts",
        description: "EᗩᔕIᒪY 𝗴𝗲𝗻𝗲𝗿𝗮𝘁𝗲 𝗲𝗹𝗲𝗴𝗮𝗻𝘁 cursive fonts with our cursive text generator. Add a touch of sophistication to your text.",
        keywords: "cursive generator, cursed text generator, cursive text generator, cursive font, cursive font styles, cursive font copy and paste, fancy cursive font, copy and paste cursive font, cute cursive font"
    },
    {
        slug: "unicode-text-converter",
        title: "Unicode Text Converter | Τραnσfορμ Τεξτ into 𝖀𝖓𝖎𝖖𝖚𝖊 Unicode Characters",
        description: "𝓔𝓪𝓼𝓲𝓵𝔂 convert your text 𝕚𝕟𝕥𝕠 𝕦𝕟𝕚𝕢𝕦𝕖 Unicode characters with our Unicode Text Converter. Explore a world of fascinating symbols and fonts for your messages, social media, and creative projects. Unlock the power of Unicode encoding today!",
        keywords: "unicode text converter, unicode text, unicode text converter facebook, fancy unicode text, unicode font, unicode font generator"
    },
    {
        //home
        slug: "fonts-style",
        title: "乍尺ヨヨ Fonts Style | ꒤ꋊ꒐ꆰ꒤ꏂ ꊰꄲꋊ꓄ Designs for Your Content",
        description: "𝔇𝔦𝔰𝔠𝔬𝔳𝔢𝔯 free fonts style ꂠꈼꌚꂑꁅꋊꌚ to enhance your content. Use our stylish fonts to make your text stand out.",
        keywords: "fonts style, free fonts style, fonts style designs"
    },
    {
        slug: "cool-number-fonts",
        title: "Cool Number Fonts - Stylish Numerals for Every Occasion",
        description: "Explore cool number (१  ⅱ 3 ⓸ 𝟝 6️⃣ ٦) fonts for a stylish twist. Use these unique numerals for various applications.",
        keywords: "cool number fonts, cool number font, fancy number font, hindi counting, hindi counting 1 to 10, english number to hindi, roman number 1 to 10, roman number 100, 9 in roman number, 12 roman number, ethiopia number, arabic number, arabic number system, bengali number check, english number to hindi, hindi counting"
    },
    {
        slug: "roman-number",
        title: "Roman Numerals Converter - Convert Numbers to Roman Number",
        description: "Convert numbers to (I.. IX X) Roman numerals and vice versa with our easy-to-use Roman numerals converter. Perfect for dates and numbering.",
        keywords: "roman number, roman number 1 to 50, roman number 1 to 100, 1 to 100 roman number, roman number 1 to 10, roman number 1 to 20, roman number 100, 9 in roman number, 12 roman number, 11 roman number, 500 roman number, 50 roman number, 90 roman number, roman numerals, iv roman numerals, 4 in roman numerals, roman numerals converter, roman numeral date converter, roman numerals date converter, roman date, roman date converter, xxix roman number, roman numeral fonts"
    },
    {
        slug: "url-slug",
        title: "URL Slug Generator - Create Clean and SEO-Friendly URLs",
        description: "Generate clean and SEO-friendly URLs with our URL slug generator. Improve your website's SEO and user experience.",
        keywords: "url slug, url generator, clean url, Slug Generator, slugify, url slug generator, Slug Generator Online"
    },
    {
        slug: "replace-text",
        title: "Text Replacer - Find and Replace Text Online",
        description: "Effortlessly find and replace text online with our text replacer tool. Simplify editing and content management.",
        keywords: "replace text, find replace word, find replace in word, replace text online, text replacer, find and replace text"
    },
    {
        slug: "bubble-font",
        title: "Bubble Font Generator - Create 🅟🅛🅐🅨ⓕⓤⓛ Text Designs",
        description: "𝗧𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺 ⓨⓞⓤⓡ 🅣🅔🅧🅣 into bubble fonts or circle font with our bubble text generator. Add a fun and unique touch to your content.",
        keywords: "bubble font, bubble fonts free, bubble fonts, bubble text, bubble text generator, bubble text font, bubble font free, circle font, circle font generator, circle text, circle text generator, "
    },
    {
        slug: "strikethrough-text",
        title: "Strikethrough Text G̶e̶n̶e̶r̶a̶t̶o̶r̶ | Add a Striking Effect",
        description: "Make your text stand out with our strikethrough text generator. Easily create striking content with crossed-out text.",
        keywords: "strikethrough text, strikethrough, strikethrough text generator, strikethrough generator, strikethrough font, strikethrough text generator, strikethrough font, strikethrough font generator"
    },
    {
        slug: "plain-text",
        title: "Plain Text Generator - Create Simple and Text Cleaner",
        description: "Generate plain text effortlessly with our online tool. Create clean and straightforward text for various purposes.",
        keywords: "plain text, plain text editor, plain text converter, Plain text converter, free plan text converter, paste as plain text, convert to plain text"
    },
    {
        slug: "bold-text-generator",
        title: "Bold Text Generator - 𝗠𝗮𝗸𝗲 𝗬𝗼𝘂𝗿 𝗧𝗲𝘅𝘁 𝗦𝘁𝗮𝗻𝗱 𝗢𝘂𝘁",
        description: "𝗠𝗮𝗸𝗲 𝘆𝗼𝘂𝗿 𝘁𝗲𝘅𝘁 𝘀𝘁𝗮𝗻𝗱 𝗼𝘂𝘁 𝘄𝗶𝘁𝗵 𝗼𝘂𝗿 bold text generator. Easily add emphasis and style to your content.",
        keywords: "bold text generator, bold text, facebook bold text, bold text latex, how to bold text, instagram bold text, facebook bold text generator, bold text facebook, bold text facebook, bold text font, bold font, bold font generator, bold font copy and paste, bold font letters, bold facebook text"
    },
    {
        slug: "italic-font",
        title: "Italic Font Generator - 𝘊𝘳𝘦𝘢𝘵𝘦 𝘌𝘭𝘦𝘨𝘢𝘯𝘵 Italic Text",
        description: "Create elegant italic text with our font generator. Enhance your content with a touch of sophistication.",
        keywords: "italic font, italic font generator, italic text generator, italic text, discord italic text, italic font copy paste"
    },
    {
        slug: "sentence-case",
        title: "Sentence Case Converter - Capitalize Sentences Easily",
        description: "Easily capitalize sentences with our sentence case converter. Improve readability and professionalism in your text.",
        keywords: "sentence case, sentence case converter, convert sentence case"
    },
    {
        slug: "underline-text",
        title: "Underline Text Generator - A̲d̲d̲ E̲m̲p̲h̲a̲s̲i̲s̲ w̲i̲t̲h̲ Underlined Text",
        description: "Add emphasis to your text with our underline text generator. Create underlined content for impact.",
        keywords: "underline text, underline text generator, underline font, Unicode Underline, Underline Text Generator"
    },
    {
        slug: "title-case-converter",
        title: "Title Case Converter - Easily Capitalize Your Text",
        description: "Convert text to title case effortlessly with our title case converter. Improve readability and professionalism.",
        keywords: "title case converter, title case, title case generator, title capitalization tool, capitalization tool, headline capitalization tool"
    },
    {
        slug: "upside-down-text",
        title: "Upside Down Text Generator - Flip ʇxǝ⊥ ɹno⅄ Upside Down",
        description: "ǝʌᴉʇɐǝɹɔ ʇǝ⅁ with upside-down text. Flip your text for a unique and attention-grabbing twist.",
        keywords: "upside down text, upside down text generator, upside down font, flip text, type upside down"
    },
    {
        slug: "small-text-generator",
        title: "Small Text Generator - ᶜʳᵉᵃᵗᵉ Tiny ᴛᴇxᴛ for Special Effects",
        description: "Generate tiny text for special effects with our small text generator. Make your text discreet and intriguing.",
        keywords: "small text generator, small text, small text copy and paste, small text font, small text converter, small text gen, small text copy paste, small font, small font generator, small font copy paste, small font text, extremely small font generator, small font copy and paste"
    },
    {
        slug: "vaporwave-text-generator",
        title: "Vaporwave Text Generator - Ｃｒｅａｔｅ Aesthetic Text Styles",
        description: "𝗜𝗺𝗺𝗲𝗿𝘀𝗲 your text in the wide text or vaporwave aesthetic with our text generator. Enhance your content with unique styles.",
        keywords: "vaporwave text generator, vaporwave text, vaporwave font, wide font, wide text generator, wide font, wide text, Full Width Text Generator"
    },
    {
        slug: "wingdings-translator",
        title: "Wingdings Translator - Convert ❄︎♏︎⌧︎⧫︎ to Wingdings Font",
        description: "𝗧𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺 𝘆𝗼𝘂𝗿 text into the Wingdings font with our translator. Explore unique symbols and characters",
        keywords: "wingdings translator, wingdings font, wingdings font translator, wingdings font generator, wingdings text, wingdings text generator"
    },
    {
        slug: "text-repeater",
        title: "Text Repeater Online - Easily Repeat Text and Lines",
        description: "𝗘𝗳𝗳𝗼𝗿𝘁𝗹𝗲𝘀𝘀𝗹𝘆 repeat text and lines with our online text repeater. Save time and enhance your content",
        keywords: "text repeater, word repeater, repeat text, repeat text generator, line repeater, repeat sentence, repeats words, repeat text, repeat lines, Repeat text online, Repeat Text, Repeat String, Text Repeater Online"
    },
    {
        slug: "mirror-text-generator",
        title: "Mirror Text Generator - ǝʇɒǝɹƆ Reversed and Flipped Text",
        description: "ǝʇɒɹǝuǝפ reversed and flipped text with our mirror text generator. Add a captivating twist to your content.",
        keywords: "mirror text generator, mirror writing, flip text, mirror your text translator, mirror text generator, mirror text converter, text mirror converter"
    },
    {
        slug: "backwards-text-generator",
        title: "Backwards Text Generator - Reverse and Flip sdroW ruoY",
        description: "Reverse and flip your text with our backwards text generator. Experiment with intriguing text effects.",
        keywords: "backwards text generator, backwards text, alphabet backwards text, reverse text generator, text reverser, reverse text, reverse text generator, reversing text, reverses strings, reverse text generator, reverse words"
    },
    {
        slug: "invisible-character",
        title: "Invisible Character Generator - Add Mystery to Your Text",
        description: "ㅤㅤㅤ Add an air of mystery to your text with invisible characters. Explore unique and hidden text effects.",
        keywords: "invisible character, invisible character copy paste, invisible character unicode, invisible text generator, invisible character copy, invisible character discord, blank text, blank text copy paste, blank text copy and paste, empty characters"
    },
    {
        slug: "binary-code-translator",
        title: "Binary Code Translator - Decode and Encode Binary",
        description: "Decode 𝗮𝗻𝗱 encode (01100010) binary code with our online translator. Explore the world of binary conversions.",
        keywords: "binary code translator, binary code alphabet, binary code to text, how to read binary code, 11 in binary code, binary code decoder, binary code generator, binary code decode, binary code translator to english, numbers in binary code, binary code converter, translate binary code to english, text to binary code, decode binary code"
    },
    {
        slug: "hex-to-text",
        title: "Hex to Text Converter - Decode Hexadecimal to Text",
        description: "Decode 𝗮𝗻𝗱 encode (48 65 78) hexadecimal to text with our converter. Easily work with hexadecimal data.",
        keywords: "hex to text, hex to text converter, convert hexadecimal to text, convert hex to text, hex to ascii text converter"
    },
    {
        slug: "json-stringify-online",
        title: "JSON Stringify Online - Format and Convert JSON Text",
        description: "Format and convert JSON text with our online JSON stringify tool. Simplify JSON data handling.",
        keywords: "json stringify online, json stringify text, json stringifying text, json stringify online, json stringify online converter, json stringify text generator, json stringify text online"
    },
    {
        slug: "morse-code-translator",
        title: "Morse Code Translator - Decode and Encode Morse Code",
        description: "Decode .- -. -.. encode Morse code with our online translator. Explore the world of Morse code communication.",
        keywords: "morse code translator, morse code alphabet, morse code chart, morse code decoder, text to morse code, translate morse code, morse code generator, morse code translate, morse code to text, morse code converter, morse code translator, text to morse code, decode & encode morse code"
    },
    {
        slug: "nato-phonetic-alphabet",
        title: "NATO Phonetic Alphabet Generator - Improve Pronunciation",
        description: "Enhance pronunciation with the international phonetic alphabet. Use our generator for accurate phonetic spelling.",
        keywords: "phonetic alphabet, phonetic spelling, international phonetic alphabet, nato phonetic alphabet, phonetic spelling generator, NATO phonetic alphabet online translator, phonetic alphabet generator"
    },
    {
        slug: "utf-8-encoding",
        title: "UTF-8 Encoding - Convert Text to UTF-8 Format",
        description: "Convert text to UTF-8 encoding with our easy-to-use tool. Ensure compatibility and proper character encoding and decoder.",
        keywords: "utf-8 encoding, utf-8 decoder, utf-8 characters, utf 8 decoder, utf 8 encoding, utf 8 decoder, utf 8 encoding, UTF-8 encoder/decoder, UTF8 encoder online"
    },
    {
        slug: "online-alarm-clock",
        title: "Online Alarm Clock - Set Alarms and Reminders",
        description: "Set alarms and timers with our online alarm clock. Never miss an important today event again.",
        keywords: "online alarm clock, alarm clock"
    },
    // Add more page data entries as needed
];

export default SeoMeta;
