// Define multiple ad configurations

export const adTop = [
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-5.jpeg"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-6.png"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    // Add more ad slots configurations for the top section as needed
];

export const adMiddle1 = [
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-4.jpeg"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-6.png"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    // Add more ad slots configurations for the middle section as needed
];

export const adMiddle2 = [
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-4.jpeg"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-6.png"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    // Add more ad slots configurations for the middle section as needed
];

export const adBottom = [
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-4.jpeg"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-6.png"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    // Add more ad slots configurations for the bottom section as needed
];

export const adsqure = [
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-3.jpeg"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-6.png"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    // Add more ad slots configurations for the bottom section as needed
];
export const adStyle = [
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-5.jpeg"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    {
        shouldShowAds: false,
        adContent: (
            <img
                src="/static/images/Adds/add-6.png"  // Update with your image path
                alt="Ad Top Slot 1"
                className="add-image"
            />
        )
    },
    // Add more ad slots configurations for the top section as needed
];