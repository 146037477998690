import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, Box } from '@mui/material';

const ReminderItem = ({ reminder, deleteReminder, activateReminder }) => {
  const { title, date, time, id } = reminder;
  const [countdown, setCountdown] = useState('');
  const [showCountdown, setShowCountdown] = useState(false);

  const formatTime12Hour = (time24Hour) => {
    const [hour, minute] = time24Hour.split(':');
    const hourInt = parseInt(hour, 10);
    const amPm = hourInt >= 12 ? 'PM' : 'AM';
    const hour12 = (hourInt % 12) || 12; // Convert to 12-hour format

    return `${hour12}:${minute} ${amPm}`;
  };

  useEffect(() => {
    // ... (rest of your existing code)
  }, [date, time, reminder, activateReminder]);

  const handleDeleteReminder = () => {
    // Stop the alarm (if it's playing)
    // Remove the reminder from local storage
    deleteReminder(id);
  };

  return (
    <Grid container spacing={1} className="text-count">
      <Grid item>
        <Box style={{}}>
          <p><b className="color-text">Title: </b> {title}</p>
          <p><b className="color-text">Date and Time: </b> {date} {formatTime12Hour(time)}</p>
          {showCountdown && <p style={{ marginBottom: '12px' }}><b className="color-text">Countdown: </b> {countdown}</p>}
          <p><b className="color-text">Reminder Time: </b> {formatTime12Hour(time)}</p> {/* Display the time in 12-hour format */}
          <button style={{ backgroundColor: '#444', color: '#fff', border: 'none', padding: '8px 16px', cursor: 'pointer', borderRadius: '4px', marginTop: '10px' }} onClick={handleDeleteReminder}>
            Delete Reminder
          </button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReminderItem;
