import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const AdsPageComponent = ({ adConfigurations }) => {
  return (
    <Grid container spacing={2} className="ads-container row-min">
      {adConfigurations.map((adConfig, index) => (
        <Grid item key={index}>
          {adConfig.shouldShowAds && (
            <Box>
              {adConfig.adContent}
            </Box>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default AdsPageComponent;
