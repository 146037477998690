//Greek Plain
export const GreekPlain = (text) => {
    const plainChars = {
        A: 'Α', a: 'α', B: 'Β', b: 'β',  D: 'Δ', d: 'δ', E: 'Ε', e: 'ε',
        G: 'Γ', g: 'γ', H: 'Η', h: 'η', I: 'Ι', i: 'ι', K: 'Κ', k: 'κ',
        L: 'Λ', l: 'λ', M: 'Μ', m: 'μ', N: 'Ν', O: 'Ο', o: 'ο',
        P: 'Π', p: 'π', R: 'Ρ', r: 'ρ', s: 'σ', S: 'Σ', T: 'Τ', t: 'τ',
        X: 'Ξ', x: 'ξ', Y: 'Υ', y: 'υ', Z: 'Ζ', z: 'ζ',
    };

    return text
        .split('')
        .map((char) => plainChars[char] || char)
        .join('');
};



