// Bold Digits Representation
//Mathematical Bold (Updated alternative representation)
export const DigitsBoldNew = (text) => {
  const boldDigits = {
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒',
    '5': '𝟓', '6': '𝟔', '7': '𝟕', '8': '𝟖', '9': '𝟗'
  };

  return text
    .split('')
    .map((char) => boldDigits[char] || char)
    .join('');
};
// Output: '𝟏𝟐𝟑𝟒𝟓𝟔𝟕𝟖𝟗𝟏𝟎'
// Double-Struck Digits Representation
export const DigitsDoubleStruck = (text) => {
  const doubleStruckDigits = {
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜',
    '5': '𝟝', '6': '𝟞', '7': '𝟟', '8': '𝟠', '9': '𝟡'
  };

  return text
    .split('')
    .map((char) => doubleStruckDigits[char] || char)
    .join('');
};
// Output: '𝟘𝟙𝟚𝟛𝟜𝟝𝟞𝟟𝟠𝟡𝟙𝟘'
// Sans-Serif Digits Representation
export const DigitsSansSerif = (text) => {
  const sansSerifDigits = {
    '0': '𝟢', '1': '𝟣', '2': '𝟤', '3': '𝟥', '4': '𝟦',
    '5': '𝟧', '6': '𝟨', '7': '𝟩', '8': '𝟪', '9': '𝟫'
  };

  return text
    .split('')
    .map((char) => sansSerifDigits[char] || char)
    .join('');
};
// Output: '𝟢𝟣𝟤𝟥𝟦𝟧𝟨𝟩𝟪𝟫𝟙𝟘'
// Sans-Serif Bold Digits Representation
export const DigitsSansSerifBold = (text) => {
  const sansSerifBoldDigits = {
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰',
    '5': '𝟱', '6': '𝟲', '7': '𝟳', '8': '𝟴', '9': '𝟵'
  };

  return text
    .split('')
    .map((char) => sansSerifBoldDigits[char] || char)
    .join('');
};
// Output: '𝟬𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵𝟭𝟬'
// Monospace Digits Representation
export const DigitsMonoSpace = (text) => {
  const monoSpaceDigits = {
    '0': '𝟶', '1': '𝟷', '2': '𝟸', '3': '𝟹', '4': '𝟺',
    '5': '𝟻', '6': '𝟼', '7': '𝟽', '8': '𝟾', '9': '𝟿'
  };

  return text
    .split('')
    .map((char) => monoSpaceDigits[char] || char)
    .join('');
};
// Output: '𝟶𝟷𝟸𝟹𝟺𝟻𝟼𝟽𝟾𝟿𝟭𝟶'
export const DigitDevanagari = (text) => {
  const devanagariDigits = {
    '0': '०', '1': '१', '2': '२', '3': '३', '4': '४',
    '5': '५', '6': '६', '7': '७', '8': '८', '9': '९'
  };

  const devanagariTwoDigits = {
    '10': '१०', '11': '११', '12': '१२', '13': '१३', '14': '१४',
    '15': '१५', '16': '१६', '17': '१७', '18': '१८', '19': '१९',
    '20': '२०', '21': '२१', '22': '२२', '23': '२३', '24': '२४',
    '25': '२५', '26': '२६', '27': '२७', '28': '२८', '29': '२९',
    '30': '३०', '31': '३१', '32': '३२', '33': '३३', '34': '३४',
    '35': '३५', '36': '३६', '37': '३७', '38': '३८', '39': '३९',
    '40': '४०', '41': '४१', '42': '४२', '43': '४३', '44': '४४',
    '45': '४५', '46': '४६', '47': '४७', '48': '४८', '49': '४९',
    '50': '५०', '51': '५१', '52': '५२', '53': '५३', '54': '५४',
    '55': '५५', '56': '५६', '57': '५७', '58': '५८', '59': '५९',
    '60': '६०', '61': '६१', '62': '६२', '63': '६३', '64': '६४',
    '65': '६५', '66': '६६', '67': '६७', '68': '६८', '69': '६९',
    '70': '७०', '71': '७१', '72': '७२', '73': '७३', '74': '७४',
    '75': '७५', '76': '७६', '77': '७७', '78': '७८', '79': '७९',
    '80': '८०', '81': '८१', '82': '८२', '83': '८३', '84': '८४',
    '85': '८५', '86': '८६', '87': '८७', '88': '८८', '89': '८९',
    '90': '९०', '91': '९१', '92': '९२', '93': '९३', '94': '९४',
    '95': '९५', '96': '९६', '97': '९७', '98': '९८', '99': '९९', '100': '१००'
  };

  return text
    .split('')
    .map((char) => devanagariDigits[char] || devanagariTwoDigits[char] || char)
    .join('');
};
// Output: '१२३४५६७८९१०'

export const DigitsRoman = (text) => {
  const romanNumerals = {
    '1': 'ⅰ', '2': 'ⅱ', '3': 'ⅲ', '4': 'ⅳ', '5': 'ⅴ',
    '6': 'ⅵ', '7': 'ⅶ', '8': 'ⅷ', '9': 'ⅸ', '10': 'ⅹ',
    '11': 'ⅺ', '12': 'ⅻ', '13': 'ⅹⅳ', '14': 'ⅹⅴ', '15': 'ⅹⅵ',
    '16': 'ⅹⅶ', '17': 'ⅹⅷ', '18': 'ⅹⅸ', '19': 'ⅹⅹ', '20': 'ⅹⅹⅰ',
    '21': 'ⅹⅹⅱ', '22': 'ⅹⅹⅲ', '23': 'ⅹⅹⅳ', '24': 'ⅹⅹⅴ', '25': 'ⅹⅹⅵ',
    '26': 'ⅹⅹⅶ', '27': 'ⅹⅹⅷ', '28': 'ⅹⅹⅸ', '29': 'ⅹⅹⅹ', '30': 'ⅼ',
    '31': 'ⅼⅰ', '32': 'ⅼⅱ', '33': 'ⅼⅲ', '34': 'ⅼⅳ', '35': 'ⅼⅴ',
    '36': 'ⅼⅵ', '37': 'ⅼⅶ', '38': 'ⅼⅷ', '39': 'ⅼⅸ', '40': 'ⅼⅹ',
    '41': 'ⅼⅹⅰ', '42': 'ⅼⅹⅱ', '43': 'ⅼⅹⅲ', '44': 'ⅼⅹⅳ', '45': 'ⅼⅹⅴ',
    '46': 'ⅼⅹⅵ', '47': 'ⅼⅹⅶ', '48': 'ⅼⅹⅷ', '49': 'ⅼⅹⅸ', '50': 'ⅼⅹⅹ',
    '51': 'ⅼⅹⅹⅰ', '52': 'ⅼⅹⅹⅱ', '53': 'ⅼⅹⅹⅲ', '54': 'ⅼⅹⅹⅳ', '55': 'ⅼⅹⅹⅴ',
    '56': 'ⅼⅹⅹⅵ', '57': 'ⅼⅹⅹⅶ', '58': 'ⅼⅹⅹⅷ', '59': 'ⅼⅹⅹⅸ', '60': 'ⅼⅹⅹⅹ',
    '61': 'ⅼⅹⅹⅹⅰ', '62': 'ⅼⅹⅹⅹⅱ', '63': 'ⅼⅹⅹⅹⅲ', '64': 'ⅼⅹⅹⅹⅳ', '65': 'ⅼⅹⅹⅹⅴ',
    '66': 'ⅼⅹⅹⅹⅵ', '67': 'ⅼⅹⅹⅹⅶ', '68': 'ⅼⅹⅹⅹⅷ', '69': 'ⅼⅹⅹⅹⅸ', '70': 'ⅼⅹⅹⅹⅹ',
    '71': 'ⅼⅹⅹⅹⅹⅰ', '72': 'ⅼⅹⅹⅹⅹⅱ', '73': 'ⅼⅹⅹⅹⅹⅲ', '74': 'ⅼⅹⅹⅹⅹⅳ', '75': 'ⅼⅹⅹⅹⅹⅴ',
    '76': 'ⅼⅹⅹⅹⅹⅵ', '77': 'ⅼⅹⅹⅹⅹⅶ', '78': 'ⅼⅹⅹⅹⅹⅷ', '79': 'ⅼⅹⅹⅹⅹⅸ', '80': 'ⅼⅹⅹⅹⅹⅹ',
    '81': 'ⅼⅹⅹⅹⅹⅹⅰ', '82': 'ⅼⅹⅹⅹⅹⅹⅱ', '83': 'ⅼⅹⅹⅹⅹⅹⅲ', '84': 'ⅼⅹⅹⅹⅹⅹⅳ', '85': 'ⅼⅹⅹⅹⅹⅹⅴ',
    '86': 'ⅼⅹⅹⅹⅹⅹⅵ', '87': 'ⅼⅹⅹⅹⅹⅹⅶ', '88': 'ⅼⅹⅹⅹⅹⅹⅷ', '89': 'ⅼⅹⅹⅹⅹⅹⅸ', '90': 'ⅽ',
    '91': 'ⅽⅰ', '92': 'ⅽⅱ', '93': 'ⅽⅲ', '94': 'ⅽⅳ', '95': 'ⅽⅴ',
    '96': 'ⅽⅵ', '97': 'ⅽⅶ', '98': 'ⅽⅷ', '99': 'ⅽⅸ', '100': 'ⅽⅹ'
  };

  return text
    .split('')
    .map((char) => romanNumerals[char] || char)
    .join('');
};
// Output: 'ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹ'
// Function to convert normal digits to bold digits
export const DigitsBold = (text) => {
  const boldDigits = {
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰',
    '5': '𝟱', '6': '𝟲', '7': '𝟳', '8': '𝟴', '9': '𝟵'
  };

  return text
    .split('')
    .map((char) => boldDigits[char] || char)
    .join('');
};
// Output: '𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵𝟬'
// Function to convert normal digits to fullwidth digits
export const FullwidthDigits = (text) => {
  const fullwidthDigits = {
    '0': '０', '1': '１', '2': '２', '3': '３', '4': '４',
    '5': '５', '6': '６', '7': '７', '8': '８', '9': '９'
  };

  return text
    .split('')
    .map((char) => fullwidthDigits[char] || char)
    .join('');
};
// Output: '１２３４５６７８９１０'

// Function to convert normal digits to subscript digits
export const SubscriptDigits = (text) => {
  const subscriptDigits = {
    '0': '₀', '1': '₁', '2': '₂', '3': '₃', '4': '₄',
    '5': '₅', '6': '₆', '7': '₇', '8': '₈', '9': '₉'
  };

  return text
    .split('')
    .map((char) => subscriptDigits[char] || char)
    .join('');
};
// Output: '₁₂₃₄₅₆₇₈₉₀'
// Function to convert normal digits to superscript digits
export const SuperscriptDigits = (text) => {
  const superscriptDigits = {
    '0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴',
    '5': '⁵', '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹'
  };

  return text
    .split('')
    .map((char) => superscriptDigits[char] || char)
    .join('');
};
// Output: '¹²³⁴⁵⁶⁷⁸⁹¹⁰'
//Unicode Ethiopic characters for numbers beyond 0 to 9,
export const plainToUnicodeEthiopic = (text) => {
  const unicodeEthiopic = {
    '0': '፩', '1': '፪', '2': '፫', '3': '፬', '4': '፭',
    '5': '፮', '6': '፯', '7': '፰', '8': '፱', '9': '፲',
    '10': '፲፩', '20': '፳', '30': '፴', '40': '፵', '50': '፶',
    '60': '፷', '70': '፸', '80': '፹', '90': '፺',
    '100': '፻', '1000': '፲፻', '10000': '፼'
  };

  return text
    .split('')
    .map((char) => unicodeEthiopic[char] || char)
    .join('');
};
// Output: '፩፪፫፬፭፮፯፰፱፲'

// Both Eastern Arabic and Persian/Farsi scripts 
export const plainToUnicodeArabic = (text) => {
  const unicodeArabicEastern = {
    '0': '٠', '1': '١', '2': '٢', '3': '٣', '4': '٤',
    '5': '٥', '6': '٦', '7': '٧', '8': '٨', '9': '٩'
  };

  const unicodeArabicPersian = {
    '0': '۰', '1': '۱', '2': '۲', '3': '۳', '4': '۴',
    '5': '۵', '6': '۶', '7': '۷', '8': '۸', '9': '۹'
  };

  return text
    .split('')
    .map((char) => unicodeArabicEastern[char] || unicodeArabicPersian[char] || char)
    .join('');
};
// Output: '١٢٣٤٥٦٧٨٩۱۰'
//numeric digits to their Unicode Bengali or Tamil counterparts
export const plainToUnicodeDigits = (text) => {
  const unicodeBengali = {
    '0': '০', '1': '১', '2': '২', '3': '৩', '4': '৪',
    '5': '৫', '6': '৬', '7': '৭', '8': '৮', '9': '৯'
  };

  const unicodeTamil = {
    '0': '௦', '1': '௧', '2': '௨', '3': '௩', '4': '௪',
    '5': '௫', '6': '௬', '7': '௭', '8': '௮', '9': '௯'
  };

  return text
    .split('')
    .map((char) => unicodeBengali[char] || unicodeTamil[char] || char)
    .join('');
};
// Output: '১২৩৪৫৬৭৮৯১০'
export const NumericEmojiText = (text) => {
  const numericEmojiChars = {
    '1': '1️⃣', '2': '2️⃣', '3': '3️⃣', '4': '4️⃣', '5': '5️⃣',
    '6': '6️⃣', '7': '7️⃣', '8': '8️⃣', '9': '9️⃣', '10': '🔟'
  };

  return text
    .split('')
    .map((char) => numericEmojiChars[char] || char)
    .join('');
};
//// Output: 1️⃣2️⃣3️⃣4️⃣5️⃣6️⃣7️⃣8️⃣9️⃣🔟

export const DigitsStrikethrough = (text) => {
  const strikethroughDigits = {
    '0': '0̶', '1': '1̶', '2': '2̶', '3': '3̶', '4': '4̶',
    '5': '5̶', '6': '6̶', '7': '7̶', '8': '8̶', '9': '9̶'
  };

  return text
    .split('')
    .map((char) => strikethroughDigits[char] || char)
    .join('');
};
// Output: '1̶2̶3̶4̶5̶6̶7̶8̶9̶'

export const DigitsUnderline = (text) => {
  const underlineDigits = {
    '0': '0̲', '1': '1̲', '2': '2̲', '3': '3̲', '4': '4̲',
    '5': '5̲', '6': '6̲', '7': '7̲', '8': '8̲', '9': '9̲'
  };

  return text
    .split('')
    .map((char) => underlineDigits[char] || char)
    .join('');
};
// Output: '1̲2̲3̲4̲5̲6̲7̲8̲9̲'
export const DigitsWavyUnderline = (text) => {
  const wavyUnderlineDigits = {
    '0': '0̴', '1': '1̴', '2': '2̴', '3': '3̴', '4': '4̴',
    '5': '5̴', '6': '6̴', '7': '7̴', '8': '8̴', '9': '9̴'
  };

  return text
    .split('')
    .map((char) => wavyUnderlineDigits[char] || char)
    .join('');
};
// Output: '1̴2̴3̴4̴5̴6̴7̴8̴9̴'  
export const DigitsSquareBrackets = (text) => {
  return text
    .split('')
    .map((char) => `【${char}】`)
    .join('');
};
// Output: '【1】【2】【3】【4】【5】【6】【7】【8】【9】'
export const DigitsCurlyBrackets = (text) => {
  return text
    .split('')
    .map((char) => `『${char}』`)
    .join('');
};
// Output: '『1』『2』『3』『4』『5』『6』『7』『8』『9』'
export const DigitsFullWidthPeriod = (text) => {
  const fullWidthPeriod = '．';

  return text
    .split('')
    .map((char) => (char >= '0' && char <= '9') ? `${char}${fullWidthPeriod}` : char)
    .join('');
};
// Output: '１．２．３．４．５．６．７．８．９．１０．'
export const DigitsRegularPeriod = (text) => {
  const regularPeriod = '.';

  return text
    .split('')
    .map((char) => (char >= '0' && char <= '9') ? `${char}${regularPeriod}` : char)
    .join('');
};
// Output: '１.２.３.４.５.６.７.８.９.１０.'  

//Chinese/Japanese/Korean Circled Number
export const CircledNumbers = (text) => {
  const circledNumbers = {
    '0': '㊀', '1': '㊁', '2': '㊂', '3': '㊃', '4': '㊄',
    '5': '㊅', '6': '㊆', '7': '㊇', '8': '㊈', '9': '㊉'
  };

  return text
    .split('')
    .map((char) => circledNumbers[char] || char)
    .join('');
};
//// Output: '㊀㊁㊂㊃㊄㊅㊆㊇㊈㊉'
//double circled numbers
export const DubbleCircles = (text) => {
  const dubbleCirclesDigits = {
    '0': '⓪', '1': '⓵', '2': '⓶', '3': '⓷', '4': '⓸',
    '5': '⓹', '6': '⓺', '7': '⓻', '8': '⓼', '9': '⓽'
  };

  return text
    .split('')
    .map((char) => dubbleCirclesDigits[char] || char)
    .join('');
};
export const AddDecoration33 = (text) => {
  return `₹ ${text}`;
};
export const AddDecoration34 = (text) => {
  return `₨ ${text}`;
};