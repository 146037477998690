import React from 'react';
import TestComponent from '../Backup/Testing/TestComponent';
import BoldTextConverter from '../pages/StringManipulation/BoldTextConverter';
import CaseConver from './StringManipulation/CaseConverter';
import BubbleTextGenerator from '../pages/StringManipulation/BubbleTextGenerator';
import BinaryCodeTranslator from '../pages/StringManipulation/BinaryCodeTranslator';
import FacebookFontGenerator from '../pages/StringManipulation/FacebookFontGenerator';
import HextoTextConverter from '../pages/StringManipulation/HextoTextConverter';
import InvisibleTextGenerator from '../pages/StringManipulation/InvisibleTextGenerator';
import ItalicTextGenerator from '../pages/StringManipulation/ItalicTextGenerator';
import JSONStringifyTextGenerator from '../pages/StringManipulation/JSONStringifyTextGenerator';
import MirrorTextGenerator from '../pages/StringManipulation/MirrorTextGenerator';
import MorseCodeTranslator from '../pages/StringManipulation/MorseCodeTranslator';
import NATOPhoneticAlphabet from '../pages/StringManipulation/NATOPhoneticAlphabet';
import PlainTextConverter from '../pages/StringManipulation/PlainTextConverter';
import RepeatText from '../pages/StringManipulation/RepeatText';
import TextReplacer from '../pages/StringManipulation/TextReplacer';
import ReverseTextGenerator from '../pages/StringManipulation/ReverseTextGenerator';
import RomanNumeralDateConverter from '../pages/StringManipulation/RomanNumeralDateConverter';
import SlugifyURLGenerator from '../pages/StringManipulation/SlugifyURLGenerator';
import SentenceCaseConverter from '../pages/StringManipulation/SentenceCaseConverter';
import StrikethroughTextGenerator from '../pages/StringManipulation/StrikethroughTextGenerator';
import TitleCaseConverter from '../pages/StringManipulation/TitleCaseConverter';
import UnderlineText from '../pages/StringManipulation/UnderlineText';
import UpsideDownTextGenerator from '../pages/StringManipulation/UpsideDownTextGenerator';
import SmallTextGenerator from '../pages/StringManipulation/SmallTextGenerator';
import UTF8EncoderDecoder from '../pages/StringManipulation/UTF8EncoderDecoder';
import WideTextGenerator from '../pages/StringManipulation/WideTextGenerator';
import WingdingsConverter from '../pages/StringManipulation/WingdingsConverter';
import CursedText from '../pages/StringManipulation/CursedText';
import UnicodeText from '../pages/StringManipulation/UnicodeText';
import FontsStyle from '../pages/StringManipulation/FontsStyle';
import DigitsStyle from '../pages/StringManipulation/DigitsStyle';
import TypingStyleFonts from '../pages/StringManipulation/TypingStyleFonts';
import Notepad from '../pages/StringManipulation/Notepad';
import WordCounter from '../pages/StringManipulation/WordCounter';
import ReminderAlert from './ReminderAlert/ReminderAlert';


const HomePage = () => {
  return (
    <div>
      {/* <TestComponent/> */}
      {/* <BoldTextConverter/> */}
      {/* <CaseConver/> */}
      {/* <BubbleTextGenerator/> */}
      {/* <BinaryCodeTranslator/> */}
      {/* <FacebookFontGenerator/> */}
      {/* <HextoTextConverter/> */}
      {/* <InvisibleTextGenerator/> */}
      {/* <ItalicTextGenerator/> */}
      {/* <JSONStringifyTextGenerator/> */}
      {/* <MirrorTextGenerator/> */}
      {/* <MorseCodeTranslator/> */}
      {/* <NATOPhoneticAlphabet/> */}
      {/* <RepeatText/> */}
      {/* <PlainTextConverter/> */}
      {/* <TextReplacer /> */}
      {/* <ReverseTextGenerator/> */}
      {/* <RomanNumeralDateConverter /> */}
      {/* <SlugifyURLGenerator/> */}
      {/* <SentenceCaseConverter/> */}
      {/* <StrikethroughTextGenerator/> */}
      {/* <TitleCaseConverter/> */}
      {/* <UnderlineText/> */}
      {/* <UpsideDownTextGenerator/> */}
      {/* <SmallTextGenerator/> */}
      {/* <UTF8EncoderDecoder/> */}
      {/* <WideTextGenerator/> */}
      {/* <WingdingsConverter/> */}
      {/* <CursedText/> */}
      {/* <UnicodeText/> */}
      {/* <FontsStyle/> */}
      {/* <DigitsStyle/> */}
      <TypingStyleFonts/>
      {/* <Notepad/> */}
      {/* <WordCounter/> */}
      {/* <ReminderAlert/> */}
    </div>
  );
};

export default HomePage;
