import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Snackbar } from '@mui/material';

const ReminderForm = ({ addReminder, reminders }) => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [playDuration, setPlayDuration] = useState(45);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [played, setPlayed] = useState(false); // Added played property

  useEffect(() => {
    if (successMessage) {
      setOpenSuccessSnackbar(true);
      const timer = setTimeout(() => {
        setOpenSuccessSnackbar(false);
        setSuccessMessage('');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleAddReminder = () => {
    if (validateInputs()) {
      const id = generateUniqueReminderID();
      const alarmDateTime = new Date(`${date}T${time}`);
      const currentDateTime = new Date();

      if (alarmDateTime <= currentDateTime) {
        alert('Alarm creation failed. Please select a future time for your alarm.');
        return;
      }

      const isDuplicate = reminders.some((reminder) => {
        const reminderDateTime = new Date(`${reminder.date}T${reminder.time}`);
        return (
          reminderDateTime.getHours() === alarmDateTime.getHours() &&
          reminderDateTime.getMinutes() === alarmDateTime.getMinutes()
        );
      });

      if (isDuplicate) {
        alert('Alarm creation failed. An alarm with the same date and time already exists.');
        return;
      }

      const reminder = {
        id,
        title,
        date,
        time,
        playDuration,
        creationDateTime: new Date().toISOString(),
        expirationDate: alarmDateTime.toISOString().slice(0, 10),
        expirationTime: `${time.slice(0, 2)}:${time.slice(3)}`, // Use the same time as input
        played, // Added played property
      };

      addReminder(reminder);
      setTitle('');
      setDate('');
      setTime('');
      setPlayDuration(45);
      setSuccessMessage('Your reminder alert was set successfully.');
      setPlayed(false); // Reset played property
    } else {
      alert('All fields are required');
    }
  };

  const validateInputs = () => {
    if (!title || !date || !time) {
      setError('All fields are required');
      return false;
    }

    setError('');
    return true;
  };

  const generateUniqueReminderID = () => {
    return `reminder_${Date.now()}_${Math.floor(Math.random() * 100000)}`;
  };

  // Function to get the current date in "mm/dd/yyyy" format
  function getCurrentDate() {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    return `${month}/${day}/${year}`;
  }

  return (
    <Grid container spacing={1} className="text-count">
      <Grid item xs={12}>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          error={!!error}
          helperText={error}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label=""
          type="date"
          variant="outlined"
          fullWidth
          value={date}
          onChange={(e) => setDate(e.target.value)}
          error={!!error}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label=""
          type="time"
          variant="outlined"
          fullWidth
          value={time}
          onChange={(e) => setTime(e.target.value)}
          error={!!error}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleAddReminder}>
          Set Alarm
        </Button>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnackbar}
        autoHideDuration={6000} // Set to 6 seconds
        onClose={() => setOpenSuccessSnackbar(false)}
        message={successMessage}
      />
    </Grid>
  );
};

export default ReminderForm;
