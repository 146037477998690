import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TextTextAreaHorizontal from '../../common/Layouts/TextTextAreaHorizontal';
import TextTextAreaVerticale from '../../common/Layouts/TextTextAreaVerticale';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import { PlainTextDate, RomanNumeralDate } from '../../features/StringManipulation/TextManipuation';
// for seo
import SEOComponent from '../../common/SEOComponent';

const RomanNumeralDateConverter = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(true);

    // All functions and Define the initial text
    const [currentDate, setCurrentDate] = useState(new Date());
    //get current date 
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000); // Update the date every second

        return () => clearInterval(interval); // Clean up the interval when the component unmounts
    }, []);
    const formattedCurrentDate = currentDate.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });
    const Romandate = RomanNumeralDate(formattedCurrentDate);
    const Placeholderoutput = Romandate;

    const Placeholderinput = formattedCurrentDate;

    // end date
    const [initialOutputText, setInitialOutputText] = useState(Placeholderoutput);
    const [inputplaceholder, setinputplaceholder] = useState(Placeholderinput);

    const TextConversion = (inputText) => {
        const convertedText = RomanNumeralDate(inputText);
        return convertedText;
    };

    const TextConversionOutput = (inputText) => {
        const convertedOutput = PlainTextDate(inputText);
        return convertedOutput;
    };
    // Automatically scroll the output textarea to the bottom when outputText changes
    useEffect(() => {
        const outputTextarea = outputTextareaRef.current;
        if (outputTextarea) {
            outputTextarea.scrollTop = outputTextarea.scrollHeight;
        }
    }, [outputText]);
    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        // Perform conversion for input text
        const convertedInput = TextConversion(text);
        // Update outputText state based on the conversion
        setOutputText(convertedInput);
        setShowClearButton(text.length > 0);
        setIsCopied(false);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
    };
    // Handle output text changes function
    const handleOutputChange = (event) => {
        const text = event.target.value;
        setOutputText(text);
        // Perform conversion for output text
        const convertedOutput = TextConversionOutput(text);
        // Update inputText state based on the conversion, only if there's content
        if (text.trim() === '') {
            setInputText('');
        } else {
            setInputText(convertedOutput);
        }
        setShowClearButton(text.length > 0);
        setIsCopied(false);
    };

    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText('');
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const outputText = outputDiv.textContent.trim(); // Remove leading/trailing spaces and newlines
            if (outputText) {
                const textArea = document.createElement('textarea');
                textArea.value = outputText;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                setIsCopied(true);
            }
        }
    };

    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string
        const fileContent = outputText.replace(/,/g, '');

        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'BinaryCodeASCII.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);

        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };

    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;
    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Dive into the World of Roman Numerals and Conversions</h1>
                        <p>Discover the rich history of Roman numerals and easily convert numbers using our Roman numeral tools. From simple conversions to date formatting, we've got it all covered.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TextTextAreaHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : ""}
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                    handleOutputChange={handleOutputChange}
                    inputplaceholder={inputplaceholder}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TextTextAreaVerticale
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : ""}
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        handleOutputChange={handleOutputChange}
                        inputplaceholder={inputplaceholder}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                       <h2>Roman Numerals - Dive into Numeric History</h2>
                       <p>Roman numerals are not just a numeric system; they are a window into history. Explore the intriguing world of Roman numerals and effortless conversions with our Roman Numeral Tools. Whether you need to convert numbers from 1 to 100, explore intricate numerals like IV and XXIX, or work with larger values like 500, our tools have you covered. Additionally, simplify date formatting and make your content pop with captivating Roman numeral fonts.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default RomanNumeralDateConverter;
