import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { StyledButtonIcon } from '../TagsStyle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const SwapButtonComponent = ({ handleSwapClick, isHorizontalLayout }) => {
  return (
    <Grid container className="row-min" spacing={2} sx={{ position: 'relative' }}>
      <Grid item>
        <Box className="swich">
          {/* Swap button */}
          <StyledButtonIcon
            className="button-shadow"
            variant="contained"
            size="small"
            onClick={handleSwapClick}
          >
            {isHorizontalLayout ? <SwapVertIcon /> : <SwapHorizIcon />}
          </StyledButtonIcon>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SwapButtonComponent;
