import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import AdsComponent from '../../adds/AdsPageComponent';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import {
  StyledClearButton, StyledButtonIcon, StyledCopyButton,
} from '../../common/TagsStyle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// for seo
import SEOComponent from '../../common/SEOComponent';

function Notepad() {
  const [content, setContent] = useState('');
  const [editorHtml, setEditorHtml] = useState('');
  const editorRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false); // Track copy operation

  // Load the initial content from localStorage
  useEffect(() => {
    const savedContent = localStorage.getItem('notepadContent');
    if (savedContent) {
      setContent(savedContent);
      setEditorHtml(savedContent); // Update editorHtml with the saved content
    }
  }, []);

  // Auto-save the content to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('notepadContent', content);
  }, [content]);

  useEffect(() => {
    // Reset isCopied to false when editorHtml changes
    setIsCopied(false);
  }, [editorHtml]);

  // Custom toolbar for the editor
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ color: [] }, { background: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: '1' }, { header: '2' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ],
  };
  // Custom CSS styles for the editor
  const editorStyles = {
    width: '100%',
  };

  // Custom functions for Print, Download, and Copy
  const handlePrint = () => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Notepad Print</title></head><body>');
    printWindow.document.write('<style>@page { size: A4; margin: 20mm; }</style>'); // Set page size and margin
    printWindow.document.write('<pre style="font-size: 12pt; line-height: 1;">' + content + '</pre>'); // Add padding and styling
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleDownload = () => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const plainText = editor.getText(); // Get plain text from the editor

      const blob = new Blob([plainText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'notepad.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
  const handleCopy = () => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      const plainText = editor.getText(); // Get plain text from the editor

      // Create a textarea to copy the plain text
      const textArea = document.createElement('textarea');
      textArea.value = plainText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setIsCopied(true);
    }
  };

  // In your ReactQuill component, update the value prop to editorHtml
  return (
    <div className="main">
      {/* For Seo on page Head information */}
      <SEOComponent />
      {/* Google Ads Middle-1 --Start */}
      <AdsComponent adConfigurations={adMiddle1} />
      {/* Google Ads Middle-1 --End */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <h1>Explore Online Notepad Tools for Easy Note-Taking</h1>
            <p> Our free online notepad offers a user-friendly interface and hassle-free access to your notes. Take notes anytime, anywhere. </p>
          </Box>
        </Grid>
      </Grid>
      <Grid container className="row-min" spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box display="inline">
            <StyledCopyButton
              className="copied-note"
              onClick={handleCopy}
              size="small"
              endIcon={<CopyAllIcon />}
              style={{ marginRight: '10px', marginBottom: '5px' }} // Add margin to the right
            >
              Copy
            </StyledCopyButton>
            <StyledCopyButton
              className="copied-note"
              onClick={handleDownload}
              size="small"
              endIcon={<FileDownloadIcon />}
              style={{ marginRight: '10px', marginBottom: '5px' }} // Add margin to the right
            >
              Download
            </StyledCopyButton>
            <StyledCopyButton
              className="copied-note"
              onClick={handlePrint}
              size="small"
              endIcon={<PrintIcon />}
              style={{ marginRight: '10px', marginBottom: '5px' }} // Add margin to the right
            >
              Print
            </StyledCopyButton>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={12} className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <ReactQuill
              className="text-input"
              ref={editorRef}
              value={editorHtml}
              onChange={(value) => {
                setEditorHtml(value);
                setContent(value);
              }}
              modules={modules}
              style={editorStyles}
            />

          </Box>
        </Grid>
      </Grid>
      {/* Google Ads Middle-2 --Start */}
      <AdsComponent adConfigurations={adMiddle2} />
      {/* Google Ads Middle-2 --End */}
      {/* Similar Links */}
      <SimilarLinkComponent />
      {/* Google Ads bottom --Start */}
      <AdsComponent adConfigurations={adBottom} />
      {/* Google Ads bottom --End */}
      {/* Page Information */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <h2>Online Notepad - Free and Easy Note-Taking Tool</h2>
            <p>
              When it comes to convenient note-taking, our Online Notepad tools have you covered. With features like a user-friendly interface and easy access to your notes from anywhere, our free online notepad is the best choice for all your note-taking needs. Say goodbye to the hassle of carrying around physical notepads and embrace the simplicity of digital note-taking with our top-notch Online Notepad.
            </p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Notepad;
