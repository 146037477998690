import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import TextTextAreaSingleAuto from '../../common/Layouts/TextTextAreaSingleAuto';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
// for seo
import SEOComponent from '../../common/SEOComponent';

const WordCounter = () => {
    // State variables
    const [isCopied, setIsCopied] = useState(false);
    const [outputText, setOutputText] = useState('');
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);

    // All functions and Define the initial text
    const Placeholderoutput = "Start Typing or paste your text..";
    const [initialOutputText, setInitialOutputText] = useState(Placeholderoutput);

    // State variable for selected keyword density (1X, 2X, 3X, TOP)
    const [selectedDensity, setSelectedDensity] = useState('TOP');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const LOCAL_STORAGE_KEY = "autoSavedTextcout"; // Key for local storage

    // Load auto-saved text from local storage when the component mounts
    useEffect(() => {
        const savedText = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (savedText) {
            setOutputText(savedText);
        }
    }, []);

    // Save the text area's content to local storage whenever it changes
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, outputText);
    }, [outputText]);
    useEffect(() => {
        const outputTextarea = outputTextareaRef.current;
        if (outputTextarea) {
            outputTextarea.scrollTop = outputTextarea.scrollHeight;
        }
    }, [outputText]);

    // Helper function to calculate reading time based on word count and an assumed reading speed (words per minute)
    function calculateReadingTime(wordCount) {
        const wordsPerMinute = 200; // Industry standard for average adult reading speed
        return wordCount / wordsPerMinute;
    }

    // Helper function to calculate speaking time based on word count and an assumed speaking speed (words per minute)
    function calculateSpeakingTime(wordCount) {
        const wordsPerMinute = 125; // Industry standard for average speaking speed
        return wordCount / wordsPerMinute;
    }

    // Define arrays for pronouns and possessive determiners in lowercase
    const pronouns = [
        'he', 'she', 'it', 'me', 'us', 'them', 'myself', 'himself', 'herself', 'itself',
        'oneself', 'who', 'whom', 'whose', 'which', 'what', 'whichever', 'whatever',
        'whoever', 'whomever', 'whosesoever', 'anyone', 'someone', 'everyone', 'nobody', 'somebody', 'everybody', 'no one',
        'i', 'you', 'we', 'they',
        'he/she', 'him/her', 'his/her', 'his/hers', 'himself/herself',
    ];

    const possessiveDeterminers = [
        'my', 'your', 'his', 'her', 'its', 'our', 'their', 'mine', 'yours', 'hers', 'ours', 'theirs',
        'to', 'with', 'your', 'we', 'the', 'and', 'this', 'that', 'these', 'those', 'such', 'many', 'few', 'several',
        'any', 'some', 'most', 'several', 'enough', 'more', 'less',
        'another', 'each', 'every', 'neither', 'either', 'to', 'with', 'your', 'we', 'the', 'and', 'this',
        'of', 'is', 'in', 'can',
    ];

    function calculateKeywordDensities(text, densityLevel) {
        // Convert the text to lowercase for case insensitivity
        const lowercaseText = text.toLowerCase();

        // Split the text into words using non-alphabet characters as separators
        const words = lowercaseText.split(/[^a-zA-Z]+/).filter(Boolean);

        const keywordMap = {};

        // Define a list of common stop words to exclude
        const stopWords = [
            'a', 'an', 'and', 'the', 'in', 'on', 'at', 'to', 'for', 'with', 'by', 'of', 'is', 'it', 'as', 'was', 'were', 'be',
            'that', 'this', 'from', 'or', 'not', 'but', 'such', 'you', 'your', 'we', 'they', 'he', 'she', 'i', 'me', 'him',
            'her', 'my', 'our', 'us', 'them', 'its', 'whose', 'whom', 'which', 'what', 'where', 'when', 'how', 'why', 'over',
            'under', 'between', 'through', 'into', 'out', 'down', 'up', 'upon', 'while', 'before', 'after', 'about', 'by',
            'during', 'although', 'though', 'unless', 'until', 'unless', 'since', 'than', 'unless', 'throughout', 'till', 'unless',
            'unless', 'above', 'below', 'beneath', 'behind', 'beside', 'along', 'against', 'among', 'amongst', 'within', 'whenever',
            'wherever', 'almost', 'sometimes', 'always', 'never', 'ever', 'often', 'rarely', 'seldom', 'frequently', 'daily', 'weekly',
            'monthly', 'yearly', 'once', 'twice', 'thrice', 'if',
        ];

        // Iterate through the words in the text
        for (let i = 0; i < words.length; i++) {
            const word = words[i];

            // Rule 4: Exclude common stop words
            if (stopWords.includes(word)) {
                continue;
            }

            // Rule 2: Exclude keywords that are too short
            if (word.length < 3) {
                continue;
            }

            // Check if the word consists of repeated characters (e.g., "aa," "aaaa," "ppp")
            if (/^([a-z])\1+$/.test(word)) {
                continue;
            }

            // Rule 5: Exclude Pronouns and Determiners
            if (pronouns.includes(word) || possessiveDeterminers.includes(word)) {
                continue; // Skip pronouns and possessive determiners
            }

            // Check for keywords of various lengths (1X, 2X, 3X)
            for (let j = 1; j <= 3; j++) {
                if (i + j <= words.length) {
                    const phrase = words.slice(i, i + j).join(' ');

                    // Rule 3: Set a minimum keyword frequency threshold
                    if (keywordMap[phrase]) {
                        keywordMap[phrase] += 1;
                    } else {
                        keywordMap[phrase] = 1;
                    }
                }
            }
        }

        // Convert the keyword map to an array
        const keywordList = Object.keys(keywordMap).map((word) => ({
            word,
            count: keywordMap[word],
        }));

        // Calculate density for each keyword
        const totalWords = words.length;
        keywordList.forEach((keyword) => {
            const wordCount = keyword.word.split(/\s+/).length;
            keyword.density = ((keyword.count * wordCount) / totalWords) * 100;
        });

        // Filter out keywords with a density of 0%
        const filteredKeywords = keywordList.filter((keyword) => keyword.density > 0);

        // Sort keywords by density in descending order
        filteredKeywords.sort((a, b) => b.density - a.density);

        // Rule 1: Filter by Density
        if (densityLevel === 'TOP') {
            return filteredKeywords;
        } else {
            const filteredByDensity = filteredKeywords.filter((keyword) => {
                return keyword.word.split(/\s+/).length === parseInt(densityLevel);
            });
            return filteredByDensity;
        }
    }

    const allKeywordDensities = outputText ? calculateKeywordDensities(outputText, selectedDensity) : [];

    // Filter keywords based on selected density
    const filteredKeywords = allKeywordDensities.filter((keyword) => {
        if (outputText === '') {
            return false; // Return empty list if the text area is empty
        } else if (selectedDensity === 'TOP') {
            // Display all keywords in 'TOP' density
            return true;
        } else {
            // Display keywords that match the selected density
            return keyword.word.split(/\s+/).length === parseInt(selectedDensity);
        }
    });

    // Calculate pagination
    const totalPages = Math.ceil(filteredKeywords.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentKeywordDensity = filteredKeywords.slice(startIndex, endIndex);

    // Handle density selection change
    const handleDensityChange = (density) => {
        setSelectedDensity(density);
        setCurrentPage(1);
    };

    // Handle pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle output text changes function
    const handleOutputChange = (event) => {
        const text = event.target.value;
        setOutputText(text);
        setIsCopied(false);
    };

    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        const outputTextarea = outputTextareaRef.current;
        if (outputDiv) {
            const outputText = outputDiv.textContent.trim(); // Remove leading/trailing spaces and newlines
            if (outputText) {
                const textArea = document.createElement('textarea');
                textArea.value = outputText;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                setIsCopied(true);
                outputTextarea.select();
            }
        }
    };

    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with an empty string
        const fileContent = outputText.replace(/,/g, '');

        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'TextReplace.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);

        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };

    // Calculate character count
    const characterCount = outputText.length;
    // Calculate word count
    const wordCount = outputText.trim().length > 0 ? outputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = outputText.length > 0 ? outputText.split('\n').length : 0;
    // Calculate character count without spaces
    const characterCountNoSpace = outputText.replace(/\s/g, '').length;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Accurate Word Counting Made Simple with Our Online Word Counter</h1>
                        <p>
                            Counting words and Keyword Density is a breeze with our versatile online word counter tool. Whether you're working on an essay, a website, or any text, our word counter website has you covered.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h3 className="count-section" style={{ fontSize: '2.4em' }}>
                            <span>{characterCount} Characters </span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines </span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            <Grid container className="row-min" spacing={2}>
                <Grid item xs={12} md={8}>
                    <TextTextAreaSingleAuto
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : ''}
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        handleOutputChange={handleOutputChange}
                    />
                </Grid>
                <Grid item xs={12} md={4} className="word-count-mt">
                    <div className="text-count">
                        <span className="color-text"><b>Characters</b></span>&nbsp;{characterCount}<br />
                        <span className="color-text"><b>Words</b></span>&nbsp;{wordCount}<br />
                        <span className="color-text"><b>Lines</b></span>&nbsp;{lineCount}<br />
                        <span className="color-text"><b>Characters No Spaces</b></span>&nbsp;{characterCountNoSpace}<br />
                        <span className="color-text"><b>Reading Time</b></span>&nbsp;{calculateReadingTime(wordCount).toFixed(2)} min <br />
                        <span className="color-text"><b>Speaking Time</b></span>&nbsp;{calculateSpeakingTime(wordCount).toFixed(2)} min<br /><br />
                        {/* Display top keywords and phrases */}
                        <div>
                            <h3>Keyword Density</h3>
                            <div>
                                {['TOP', '1', '2', '3'].map((density) => (
                                    <button
                                        key={density}
                                        className={`density-selector ${selectedDensity === density.toString() ? 'active' : ''}`}
                                        onClick={() => handleDensityChange(density)}
                                    >
                                        {density === 'TOP' ? density : density + ' Word'}
                                    </button>
                                ))}
                            </div>
                            {filteredKeywords.length === 0 ? (
                                <p>No keywords found.</p>
                            ) : (
                                <div>
                                    {currentKeywordDensity.map((keyword, index) => (
                                        <div key={index} className="keyword-row">
                                            <p>
                                                <b>{keyword.word}</b>&nbsp;({keyword.count})
                                                &nbsp;&nbsp;{keyword.density.toFixed(2)}%
                                            </p>
                                        </div>
                                    ))}
                                    {totalPages > 1 && (
                                        <div className="pagination">
                                            {Array.from({ length: totalPages }, (_, i) => (
                                                <button
                                                    key={i}
                                                    className={`page-btn ${i + 1 === currentPage ? 'active' : ''}`}
                                                    onClick={() => handlePageChange(i + 1)}
                                                >
                                                    {i + 1}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Word Counter - Accurate Tool for Word Counting</h2>
                        <p>Counting words accurately is a breeze with our Online Word Counter tool. Whether you're crafting an essay, working on a website, or editing any text, our word counter website is your go-to solution. It's user-friendly and ensures precise word counts, making your writing tasks simpler and more efficient.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default WordCounter;
