import React from 'react';
import ReminderItem from './ComponentReminderItem';
import { Button, TextField, Grid, Box } from '@mui/material';

const ReminderList = ({ reminders, deleteReminder, activateReminder }) => {
  return (
    <div>
      {reminders.length === 0 ? (
        <Grid container spacing={1} className="text-count">
          <Grid item>
            <Box style={{ marginTop: '-15px', minHeight: '173px' }}>
              <p>No reminders available.</p>
            </Box>
          </Grid>
        </Grid>
      ) : (
        reminders.map((reminder) => (
          <ReminderItem
            key={reminder.id}
            reminder={reminder}
            deleteReminder={deleteReminder}
            activateReminder={activateReminder}
          />
        ))
      )}
    </div>
  );
};

export default ReminderList;
