// Inside LayoutTextInnerDivVertical
const copyTextToClipboard = (text) => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    tempTextArea.style.position = 'fixed';
    tempTextArea.style.opacity = 0;
    tempTextArea.style.width = '1px';
    tempTextArea.style.height = '1px';
    document.body.appendChild(tempTextArea);
    tempTextArea.focus();
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
};
export default copyTextToClipboard;