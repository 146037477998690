// SEOComponent.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import SeoMeta from './SeoMeta'; // Import the page data
//for SEO  Dynamic Schema,s
import SchemaComponent from '../common/SchemaComponent';

const SEOComponent = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Find the page data that matches the current URL slug
    const pageSeoData = SeoMeta.find(data => data.slug === currentPath.replace('/', ''));

    // Set default values if the page data is not found
    const defaultPageSeoData = {
        title: "ꅏꂦꌅ꒒ꂠ ꂦꄞ ꄞꂦꋊꋖꌚ | Font Generator, Copy and Paste, Instagram Fonts, and More",
        description: "𝗗𝗶𝘀𝗰𝗼𝘃𝗲𝗿 𝗮 𝘄𝗶𝗱𝗲 array of fonts for every occasion! Our font generator offers copy and paste fonts, Instagram fonts, calligraphy fonts, and more. Get creative with different lettering styles, from cursive fonts to bold and modern fonts. Elevate your content with aesthetic fonts, perfect for Instagram and beyond. Explore our collection now!",
        keywords: "cool fonts, copy and paste fonts, fonts copy and paste, instagram fonts, calligraphy fonts, fonts copy paste, cursive fonts, ig fonts, fonts generator, discord fonts, different fonts, lettering fonts, cute fonts, fonts style, copy paste fonts, aesthetic fonts, fonts for instagram, fun fonts, script fonts, insta fonts, pretty fonts, christmas fonts, fonts alphabet, sans serif fonts, facebook fonts, bold fonts, free script fonts, writing fonts, embroidery fonts, aesthetic fonts copy and paste, block fonts, best fonts, cute fonts copy and paste, modern fonts"
  
    };

    // Use pageSeoData if available, otherwise use defaultPageSeoData
    const { title, description, keywords } = pageSeoData || defaultPageSeoData;

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                {/* Set the canonical URL dynamically */}
                {/* <link rel="canonical" href={`https://www.yourwebsite.com${currentPath}`} /> */}

                {/* Other head elements */}

                {/* Set dynamic OG tags for Shear */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://fontgenerator.co/static/images/Adds/logo.png" />
            </Helmet>

            {/* Include Schema For seo */}
            <SchemaComponent />
        </>
    );
};

export default SEOComponent;
