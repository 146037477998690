import * as React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import AdbIcon from '@mui/icons-material/Adb';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

// Page Menus And links for Desktop and Mobile
const pages = [
    { pagename: 'Notepad', pagelink: '/online-notepad' },
    { pagename: 'Word Counter', pagelink: '/word-counter' },
    { pagename: 'Case Converter', pagelink: '/case-converter' },
    { pagename: 'Cool Fonts', pagelink: '/cool-fonts' },
    { pagename: 'Facebook Fonts', pagelink: '/facebook-fonts' },
];


// All Tools Menu, Sub menusand links for Desktop and Mobile

const allToolsSubmenus = [

    { name: 'Cursive Generator', link: '/cursive-generator' },
    { name: 'Unicode Text Converter', link: '/unicode-text-converter' },
    { name: 'Fonts Style', link: '/fonts-style' },
    { name: 'Cool Number Fonts', link: '/cool-number-fonts' },
    { name: 'Roman Number', link: '/roman-number' },
    { name: 'Url Slug', link: '/url-slug' },
    { name: 'Replace Text', link: '/replace-text' },
    { name: 'Bubble Font', link: '/bubble-font' },
    { name: 'Strikethrough Text', link: '/strikethrough-text' },
    { name: 'Plain Text', link: '/plain-text' },
    { name: 'Bold Text Generator', link: '/bold-text-generator' },
    { name: 'Italic Font', link: '/italic-font' },
    { name: 'Sentence Case', link: '/sentence-case' },
    { name: 'Underline Text', link: '/underline-text' },
    { name: 'Title Case Converter', link: '/title-case-converter' },
    { name: 'Upside Down Text', link: '/upside-down-text' },
    { name: 'Small Text Generator', link: '/small-text-generator' },
    { name: 'Vaporwave Text Generator', link: '/vaporwave-text-generator' },
    { name: 'Wingdings Translator', link: '/wingdings-translator' },
    { name: 'Text Repeater', link: '/text-repeater' },
    { name: 'Mirror Text Generator', link: '/mirror-text-generator' },
    { name: 'Backwards Text Generator', link: '/backwards-text-generator' },
    { name: 'Invisible Character', link: '/invisible-character' },
    { name: 'Binary Code Translator', link: '/binary-code-translator' },
    { name: 'Hex To Text', link: '/hex-to-text' },
    { name: 'JSON Stringify Online', link: '/json-stringify-online' },
    { name: 'Morse Code Translator', link: '/morse-code-translator' },
    { name: 'NATO Phonetic Alphabet', link: '/nato-phonetic-alphabet' },
    { name: 'UTF-8 Encodingr', link: '/utf-8-encoding' },
    { name: 'Online Alarm Clock', link: '/online-alarm-clock' }
];

function Header() {
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
    const [allToolsOpen, setAllToolsOpen] = React.useState(false);
    const [mobileAllToolsOpen, setMobileAllToolsOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const mobileAnchorRef = React.useRef(null);
    const desktopAnchorRef = React.useRef(null);

    const handleDrawerToggle = () => {
        setMobileDrawerOpen((prevState) => !prevState);
    };

    const handleAllToolsToggle = (isMobile) => {
        if (isMobile) {
            setMobileAllToolsOpen((prevState) => !prevState);
        } else {
            setAllToolsOpen((prevState) => !prevState);
        }
    };

    const handleCloseAllTools = (event, isMobile) => {
        if (
            (anchorRef.current && anchorRef.current.contains(event.target)) ||
            (mobileAnchorRef.current && mobileAnchorRef.current.contains(event.target)) ||
            (desktopAnchorRef.current && desktopAnchorRef.current.contains(event.target))
        ) {
            return;
        }
        if (isMobile) {
            setMobileAllToolsOpen(false);
        } else {
            setAllToolsOpen(false);
        }
    };

    // Create a custom class for your Link component
    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 960) {
                setAllToolsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const customLinkStyle = {
        textDecoration: 'none', // Remove underlines
        color: 'inherit',      // Inherit the text color
    };
    //For Logo, name change in Local
    const isProduction = process.env.NODE_ENV === 'production';


    //For Get Location of the url
    const location = useLocation();
    // Define the active class conditionally
    const getButtonClass = (pageLink) =>
        `button-header menu-style ${location.pathname === pageLink ? 'active' : ''}`;

    return (
        <Box className="header-fixed">
            <AppBar className="header-color" position="static">
                <Container maxWidth="xl">
                    <Toolbar>
                        {/* Left side of the header */}
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            {/* <AdbIcon sx={{ mr: 1 }} /> */}
                            <Typography
                                // className="logo-color"
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                {/* 𝖋𝖔𝖓𝖙𝖌𝖊𝖓𝖊𝖗𝖆𝖙𝖔𝖗.𝖈𝖔 */}
                                {isProduction ? (
                                    '𝖋𝖔𝖓𝖙𝖌𝖊𝖓𝖊𝖗𝖆𝖙𝖔𝖗.𝖈𝖔'
                                ) : (
                                    'Logo'
                                )}
                            </Typography>
                        </Box>

                        {/* Center and right side of the header */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* Pages */}
                            {pages.map((page) => (
                                <Link to={page.pagelink} style={customLinkStyle} key={page.pagename}>
                                    <Button
                                        key={page.pagename}
                                        sx={{
                                            my: 2,
                                            display: { xs: 'none', md: 'block' },
                                            marginLeft: '1rem', // Adjust spacing to match the footer
                                        }}
                                        className={getButtonClass(page.pagelink)}
                                    >
                                        {page.pagename}
                                    </Button>
                                </Link>
                            ))}
                            {/* All Tools Dropdown Desktop */}
                            {allToolsSubmenus.length > 0 && (
                                <>
                                    <Button
                                        ref={anchorRef}
                                        onClick={() => handleAllToolsToggle(false)}
                                        endIcon={<ArrowDropDownIcon />}
                                        sx={{
                                            display: { xs: 'none', md: 'flex' },
                                            alignItems: 'center',
                                            marginLeft: '1rem', // Adjust spacing to match the footer
                                        }}
                                        className="button-header menu-style"
                                    >
                                        All Tools
                                    </Button>

                                    {/* All Tools Popper - Desktop */}
                                    <Popper className="sub-menu" open={allToolsOpen} anchorEl={anchorRef.current} placement="bottom-start">
                                        <ClickAwayListener onClickAway={(event) => handleCloseAllTools(event, false)}>
                                            <Paper className="sub-r max-h custom-scrollbar">
                                                <List sx={{ minWidth: '200px' }}>
                                                    {allToolsSubmenus.map((submenu) => (
                                                        // <ListItem button key={submenu.name} onClick={(event) => handleCloseAllTools(event, false)}>
                                                        //     <Link to={submenu.link} style={customLinkStyle}>
                                                        //         <ListItemText primary={submenu.name} />
                                                        //     </Link>
                                                        // </ListItem>
                                                        <ListItem
                                                            button
                                                            key={submenu.name}
                                                            component={Link} // Use the React Router Link component
                                                            to={submenu.link} // Use 'href' instead of 'to' for external links
                                                            onClick={(event) => handleCloseAllTools(event, false)}
                                                            className={getButtonClass(submenu.link)}
                                                        >
                                                            <ListItemText primary={submenu.name} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Paper>
                                        </ClickAwayListener>
                                    </Popper>
                                </>
                            )}

                            {/* Mobile drawer toggle */}
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                    {/* Mobile Drawer */}
                    <Drawer
                        variant="temporary"
                        anchor="right"
                        open={mobileDrawerOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                        }}
                    >
                        {/* Close Icon */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
                            <IconButton onClick={handleDrawerToggle}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <List>
                            {/* Pages */}
                            {pages.map((page) => (

                                // <ListItem button key={page.pagename} onClick={handleDrawerToggle}>
                                //     <Link to={page.pagelink} style={customLinkStyle}>
                                //         <ListItemText primary={page.pagename} />
                                //     </Link>
                                // </ListItem>
                                <ListItem
                                    button
                                    key={page.pagename}
                                    onClick={handleDrawerToggle}
                                    component={Link} // Use the Link component for the whole ListItem
                                    to={page.pagelink} // Specify the route to navigate to
                                    className={getButtonClass(page.pagelink)}
                                >
                                    <ListItemText primary={page.pagename} />
                                </ListItem>
                            ))}
                        </List>

                        {/* All Tools Dropdown - Mobile */}
                        {allToolsSubmenus.length > 0 && (
                            <List>
                                <ListItem
                                    button
                                    key="All Tools"
                                    onClick={() => handleAllToolsToggle(true)}
                                    sx={{ ...(mobileAllToolsOpen && { background: 'rgba(0, 0, 0, 0.1)' }) }}
                                >
                                    <ListItemText primary="All Tools" />
                                    <ExpandMoreIcon
                                        sx={{
                                            ...(mobileAllToolsOpen && { transform: 'rotate(180deg)' }),
                                            transition: 'transform 0.3s ease',
                                        }}
                                    />
                                </ListItem>
                                {/* All Tools Submenus Mobile */}
                                {mobileAllToolsOpen && (
                                    <List sx={{ paddingLeft: '20px' }}>
                                        {allToolsSubmenus.map((submenu) => (
                                            // <ListItem button key={submenu.name} onClick={handleDrawerToggle}>
                                            //     <Link to={submenu.link} style={customLinkStyle}>
                                            //         <ListItemText primary={submenu.name} />
                                            //     </Link>
                                            // </ListItem>
                                            <ListItem
                                                button
                                                key={submenu.name}
                                                component={Link} // Use the Link component for the whole ListItem
                                                to={submenu.link} // Specify the route to navigate to
                                                onClick={handleDrawerToggle} // Optionally, add any additional click handling
                                                className={getButtonClass(submenu.link)}
                                            >
                                                <ListItemText primary={submenu.name} />
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </List>
                        )}
                    </Drawer>
                </Container>
            </AppBar>
        </Box>
    );
}

export default Header;
