import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import {
    StyledTextareaAutosize, Icon, StyledButton,
} from '../../common/TagsStyle';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom, adStyle } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import copyTextToClipboard from '../../../hooks/copyTextToClipboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    LatinSansSerifText, LatinSansSerifTextToBold, LatinSansSerifItalic, LatinSansSerifItalicBold,
    LatinSerifTextToBold, LatinSerifTextToItalic, LatinSerifTextToItalicBold,
    LatinCalligraphy, LatinCalligraphyBold, LatinFraktur, LatinFrakturBold, LatinMonoSpace,
    LatinDoubleStruck
} from '../../features/StringManipulation/TextToLatin';
import { WhiteCircles, BlackCircles } from '../../features/StringManipulation/TextToCircled';
import { SquaredLatin, SquaredLatinFilled } from '../../features/StringManipulation/TextToSquare';
import { GreekPlain } from '../../features/StringManipulation/TextToGreek';
import {
    fullWidthCharacters, SmallCapsText, SuperscriptGenerator, PlaintoEmoji,
    plainToIPA, MirrorVisibleText, BackwardsText,
    VaporwaveText, strikethroughText, convertToDoubleUnderlined,
} from '../../features/StringManipulation/TextManipuation';
import {
    FontSytle1, FontStyle2, FontStyle3, FontStyle4, FontStyle5,
    FontStyle6, FontStyle7, FontStyle8, FontStyle9,
    SmallTextGenerator, TinyText, EnlargedText, UpsideDownText, GothicText,
    HeartText, OldEnglishText, ImpactText, CourierNewText, TimesNewRomanText,
    LucidaConsoleText, LucidaSansText, BroadwayText, EdwardianScriptText, HarlowSolidText,
    //Decorated
    Backtick, GlitchText, Hyphen,
    StencilText, BookAntiquaText, Underscore,
    Asterisk, Hash, UnderlineText, SquareBrackets,
    CurlyBrackets, FullWidthPeriod, RegularPeriod, Parentheses, SquareBracketsNormal, CurlyBracketsNormal,
    Slash, Backslash, Pipe, Exclamation, Tilde,
    //Repeated
    BoldText, ItalicText, BoldItalicText, MonospaceText, BoldMonospaceText, ItalicMonospaceText,
    BoldItalicMonospaceText, CursiveText, BoldCursiveText, DoubleStruckText, SansSerifText,
    SansSerifBoldText, SansSerifItalicText, SansSerifBoldItalicText, FrakturText, BoldFrakturText,
    ScriptText, BoldScriptText, CircledText, CircledTextBlack, SquaredText, NegativeSquaredText,
    RegionalIndicatorText, ZalgoText
} from '../../features/StringManipulation/TextToStyle';

import {
    DecorativeStyle1, AddSymbolAroundLetters, AddDoubleSquareBracketsAroundLetters, AddSquareBracketsAroundLetters,
    AddHeartSymbolAfterLetters, AddFancyDecoration, AddDecoration, AddDecoration1, AddDecoration2,
    AddDecoration3, AddDecoration4, AddDecoration6, AddDecoration7, AddDecoration8,
    AddDecoration9, AddDecoration10, AddDecoration11, AddDecoration12, AddDecoration14,
    AddDecoration15, AddDecoration16, AddDecoration17, AddDecoration18, AddDecoration19,
    AddDecoration20, AddDecoration21, AddDecoration22, AddDecoration23, AddDecoration24, AddDecoration25,
    AddDecoration26, AddDecoration27, AddDecoration28,
    AddDecorationg2, AddDecorationg3, AddDecorationg4, AddDecorationg5,
    AddDecoration35, AddDecoration36, AddDecoration37, AddDecoration38, AddDecoration39,
    AddDecoration40, AddDecoration41, mixCharacters1, mixCharacters2, mixCharacters3, mixCharacters4,
    mixCharacters5, mixCharacters6, mixCharacters7
} from '../../features/StringManipulation/TextToDecorative';

// for seo
import SEOComponent from '../../common/SEOComponent';

const FontsStyle = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const inputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
    const [individualCopiedIndex, setIndividualCopiedIndex] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(10);
    const [noMoreItemsMessage, setNoMoreItemsMessage] = useState(null);
    const [displayAd, setDisplayAd] = useState(false);


    // All functions and Define the initial texts 

    const initialTexts = [
        "𝒯ℯ𝓍𝓉 𝓉ℴ 𝒞𝒶𝓁𝓁𝒾ℊ𝓇𝒶𝓅𝒽𝓎 ..", "𝓣𝓮𝔁𝓽 𝓽𝓸 𝓒𝓪𝓵𝓵𝓲𝓰𝓻𝓪𝓹𝓱𝔂 (𝓑𝓸𝓵𝓭) ..", "𝔗𝔢𝔵𝔱 𝔱𝔬 𝔉𝔯𝔞𝔨𝔱𝔲𝔯 ..", "𝕿𝖊𝖝𝖙 𝖙𝖔 𝕱𝖗𝖆𝖐𝖙𝖚𝖗 (𝕭𝖔𝖑𝖉) ..",
        "𝕋𝕖𝕩𝕥 𝕥𝕠 𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜 ..", "𝚃𝚎𝚡𝚝 𝚝𝚘 𝙼𝚘𝚗𝚘𝚂𝚙𝚊𝚌𝚎 ..", "Ⓣⓔⓧⓣ ⓣⓞ Ⓦⓗⓘⓣⓔ Ⓒⓘⓡⓒⓛⓔⓢ ..", "🅣🅔🅧🅣 🅣🅞 🅑🅛🅐🅒🅚 🅒🅘🅡🅒🅛🅔🅢 ...",
        "🅃🄴🅇🅃 🅃🄾 🅂🅀🅄🄰🅁🄴🄳 ..", "🆃🅴🆇🆃 🆃🅾 🆂🆀🆄🅰🆁🅴🅳 🅵🅸🅻🅻🅴🅳 ..", "𝖳𝖾𝗑𝗍 𝗍𝗈 𝖲𝖺𝗇𝗌 𝖲𝖾𝗋𝗂𝖿 ..",
        "𝗧𝗲𝘅𝘁 𝘁𝗼 𝗦𝗮𝗻𝘀 𝗦𝗲𝗿𝗶𝗳 (𝗕𝗼𝗹𝗱) ..", "𝘛𝘦𝘹𝘵 𝘵𝘰 𝘚𝘢𝘯𝘴 𝘚𝘦𝘳𝘪𝘧 (𝘐𝘵𝘢𝘭𝘪𝘤) ..", "𝙏𝙚𝙭𝙩 𝙩𝙤 𝙎𝙖𝙣𝙨 𝙎𝙚𝙧𝙞𝙛 𝙄𝙩𝙖𝙡𝙞𝙘 (𝘽𝙤𝙡𝙙) ..", "Ｔｅｘｔ ｔｏ Ｆｕｌｌ Ｗｉｄｔｈ ..", "ᴛᴇxᴛ ᴛᴏ ᴄᴀᴘɪᴛᴀʟɪᴢᴇᴅ ..", "ᵀᵉˣᵗ ᵗᵒ ˢᵘᵖᵉʳ ˢᶜʳⁱᵖᵗ ..",
        "𝐓𝐞𝐱𝐭 𝐭𝐨 𝐒𝐞𝐫𝐢𝐟 (𝐁𝐨𝐥𝐝) ..",
        "𝑇𝑒𝑥𝑡 𝑡𝑜 𝑆𝑒𝑟𝑖𝑓 𝐼𝑡𝑎𝑙𝑖𝑐 ..", "𝑻𝒆𝒙𝒕 𝒕𝒐 𝑺𝒆𝒓𝒊𝒇 𝑰𝒕𝒂𝒍𝒊𝒄 (𝑩𝒐𝒍𝒅) ..", "🇹🇪❌🇹 🇹🅾️ 🇪🇲🅾️🇯ℹ️💲 ..", "Τεξτ το Γρεεκ ..",

        "𝕿𝖊𝖝𝖙 𝖙𝖔 𝕮𝖚𝖗𝖘𝖊𝖉 𝕿𝖊𝖝𝖙..", "TE᙭T TO ᑕᑌᖇᔕEᗪ TE᙭T..", "ꋖꈼꇒꋖ ꋖꂦ ꀯꐇꌅꌚꈼꂠ ꋖꈼꇒꋖ..", "꓄ꏂꉧ꓄ ꓄ꄲ ꉔ꒤ꋪꇙꏂ꒯ ꓄ꏂꉧ꓄..",
        "𝓉ℯ𝓍𝓉 𝓉ℴ 𝒸𝓊𝓇𝓈ℯ𝒹 𝓉ℯ𝓍𝓉..", "ƚҽxƚ ƚσ ƈυɾʂҽԃ ƚҽxƚ..", "卞ヨメ卞 卞回 亡凵尺己ヨ句 卞ヨメ卞..",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);
    const TextConversion = (inputText) => {
        const AllFunctionValues = [

            LatinCalligraphy(inputText),
            LatinCalligraphyBold(inputText),
            LatinFraktur(inputText),
            LatinFrakturBold(inputText),
            LatinDoubleStruck(inputText),
            LatinMonoSpace(inputText),
            WhiteCircles(inputText),
            BlackCircles(inputText),
            SquaredLatin(inputText),
            SquaredLatinFilled(inputText),
            LatinSansSerifText(inputText),
            LatinSansSerifTextToBold(inputText),
            LatinSansSerifItalic(inputText),
            LatinSansSerifItalicBold(inputText),
            fullWidthCharacters(inputText),
            SmallCapsText(inputText),
            SuperscriptGenerator(inputText),
            LatinSerifTextToBold(inputText),
            LatinSerifTextToItalic(inputText),
            LatinSerifTextToItalicBold(inputText),
            PlaintoEmoji(inputText),
            GreekPlain(inputText),

            //Cursive Text
            TimesNewRomanText(inputText),
            FontStyle8(inputText),
            FontStyle7(inputText),
            FontStyle6(inputText),
            FontStyle9(inputText),
            FontStyle5(inputText),
            FontStyle3(inputText),
            GlitchText(inputText),
            strikethroughText(inputText),
            plainToIPA(inputText),
            BackwardsText(inputText),
            MirrorVisibleText(inputText),
            UpsideDownText(inputText),
            VaporwaveText(inputText),

            //Facebook

            FontSytle1(inputText),
            FontStyle2(inputText),
            mixCharacters6(inputText),
            mixCharacters7(inputText),
            EnlargedText(inputText),
            Backtick(inputText),
            LatinMonoSpace(inputText),
            mixCharacters1(inputText),
            mixCharacters2(inputText),
            mixCharacters3(inputText),
            mixCharacters4(inputText),
            mixCharacters5(inputText),

            //Text Decoration
            DecorativeStyle1(inputText),
            AddSymbolAroundLetters(inputText),
            AddDoubleSquareBracketsAroundLetters(inputText),
            AddSquareBracketsAroundLetters(inputText),
            AddHeartSymbolAfterLetters(inputText),
            AddFancyDecoration(inputText),
            AddDecoration(inputText),
            AddDecoration1(inputText),
            AddDecoration2(inputText),
            AddDecoration3(inputText),
            AddDecoration4(inputText),
            AddDecoration6(inputText),
            AddDecoration7(inputText),
            AddDecoration8(inputText),
            AddDecoration9(inputText),
            AddDecoration10(inputText),
            AddDecoration11(inputText),
            AddDecoration12(inputText),
            AddDecoration14(inputText),
            AddDecoration15(inputText),
            AddDecoration16(inputText),
            AddDecoration17(inputText),
            AddDecoration18(inputText),
            AddDecoration19(inputText),
            convertToDoubleUnderlined(inputText),

            //Stayle More
            FontStyle4(inputText),
            SmallTextGenerator(inputText),
            TinyText(inputText),
            GothicText(inputText),
            HeartText(inputText),
            OldEnglishText(inputText),
            ImpactText(inputText),
            CourierNewText(inputText),
            LucidaConsoleText(inputText),
            LucidaSansText(inputText),
            BroadwayText(inputText),
            EdwardianScriptText(inputText),
            HarlowSolidText(inputText),

            //Text Decoration
            AddDecoration20(inputText),
            AddDecoration21(inputText),
            AddDecoration22(inputText),
            AddDecoration23(inputText),
            AddDecoration24(inputText),
            AddDecoration25(inputText),
            AddDecoration26(inputText),
            AddDecoration27(inputText),
            AddDecoration28(inputText),
            AddDecorationg2(inputText),
            AddDecorationg3(inputText),
            AddDecorationg4(inputText),
            AddDecorationg5(inputText),
            AddDecoration35(inputText),
            AddDecoration36(inputText),
            AddDecoration37(inputText),
            AddDecoration38(inputText),
            AddDecoration39(inputText),
            AddDecoration40(inputText),
            AddDecoration41(inputText),
            mixCharacters1(inputText),
            mixCharacters2(inputText),
            mixCharacters3(inputText),
            mixCharacters4(inputText),
            mixCharacters5(inputText),
            mixCharacters6(inputText),
            mixCharacters7(inputText),

            //Style 2
            Hyphen(inputText),
            StencilText(inputText),
            BookAntiquaText(inputText),
            Underscore(inputText),
            Asterisk(inputText),
            Hash(inputText),
            UnderlineText(inputText),
            SquareBrackets(inputText),
            CurlyBrackets(inputText),
            FullWidthPeriod(inputText),
            RegularPeriod(inputText),
            Parentheses(inputText),
            SquareBracketsNormal(inputText),
            CurlyBracketsNormal(inputText),
            Slash(inputText),
            Backslash(inputText),
            Pipe(inputText),
            Exclamation(inputText),
            Tilde(inputText),

            //Repeted again
            BoldText(inputText),
            ItalicText(inputText),
            BoldItalicText(inputText),
            MonospaceText(inputText),
            BoldMonospaceText(inputText),
            ItalicMonospaceText(inputText),
            BoldItalicMonospaceText(inputText),
            CursiveText(inputText),
            BoldCursiveText(inputText),
            DoubleStruckText(inputText),
            SansSerifText(inputText),
            SansSerifBoldText(inputText),
            SansSerifItalicText(inputText),
            SansSerifBoldItalicText(inputText),
            FrakturText(inputText),
            BoldFrakturText(inputText),
            ScriptText(inputText),
            BoldScriptText(inputText),
            CircledText(inputText),
            CircledTextBlack(inputText),
            SquaredText(inputText),
            NegativeSquaredText(inputText),
            RegionalIndicatorText(inputText),
            ZalgoText(inputText)

        ];
        // Return the output
        return AllFunctionValues;
    };

    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 1000) {
            setError('Maximum character limit (1000) reached.');
        } else {
            setError(null);
        }
        // Function Call
        const AllFunctionValues = TextConversion(text);
        // Check if the inputText is empty or null and set outputText accordingly
        setOutputText(text.trim() === null || text.trim() === '' ? [] : AllFunctionValues);
        // Function Call
        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
        setNoMoreItemsMessage(null);
    };
    const isOutputTextEmpty = outputText.length === 0 || outputText.every((text) => text === '');
    // New function to handle individual copy for function
    const handleIndividualCopyClick = (index) => {
        const textToCopy = outputText[index];
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = textToCopy;
        tempTextArea.style.position = 'fixed'; // Ensures it doesn't affect page layout
        tempTextArea.style.opacity = 0; // Make it invisible
        tempTextArea.style.width = '1px'; // Set width to ensure it's focusable
        tempTextArea.style.height = '1px'; // Set height to ensure it's focusable
        document.body.appendChild(tempTextArea);
        tempTextArea.focus(); // Focus to select content
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
        setIndividualCopiedIndex(index);
        resetIsCopied();
    };
    const handleInitialTextCopyClick = (index) => {
        const textToCopy = initialOutputText[index];
        copyTextToClipboard(textToCopy);
        setIndividualCopiedIndex(index);
        resetIsCopied();
    };
    const resetIsCopied = () => {
        setIsCopied(false);
    };
    const loadMoreItems = () => {
        if (itemsToShow >= outputText.length && itemsToShow >= initialOutputText.length) {
            // Display a message when there are no more items to show
            setNoMoreItemsMessage("No more Fonts Style.. !");
        } else {
            setNoMoreItemsMessage(null); // Clear any previous message
            setItemsToShow((prevItems) => {
                const newItemsToShow = prevItems + 10; // Increase by 5 items
                if (newItemsToShow >= outputText.length && newItemsToShow >= initialOutputText.length) {
                    setNoMoreItemsMessage("No more Fonts Style.. !");
                }
                return newItemsToShow;
            });
            setDisplayAd(true); // Set to true to display the ad after loading the next set of 5 fonts
        }
    };
    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Elevate Your Text Style with Our Cool Fonts and Text Generator</h1>
                        <p> Explore a world of creative possibilities with our cool fonts generator. Customize your Instagram bio, Facebook posts, or website content with stylish and aesthetic fonts that stand out.</p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            <span><b>Start Typing for Get 300+ Fonts Style</b></span>
            <Grid style={{ marginTop: '20px' }} spacing={2}>
                <Grid item>
                    <Box>
                        <StyledTextareaAutosize
                            className="text-input input-textarea-style vscroll"
                            style={{ textAlign: 'center' }}
                            id="copied"
                            ref={inputTextareaRef}
                            onChange={handleInputChange}
                            maxLength={1000}
                            value={inputText}
                            placeholder="Start typing, or copy and paste your document here..."
                        />
                        {error && <p className="error-message">{error}</p>}
                        <div className="stylefont" style={{ textAlign: 'center' }}>
                            <br />
                            {/* {outputText.map((allFunctionText, index) => ( */}
                            {outputText.slice(0, itemsToShow).map((allFunctionText, index) => (
                                <div key={index}>
                                    <Box className='copy-content-style border'>
                                        {allFunctionText}
                                        <Icon aria-label="copy-contents" size="small" onClick={() => handleIndividualCopyClick(index)}>
                                            {individualCopiedIndex === index ? (
                                                <span className="Copied-st">Copied</span>
                                            ) : (
                                                <span className="Copy">Copy</span>
                                            )}
                                        </Icon>
                                    </Box>
                                    {(index + 1) % 10 === 0 && displayAd && <AdsComponent adConfigurations={adStyle} />}
                                    <br />
                                </div>
                            ))}
                            {isOutputTextEmpty &&
                                // initialOutputText.map((text, index) => (
                                initialOutputText.slice(0, itemsToShow).map((text, index) => (
                                    <div key={index}>
                                        <Box className='copy-content-style border'>
                                            {text}
                                            <Icon aria-label="copy-contents" size="small"
                                                onClick={() => handleInitialTextCopyClick(index)}
                                            >
                                                {individualCopiedIndex === index ? (
                                                    <span className="Copied-st">Copied</span>
                                                ) : (
                                                    <span className="Copy">Copy</span>
                                                )}

                                            </Icon>
                                        </Box>
                                        {(index + 1) % 10 === 0 && displayAd && <AdsComponent adConfigurations={adStyle} />}
                                        <br />
                                    </div>
                                ))}
                            <div style={{ textAlign: 'center' }}>
                                <StyledButton
                                    onClick={loadMoreItems}
                                    className='button-primary'
                                    variant='contained'
                                    size='large'
                                    endIcon={<ExpandMoreIcon />}
                                >
                                    Load More 300+
                                </StyledButton>
                                <p style={{ color: 'royalblue' }}>{noMoreItemsMessage}</p>
                            </div>
                        </div>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Cool Fonts - Enhance Your Text Style Creatively</h2>
                        <p>Ready to elevate your text style and make a lasting impression? Our Cool Fonts and Text Generator is your ticket to a world of creative possibilities. Customize your Instagram bio, craft eye-catching Facebook posts, or enhance your website content with a wide array of stylish and aesthetic fonts that are sure to make your text stand out from the crowd.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default FontsStyle;
