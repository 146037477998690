
export const FontSytle1 = (text) => {
  const decorativeCharacters = {
    'A': '卂', 'B': '乃', 'C': '匚', 'D': 'ᗪ', 'E': '乇', 'F': '千', 'G': 'Ꮆ',
    'H': '卄', 'I': '丨', 'J': 'ﾌ', 'K': 'Ҝ', 'L': 'ㄥ', 'M': '爪', 'N': '几',
    'O': 'ㄖ', 'P': '卩', 'Q': 'Ɋ', 'R': '尺', 'S': '丂', 'T': 'ㄒ', 'U': 'ㄩ',
    'V': 'ᐯ', 'W': '山', 'X': '乂', 'Y': 'ㄚ', 'Z': '乙',
    'a': '卂', 'b': '乃', 'c': '匚', 'd': 'ᗪ', 'e': '乇', 'f': '千', 'g': 'Ꮆ',
    'h': '卄', 'i': '丨', 'j': 'ﾌ', 'k': 'Ҝ', 'l': 'ㄥ', 'm': '爪', 'n': '几',
    'o': 'ㄖ', 'p': '卩', 'q': 'Ɋ', 'r': '尺', 's': '丂', 't': 'ㄒ', 'u': 'ㄩ',
    'v': 'ᐯ', 'w': '山', 'x': '乂', 'y': 'ㄚ', 'z': '乙',
    '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6',
    '7': '7', '8': '8', '9': '9',
  };

  return text
    .split('')
    .map((char) => decorativeCharacters[char] || char)
    .join('');
};
// ㄒ乇乂ㄒ ㄒㄖ 千卂匚乇乃ㄖㄖҜ 千ㄖ几ㄒ
//卄乇ㄥㄥㄖ, 山ㄖ尺ㄥᗪ! 123

export const FontStyle2 = (text) => {
  const decorativeMapper = {
    'A': '₳', 'B': '฿', 'C': '₵', 'D': 'Đ', 'E': 'Ɇ', 'F': '₣', 'G': '₲',
    'H': 'Ⱨ', 'I': 'ł', 'J': 'J', 'K': '₭', 'L': 'Ⱡ', 'M': '₥', 'N': '₦',
    'O': 'Ø', 'P': '₱', 'Q': 'Q', 'R': 'Ɽ', 'S': '₴', 'T': '₮', 'U': 'Ʉ',
    'V': 'Ɏ', 'W': '₩', 'X': 'Ӿ', 'Y': 'Ɏ', 'Z': 'Ⱬ',
    'a': '₳', 'b': '฿', 'c': '₵', 'd': 'Đ', 'e': 'Ɇ', 'f': '₣', 'g': '₲',
    'h': 'Ⱨ', 'i': 'ł', 'j': 'J', 'k': '₭', 'l': 'Ⱡ', 'm': '₥', 'n': '₦',
    'o': 'Ø', 'p': '₱', 'q': 'Q', 'r': 'Ɽ', 's': '₴', 't': '₮', 'u': 'Ʉ',
    'v': 'Ɏ', 'w': '₩', 'x': 'Ӿ', 'y': 'Ɏ', 'z': 'Ⱬ'
  };

  return text
    .split('')
    .map((char) => decorativeMapper[char] || char)
    .join('');
};
//₮ɆӾ₮ ₮Ø ₣₳₵Ɇ฿ØØ₭ ₣Ø₦₮

//ⱧɆⱠⱠØ, ₩ØɌⱠĐ!"

export const FontStyle3 = (text) => {
  const decorativeMapper = {
    'A': '丹', 'B': '日', 'C': '亡', 'D': '句', 'E': 'ヨ', 'F': '乍', 'G': '呂',
    'H': '廾', 'I': '工', 'J': '勹', 'K': '片', 'L': 'し', 'M': '冊', 'N': '几',
    'O': '回', 'P': '尸', 'Q': '甲', 'R': '尺', 'S': '己', 'T': '卞', 'U': '凵',
    'V': 'と', 'W': '山', 'X': 'メ', 'Y': 'と', 'Z': '乙',
    'a': '丹', 'b': '日', 'c': '亡', 'd': '句', 'e': 'ヨ', 'f': '乍', 'g': '呂',
    'h': '廾', 'i': '工', 'j': '勹', 'k': '片', 'l': 'し', 'm': '冊', 'n': '几',
    'o': '回', 'p': '尸', 'q': '甲', 'r': '尺', 's': '己', 't': '卞', 'u': '凵',
    'v': 'と', 'w': '山', 'x': 'メ', 'y': 'と', 'z': '乙',
    '0': '〇', '1': '一', '2': '二', '3': '三', '4': '四', '5': '五',
    '6': '六', '7': '七', '8': '八', '9': '九'
  };

  return text.split('').map(char => decorativeMapper[char] || char).join('');
};

//Cursor Text:
// "廾ヨしし回, 山回尺し句!"
export const FontStyle4 = (text) => {
  const decorativeChars = {
    'A': 'ǟ', 'B': 'ɮ', 'C': 'ƈ', 'D': 'ɖ', 'E': 'ɛ', 'F': 'ʄ', 'G': 'ɢ',
    'H': 'ɦ', 'I': 'ɨ', 'J': 'ʝ', 'K': 'ӄ', 'L': 'ʟ', 'M': 'ʍ', 'N': 'ռ',
    'O': 'օ', 'P': 'ք', 'Q': 'զ', 'R': 'ʀ', 'S': 'ֆ', 'T': 'ȶ', 'U': 'ʊ',
    'V': 'ʏ', 'W': 'ա', 'X': 'Ӽ', 'Y': 'ʏ', 'Z': 'ʐ',
    'a': 'ǟ', 'b': 'ɮ', 'c': 'ƈ', 'd': 'ɖ', 'e': 'ɛ', 'f': 'ʄ', 'g': 'ɢ',
    'h': 'ɦ', 'i': 'ɨ', 'j': 'ʝ', 'k': 'ӄ', 'l': 'ʟ', 'm': 'ʍ', 'n': 'ռ',
    'o': 'օ', 'p': 'ք', 'q': 'զ', 'r': 'ʀ', 's': 'ֆ', 't': 'ȶ', 'u': 'ʊ',
    'v': 'ʏ', 'w': 'ա', 'x': 'Ӽ', 'y': 'ʏ', 'z': 'ʐ'
  };

  return text
    .split('')
    .map((char) => decorativeChars[char] || char)
    .join('');
};
// ɦɛʟʟօ, աօʀʟɖ!
export const FontStyle5 = (text) => {
  const decorativeChars = {
    'A': 'α', 'B': 'Ⴆ', 'C': 'ƈ', 'D': 'ԃ', 'E': 'ҽ', 'F': 'ϝ', 'G': 'ɠ',
    'H': 'ԋ', 'I': 'ι', 'J': 'ʝ', 'K': 'ƙ', 'L': 'ʅ', 'M': 'ɱ', 'N': 'ɳ',
    'O': 'σ', 'P': 'ρ', 'Q': 'ϙ', 'R': 'ɾ', 'S': 'ʂ', 'T': 'ƚ', 'U': 'υ',
    'V': 'ყ', 'W': 'ɯ', 'X': 'x', 'Y': 'ყ', 'Z': 'ȥ',
    'a': 'α', 'b': 'Ⴆ', 'c': 'ƈ', 'd': 'ԃ', 'e': 'ҽ', 'f': 'ϝ', 'g': 'ɠ',
    'h': 'ԋ', 'i': 'ι', 'j': 'ʝ', 'k': 'ƙ', 'l': 'ʅ', 'm': 'ɱ', 'n': 'ɳ',
    'o': 'σ', 'p': 'ρ', 'q': 'ϙ', 'r': 'ɾ', 's': 'ʂ', 't': 'ƚ', 'u': 'υ',
    'v': 'ყ', 'w': 'ɯ', 'x': 'x', 'y': 'ყ', 'z': 'ȥ'
  };

  return text
    .split('')
    .map((char) => decorativeChars[char] || char)
    .join('');
};
//Cursor Text:
// ԋҽʅʅօ, ɯօɾʅԃ!
export const FontStyle6 = (text) => {
  const decorativeCharsMapper = {
    'a': 'ꋬ', 'b': 'ꃳ', 'c': 'ꉔ', 'd': '꒯', 'e': 'ꏂ', 'f': 'ꊰ', 'g': 'ꍌ',
    'h': 'ꁝ', 'i': '꒐', 'j': '꒻', 'k': 'ꀘ', 'l': '꒒', 'm': 'ꂵ', 'n': 'ꋊ',
    'o': 'ꄲ', 'p': 'ꉣ', 'q': 'ꆰ', 'r': 'ꋪ', 's': 'ꇙ', 't': '꓄', 'u': '꒤',
    'v': 'ꌦ', 'w': 'ꅐ', 'x': 'ꉧ', 'y': 'ꌦ', 'z': 'ꁴ',
    'A': 'ꋬ', 'B': 'ꃳ', 'C': 'ꉔ', 'D': '꒯', 'E': 'ꏂ', 'F': 'ꊰ', 'G': 'ꍌ',
    'H': 'ꁝ', 'I': '꒐', 'J': '꒻', 'K': 'ꀘ', 'L': '꒒', 'M': 'ꂵ', 'N': 'ꋊ',
    'O': 'ꄲ', 'P': 'ꉣ', 'Q': 'ꆰ', 'R': 'ꋪ', 'S': 'ꇙ', 'T': '꓄', 'U': '꒤',
    'V': 'ꌦ', 'W': 'ꅐ', 'X': 'ꉧ', 'Y': 'ꌦ', 'Z': 'ꁴ'
  };

  return text
    .split('')
    .map((char) => decorativeCharsMapper[char] || char)
    .join('');
};
//Cursor Text:
//ꁝꏂ꒒꒒ꄲ ꅐꄲꋪ꒒꒯!

export const FontStyle7 = (text) => {
  const decorativeCharacters = {
    'A': 'ꁲ', 'B': 'ꋰ', 'C': 'ꀯ', 'D': 'ꂠ', 'E': 'ꈼ', 'F': 'ꄞ', 'G': 'ꁅ',
    'H': 'ꍩ', 'I': 'ꂑ', 'J': '꒻', 'K': 'ꀗ', 'L': '꒒', 'M': 'ꂵ', 'N': 'ꋊ',
    'O': 'ꂦ', 'P': 'ꉣ', 'Q': 'ꁷ', 'R': 'ꌅ', 'S': 'ꌚ', 'T': 'ꋖ', 'U': 'ꐇ',
    'V': 'ꐞ', 'W': 'ꅏ', 'X': 'ꇒ', 'Y': 'ꐞ', 'Z': 'ꁴ',
    'a': 'ꁲ', 'b': 'ꋰ', 'c': 'ꀯ', 'd': 'ꂠ', 'e': 'ꈼ', 'f': 'ꄞ', 'g': 'ꁅ',
    'h': 'ꍩ', 'i': 'ꂑ', 'j': '꒻', 'k': 'ꀗ', 'l': '꒒', 'm': 'ꂵ', 'n': 'ꋊ',
    'o': 'ꂦ', 'p': 'ꉣ', 'q': 'ꁷ', 'r': 'ꌅ', 's': 'ꌚ', 't': 'ꋖ', 'u': 'ꐇ',
    'v': 'ꐞ', 'w': 'ꅏ', 'x': 'ꇒ', 'y': 'ꐞ', 'z': 'ꁴ'
  };

  return text
    .split('')
    .map((char) => decorativeCharacters[char] || char)
    .join('');
};
//Cursor Text:
// ꍩꈼ꒒꒒ꂦ ꅏꂦꌅ꒒ꂠ!
export const FontStyle8 = (text) => {
  const decorativeCharacters = {
    'A': 'ᗩ', 'B': 'ᗷ', 'C': 'ᑕ', 'D': 'ᗪ', 'E': 'E', 'F': 'ᖴ', 'G': 'G',
    'H': 'ᕼ', 'I': 'I', 'J': 'ᒍ', 'K': 'K', 'L': 'ᒪ', 'M': 'ᗰ', 'N': 'ᑎ',
    'O': 'O', 'P': 'ᑭ', 'Q': 'ᑫ', 'R': 'ᖇ', 'S': 'ᔕ', 'T': 'T', 'U': 'ᑌ',
    'V': 'V', 'W': 'ᗯ', 'X': '᙭', 'Y': 'Y', 'Z': 'Y',
    'a': 'ᗩ', 'b': 'ᗷ', 'c': 'ᑕ', 'd': 'ᗪ', 'e': 'E', 'f': 'ᖴ', 'g': 'G',
    'h': 'ᕼ', 'i': 'I', 'j': 'ᒍ', 'k': 'K', 'l': 'ᒪ', 'm': 'ᗰ', 'n': 'ᑎ',
    'o': 'O', 'p': 'ᑭ', 'q': 'ᑫ', 'r': 'ᖇ', 's': 'ᔕ', 't': 'T', 'u': 'ᑌ',
    'v': 'V', 'w': 'ᗯ', 'x': '᙭', 'y': 'Y', 'z': 'Y',
  };

  return text
    .split('')
    .map((char) => decorativeCharacters[char] || char)
    .join('');
};
//Cursor Text: 
// ᕼᗴᒪᒪO ᗯOᖇᒪᗪ!
export const FontStyle9 = (text) => {
  const decorativeCharacters = {
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': 'ℯ', 'f': '𝒻', 'g': 'ℊ',
    'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃',
    'o': 'ℴ', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊',
    'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
    'A': '𝒶', 'B': '𝒷', 'C': '𝒸', 'D': '𝒹', 'E': 'ℯ', 'F': '𝒻', 'G': 'ℊ',
    'H': '𝒽', 'I': '𝒾', 'J': '𝒿', 'K': '𝓀', 'L': '𝓁', 'M': '𝓂', 'N': '𝓃',
    'O': 'ℴ', 'P': '𝓅', 'Q': '𝓆', 'R': '𝓇', 'S': '𝓈', 'T': '𝓉', 'U': '𝓊',
    'V': '𝓋', 'W': '𝓌', 'X': '𝓍', 'Y': '𝓎', 'Z': '𝓏',
  };

  return text
    .split('')
    .map((char) => decorativeCharacters[char] || char)
    .join('');
};
//Cursor Text: 
// 𝒽ℯ𝓁𝓁ℴ, 𝓌ℴ𝓇𝓁𝒹!"
// Output: "🇭🇪🇱🇱🇴, 🇼🇴🇷🇱🇩!"
export const SmallCapsText = (text) => {
  const smallCapsChars = {
    'A': 'ᴀ', 'B': 'ʙ', 'C': 'ᴄ', 'D': 'ᴅ', 'E': 'ᴇ', 'F': 'ꜰ', 'G': 'ɢ',
    'H': 'ʜ', 'I': 'ɪ', 'J': 'ᴊ', 'K': 'ᴋ', 'L': 'ʟ', 'M': 'ᴍ', 'N': 'ɴ',
    'O': 'ᴏ', 'P': 'ᴘ', 'Q': 'ǫ', 'R': 'ʀ', 'S': 'ꜱ', 'T': 'ᴛ', 'U': 'ᴜ',
    'V': 'ᴠ', 'W': 'ᴡ', 'X': 'x', 'Y': 'ʏ', 'Z': 'ᴢ',
    'a': 'ᴀ', 'b': 'ʙ', 'c': 'ᴄ', 'd': 'ᴅ', 'e': 'ᴇ', 'f': 'ꜰ', 'g': 'ɢ',
    'h': 'ʜ', 'i': 'ɪ', 'j': 'ᴊ', 'k': 'ᴋ', 'l': 'ʟ', 'm': 'ᴍ', 'n': 'ɴ',
    'o': 'ᴏ', 'p': 'ᴘ', 'q': 'ǫ', 'r': 'ʀ', 's': 'ꜱ', 't': 'ᴛ', 'u': 'ᴜ',
    'v': 'ᴠ', 'w': 'ᴡ', 'x': 'x', 'y': 'ʏ', 'z': 'ᴢ',
    '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6',
    '7': '7', '8': '8', '9': '9',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => smallCapsChars[char] || char)
    .join('');
};

export const SmallTextGenerator = (text) => {
  const smallText = text
    .split('')
    .map((char) => char.toLowerCase())
    .join('');

  return SmallCapsText(smallText);
};
// ᴛᴇxᴛ ᴛᴏ ꜰᴀᴄᴇʙᴏᴏᴋ ꜰᴏɴᴛ
// Output: "ʜᴇʟʟᴏ, ᴡᴏʀʟᴅ!"
export const TinyText = (text) => {
  const tinyChars = {
    'A': 'ᴀ', 'B': 'ʙ', 'C': 'ᴄ', 'D': 'ᴅ', 'E': 'ᴇ', 'F': 'ꜰ', 'G': 'ɢ',
    'H': 'ʜ', 'I': 'ɪ', 'J': 'ᴊ', 'K': 'ᴋ', 'L': 'ʟ', 'M': 'ᴍ', 'N': 'ɴ',
    'O': 'ᴏ', 'P': 'ᴘ', 'Q': 'ǫ', 'R': 'ʀ', 'S': 'ꜱ', 'T': 'ᴛ', 'U': 'ᴜ',
    'V': 'ᴠ', 'W': 'ᴡ', 'X': 'x', 'Y': 'ʏ', 'Z': 'ᴢ',
    'a': 'ᴀ', 'b': 'ʙ', 'c': 'ᴄ', 'd': 'ᴅ', 'e': 'ᴇ', 'f': 'ꜰ', 'g': 'ɢ',
    'h': 'ʜ', 'i': 'ɪ', 'j': 'ᴊ', 'k': 'ᴋ', 'l': 'ʟ', 'm': 'ᴍ', 'n': 'ɴ',
    'o': 'ᴏ', 'p': 'ᴘ', 'q': 'ǫ', 'r': 'ʀ', 's': 'ꜱ', 't': 'ᴛ', 'u': 'ᴜ',
    'v': 'ᴠ', 'w': 'ᴡ', 'x': 'x', 'y': 'ʏ', 'z': 'ᴢ',
    '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6',
    '7': '7', '8': '8', '9': '9',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '-', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => tinyChars[char] || char)
    .join('');
};
// Output: "ʜᴇʟʟᴏ, ᴡᴏʀʟᴅ!"

export const EnlargedText = (text) => {
  const enlargedChars = {
    'A': 'Ａ', 'B': 'Ｂ', 'C': 'Ｃ', 'D': 'Ｄ', 'E': 'Ｅ', 'F': 'Ｆ', 'G': 'Ｇ',
    'H': 'Ｈ', 'I': 'Ｉ', 'J': 'Ｊ', 'K': 'Ｋ', 'L': 'Ｌ', 'M': 'Ｍ', 'N': 'Ｎ',
    'O': 'Ｏ', 'P': 'Ｐ', 'Q': 'Ｑ', 'R': 'Ｒ', 'S': 'Ｓ', 'T': 'Ｔ', 'U': 'Ｕ',
    'V': 'Ｖ', 'W': 'Ｗ', 'X': 'Ｘ', 'Y': 'Ｙ', 'Z': 'Ｚ',
    'a': 'ａ', 'b': 'ｂ', 'c': 'ｃ', 'd': 'ｄ', 'e': 'ｅ', 'f': 'ｆ', 'g': 'ｇ',
    'h': 'ｈ', 'i': 'ｉ', 'j': 'ｊ', 'k': 'ｋ', 'l': 'ｌ', 'm': 'ｍ', 'n': 'ｎ',
    'o': 'ｏ', 'p': 'ｐ', 'q': 'ｑ', 'r': 'ｒ', 's': 'ｓ', 't': 'ｔ', 'u': 'ｕ',
    'v': 'ｖ', 'w': 'ｗ', 'x': 'ｘ', 'y': 'ｙ', 'z': 'ｚ',
    '0': '０', '1': '１', '2': '２', '3': '３', '4': '４', '5': '５', '6': '６',
    '7': '７', '8': '８', '9': '９',
    ' ': ' ', ',': '，', ';': '；', ':': '：', '?': '？', '!': '！',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '／', '\\': '＼', '-': '－', '=': '＝', '+': '＋'
  };
  return text
    .split('')
    .map((char) => enlargedChars[char] || char)
    .join('');
};
// Ｔｅｘｔ Ｔｏ Ｆａｃｅｂｏｏｋ Ｆｏｎｔ
// Output: "Ｈｅｌｌｏ， ｗｏｒｌｄ！"

export const UpsideDownText = (text) => {
  const upsideDownChars = {
    'A': '∀', 'B': '𐐒', 'C': 'Ɔ', 'D': 'ᗡ', 'E': 'Ǝ', 'F': 'Ⅎ', 'G': 'פ',
    'H': 'H', 'I': 'I', 'J': 'ſ', 'K': 'ʞ', 'L': '˥', 'M': 'W', 'N': 'N',
    'O': 'O', 'P': 'Ԁ', 'Q': 'Ὁ', 'R': 'ᴚ', 'S': 'S', 'T': '⊥', 'U': '∩',
    'V': 'Λ', 'W': 'M', 'X': 'X', 'Y': 'ʎ', 'Z': 'Z',
    'a': 'ɐ', 'b': 'q', 'c': 'ɔ', 'd': 'p', 'e': 'ǝ', 'f': 'ɟ', 'g': 'ƃ',
    'h': 'ɥ', 'i': 'ᴉ', 'j': 'ɾ', 'k': 'ʞ', 'l': 'l', 'm': 'ɯ', 'n': 'u',
    'o': 'o', 'p': 'd', 'q': 'b', 'r': 'ɹ', 's': 's', 't': 'ʇ', 'u': 'n',
    'v': 'ʌ', 'w': 'ʍ', 'x': 'x', 'y': 'ʎ', 'z': 'z',
    '0': '0', '1': 'Ɩ', '2': 'ᄅ', '3': 'Ɛ', '4': 'ㄣ', '5': 'ϛ', '6': '9',
    '7': 'ㄥ', '8': '8', '9': '6',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '-', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => upsideDownChars[char] || char)
    .join('');
};
// Output: "¡plɹoM ,ollǝH"

export const GothicText = (text) => {
  const gothicChars = {
    'A': '𝔄', 'B': '𝔅', 'C': 'ℭ', 'D': '𝔇', 'E': '𝔈', 'F': '𝔉', 'G': '𝔊',
    'H': 'ℌ', 'I': 'ℑ', 'J': '𝔍', 'K': '𝔎', 'L': '𝔏', 'M': '𝔐', 'N': '𝔑',
    'O': '𝔒', 'P': '𝔓', 'Q': '𝔔', 'R': 'ℜ', 'S': '𝔖', 'T': '𝔗', 'U': '𝔘',
    'V': '𝔙', 'W': '𝔚', 'X': '𝔛', 'Y': '𝔜', 'Z': 'ℨ',
    'a': '𝔞', 'b': '𝔟', 'c': '𝔠', 'd': '𝔡', 'e': '𝔢', 'f': '𝔣', 'g': '𝔤',
    'h': '𝔥', 'i': '𝔦', 'j': '𝔧', 'k': '𝔨', 'l': '𝔩', 'm': '𝔪', 'n': '𝔫',
    'o': '𝔬', 'p': '𝔭', 'q': '𝔮', 'r': '𝔯', 's': '𝔰', 't': '𝔱', 'u': '𝔲',
    'v': '𝔳', 'w': '𝔴', 'x': '𝔵', 'y': '𝔶', 'z': '𝔷',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => gothicChars[char] || char)
    .join('');
};
// 𝔄𝔢𝔩𝔩𝔬 𝔴𝔬𝔯𝔡!

export const HeartText = (text) => {
  const heartChars = {
    'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔', 'F': '𝓕', 'G': '𝓖',
    'H': '𝓗', 'I': '𝓘', 'J': '𝓙', 'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝',
    'O': '𝓞', 'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣', 'U': '𝓤',
    'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
    'a': '𝓪', 'b': '𝓫', 'c': '𝓬', 'd': '𝓭', 'e': '𝓮', 'f': '𝓯', 'g': '𝓰',
    'h': '𝓱', 'i': '𝓲', 'j': '𝓳', 'k': '𝓴', 'l': '𝓵', 'm': '𝓶', 'n': '𝓷',
    'o': '𝓸', 'p': '𝓹', 'q': '𝓺', 'r': '𝓻', 's': '𝓼', 't': '𝓽', 'u': '𝓾',
    'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃',
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔',
    '7': '𝟕', '8': '𝟖', '9': '𝟗',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => heartChars[char] || char)
    .join('');
};
//𝓗𝓮𝓵𝓵𝓸, 𝔀𝓸𝓻𝓵𝓭!

export const OldEnglishText = (text) => {
  const oldEnglishChars = {
    'A': '𝔄', 'B': '𝔅', 'C': 'ℭ', 'D': '𝔇', 'E': '𝔈', 'F': '𝔉', 'G': '𝔊',
    'H': 'ℌ', 'I': 'ℑ', 'J': '𝔍', 'K': '𝔎', 'L': '𝔏', 'M': '𝔐', 'N': '𝔑',
    'O': '𝔒', 'P': '𝔓', 'Q': '𝔔', 'R': 'ℜ', 'S': '𝔖', 'T': '𝔗', 'U': '𝔘',
    'V': '𝔙', 'W': '𝔚', 'X': '𝔛', 'Y': '𝔜', 'Z': 'ℨ',
    'a': '𝔞', 'b': '𝔟', 'c': '𝔠', 'd': '𝔡', 'e': '𝔢', 'f': '𝔣', 'g': '𝔤',
    'h': '𝔥', 'i': '𝔦', 'j': '𝔧', 'k': '𝔨', 'l': '𝔩', 'm': '𝔪', 'n': '𝔫',
    'o': '𝔬', 'p': '𝔭', 'q': '𝔮', 'r': '𝔯', 's': '𝔰', 't': '𝔱', 'u': '𝔲',
    'v': '𝔳', 'w': '𝔴', 'x': '𝔵', 'y': '𝔶', 'z': '𝔷'
  };

  return text
    .split('')
    .map((char) => oldEnglishChars[char] || char)
    .join('');
};
//ℌ𝔢𝔩𝔩𝔬, 𝔴𝔬𝔯𝔩𝔡!
export const ImpactText = (text) => {
  const impactChars = {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => impactChars[char] || char)
    .join('');
};
// 𝗛𝗲𝗹𝗹𝗼, 𝘄𝗼𝗿𝗹𝗱!
export const CourierNewText = (text) => {
  const courierNewChars = {
    'A': '𝙰', 'B': '𝙱', 'C': '𝙲', 'D': '𝙳', 'E': '𝙴', 'F': '𝙵', 'G': '𝙶',
    'H': '𝙷', 'I': '𝙸', 'J': '𝙹', 'K': '𝙺', 'L': '𝙻', 'M': '𝙼', 'N': '𝙽',
    'O': '𝙾', 'P': '𝙿', 'Q': '𝚀', 'R': '𝚁', 'S': '𝚂', 'T': '𝚃', 'U': '𝚄',
    'V': '𝚅', 'W': '𝚆', 'X': '𝚇', 'Y': '𝚈', 'Z': '𝚉',
    'a': '𝚊', 'b': '𝚋', 'c': '𝚌', 'd': '𝚍', 'e': '𝚎', 'f': '𝚏', 'g': '𝚐',
    'h': '𝚑', 'i': '𝚒', 'j': '𝚓', 'k': '𝚔', 'l': '𝚕', 'm': '𝚖', 'n': '𝚗',
    'o': '𝚘', 'p': '𝚙', 'q': '𝚚', 'r': '𝚛', 's': '𝚜', 't': '𝚝', 'u': '𝚞',
    'v': '𝚟', 'w': '𝚠', 'x': '𝚡', 'y': '𝚢', 'z': '𝚣',
    '0': '𝟶', '1': '𝟷', '2': '𝟸', '3': '𝟹', '4': '𝟺', '5': '𝟻', '6': '𝟼',
    '7': '𝟽', '8': '𝟾', '9': '𝟿',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => courierNewChars[char] || char)
    .join('');
};
//𝙷𝚎𝚕𝚕𝚘, 𝚠𝚘𝚛𝚕𝚍!
export const TimesNewRomanText = (text) => {
  const timesNewRomanChars = {
    'A': '𝕬', 'B': '𝕭', 'C': '𝕮', 'D': '𝕯', 'E': '𝕰', 'F': '𝕱', 'G': '𝕲',
    'H': '𝕳', 'I': '𝕴', 'J': '𝕵', 'K': '𝕶', 'L': '𝕷', 'M': '𝕸', 'N': '𝕹',
    'O': '𝕺', 'P': '𝕻', 'Q': '𝕼', 'R': '𝕽', 'S': '𝕾', 'T': '𝕿', 'U': '𝖀',
    'V': '𝖁', 'W': '𝖂', 'X': '𝖃', 'Y': '𝖄', 'Z': '𝖅',
    'a': '𝖆', 'b': '𝖇', 'c': '𝖈', 'd': '𝖉', 'e': '𝖊', 'f': '𝖋', 'g': '𝖌',
    'h': '𝖍', 'i': '𝖎', 'j': '𝖏', 'k': '𝖐', 'l': '𝖑', 'm': '𝖒', 'n': '𝖓',
    'o': '𝖔', 'p': '𝖕', 'q': '𝖖', 'r': '𝖗', 's': '𝖘', 't': '𝖙', 'u': '𝖚',
    'v': '𝖛', 'w': '𝖜', 'x': '𝖝', 'y': '𝖞', 'z': '𝖟',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => timesNewRomanChars[char] || char)
    .join('');
};
//Cursor Text:
//𝕳𝖊𝖑𝖑𝖔, 𝖜𝖔𝖗𝖑𝖉!
export const LucidaConsoleText = (text) => {
  const lucidaConsoleChars = {
    'A': '𝙰', 'B': '𝙱', 'C': '𝙲', 'D': '𝙳', 'E': '𝙴', 'F': '𝙵', 'G': '𝙶',
    'H': '𝙷', 'I': '𝙸', 'J': '𝙹', 'K': '𝙺', 'L': '𝙻', 'M': '𝙼', 'N': '𝙽',
    'O': '𝙾', 'P': '𝙿', 'Q': '𝚀', 'R': '𝚁', 'S': '𝚂', 'T': '𝚃', 'U': '𝚄',
    'V': '𝚅', 'W': '𝚆', 'X': '𝚇', 'Y': '𝚈', 'Z': '𝚉',
    'a': '𝚊', 'b': '𝚋', 'c': '𝚌', 'd': '𝚍', 'e': '𝚎', 'f': '𝚏', 'g': '𝚐',
    'h': '𝚑', 'i': '𝚒', 'j': '𝚓', 'k': '𝚔', 'l': '𝚕', 'm': '𝚖', 'n': '𝚗',
    'o': '𝚘', 'p': '𝚙', 'q': '𝚚', 'r': '𝚛', 's': '𝚜', 't': '𝚝', 'u': '𝚞',
    'v': '𝚟', 'w': '𝚠', 'x': '𝚡', 'y': '𝚢', 'z': '𝚣',
    '0': '𝟶', '1': '𝟷', '2': '𝟸', '3': '𝟹', '4': '𝟺', '5': '𝟻', '6': '𝟼',
    '7': '𝟽', '8': '𝟾', '9': '𝟿',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => lucidaConsoleChars[char] || char)
    .join('');
};
//𝚑𝚎𝚕𝚕𝚘, 𝚠𝚘𝚛𝚕𝚍!
export const LucidaSansText = (text) => {
  const lucidaSansChars = {
    'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝐄', 'F': '𝐅', 'G': '𝐆',
    'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝐍',
    'O': '𝐎', 'P': '𝐏', 'Q': '𝐐', 'R': '𝐑', 'S': '𝐒', 'T': '𝐓', 'U': '𝐔',
    'V': '𝐕', 'W': '𝐖', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙',
    'a': '𝐚', 'b': '𝐛', 'c': '𝐜', 'd': '𝐝', 'e': '𝐞', 'f': '𝐟', 'g': '𝐠',
    'h': '𝐡', 'i': '𝐢', 'j': '𝐣', 'k': '𝐤', 'l': '𝐥', 'm': '𝐦', 'n': '𝐧',
    'o': '𝐨', 'p': '𝐩', 'q': '𝐪', 'r': '𝐫', 's': '𝐬', 't': '𝐭', 'u': '𝐮',
    'v': '𝐯', 'w': '𝐰', 'x': '𝐱', 'y': '𝐲', 'z': '𝐳',
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔',
    '7': '𝟕', '8': '𝟖', '9': '𝟗',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => lucidaSansChars[char] || char)
    .join('');
};
//Output: "𝐻𝑒𝓁𝓁𝑜, 𝓌𝑜𝓇𝓁𝒹!"
export const BroadwayText = (text) => {
  const broadwayChars = {
    'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝐄', 'F': '𝐅', 'G': '𝐆',
    'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝐍',
    'O': '𝐎', 'P': '𝐏', 'Q': '𝐐', 'R': '𝐑', 'S': '𝐒', 'T': '𝐓', 'U': '𝐔',
    'V': '𝐕', 'W': '𝐖', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙',
    'a': '𝐚', 'b': '𝐛', 'c': '𝐜', 'd': '𝐝', 'e': '𝐞', 'f': '𝐟', 'g': '𝐠',
    'h': '𝐡', 'i': '𝐢', 'j': '𝐣', 'k': '𝐤', 'l': '𝐥', 'm': '𝐦', 'n': '𝐧',
    'o': '𝐨', 'p': '𝐩', 'q': '𝐪', 'r': '𝐫', 's': '𝐬', 't': '𝐭', 'u': '𝐮',
    'v': '𝐯', 'w': '𝐰', 'x': '𝐱', 'y': '𝐲', 'z': '𝐳',
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔',
    '7': '𝟕', '8': '𝟖', '9': '𝟗',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => broadwayChars[char] || char)
    .join('');
};
//Output: "𝐇𝐞𝐥𝐥𝐨, 𝐰𝐨𝐫𝐥𝐝!" 

export const EdwardianScriptText = (text) => {
  const edwardianScriptChars = {
    'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝒟', 'E': '𝐸', 'F': '𝐹', 'G': '𝒢',
    'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩',
    'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰',
    'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔',
    'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃',
    'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊',
    'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔',
    '7': '𝟕', '8': '𝟖', '9': '𝟗',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => edwardianScriptChars[char] || char)
    .join('');
};
//Output: 𝐻𝑒𝓁𝓁𝑜 𝓌𝑜𝓇𝓁𝒹!
export const HarlowSolidText = (text) => {
  const harlowSolidChars = {
    'A': '𐒀', 'B': '𐒁', 'C': '𐒂', 'D': '𐒃', 'E': '𐒄', 'F': '𐒅', 'G': '𐒆',
    'H': '𐒇', 'I': '𐒈', 'J': '𐒉', 'K': '𐒊', 'L': '𐒋', 'M': '𐒌', 'N': '𐒍',
    'O': '𐒎', 'P': '𐒏', 'Q': '𐒐', 'R': '𐒑', 'S': '𐒒', 'T': '𐒓', 'U': '𐒔',
    'V': '𐒕', 'W': '𐒖', 'X': '𐒗', 'Y': '𐒘', 'Z': '𐒙',
    'a': '𐒚', 'b': '𐒛', 'c': '𐒜', 'd': '𐒝', 'e': '𐒥', 'f': '𐒧', 'g': '𐒨',
    'h': '𐒡', 'i': '𐒢', 'j': '𐒣', 'k': '𐒤', 'l': '𐒥', 'm': '𐒦', 'n': '𐒧',
    'o': '𐒨', 'p': '𐒩', 'q': '𐒐', 'r': '𐒑', 's': '𐒒', 't': '𐒓', 'u': '𐒔',
    'v': '𐒕', 'w': '𐒰', 'x': '𐒱', 'y': '𐒲', 'z': '𐒳',
    '0': '𐒴', '1': '𐒵', '2': '𐒶', '3': '𐒷', '4': '𐒸', '5': '𐒹', '6': '𐒺',
    '7': '𐒻', '8': '𐒼', '9': '𐒽',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '-', '=': '=', '+': '+',
    // Add symbols below (these are just placeholders, you can replace them with actual symbols in the font if needed)
    '&': '&', '%': '%', '$': '$', '@': '@', '#': '#', '*': '*', '^': '^', '_': '_',
    '<': '<', '>': '>', '[': '[', ']': ']', '{': '{', '}': '}', '|': '|', '`': '`'
  };

  return text
    .split('')
    .map((char) => harlowSolidChars[char] || char)
    .join('');
};
//𐒄𐒧𐒧𐒝𐒡 𐒏𐒕𐒓𐒔!
export const StencilText = (text) => {
  const stencilChars = {
    'A': '█A█', 'B': '█B█', 'C': '█C█', 'D': '█D█', 'E': '█E█', 'F': '█F█', 'G': '█G█',
    'H': '█H█', 'I': '█I█', 'J': '█J█', 'K': '█K█', 'L': '█L█', 'M': '█M█', 'N': '█N█',
    'O': '█O█', 'P': '█P█', 'Q': '█Q█', 'R': '█R█', 'S': '█S█', 'T': '█T█', 'U': '█U█',
    'V': '█V█', 'W': '█W█', 'X': '█X█', 'Y': '█Y█', 'Z': '█Z█',
    'a': '█a█', 'b': '█b█', 'c': '█c█', 'd': '█d█', 'e': '█e█', 'f': '█f█', 'g': '█g█',
    'h': '█h█', 'i': '█i█', 'j': '█j█', 'k': '█k█', 'l': '█l█', 'm': '█m█', 'n': '█n█',
    'o': '█o█', 'p': '█p█', 'q': '█q█', 'r': '█r█', 's': '█s█', 't': '█t█', 'u': '█u█',
    'v': '█v█', 'w': '█w█', 'x': '█x█', 'y': '█y█', 'z': '█z█',
    '0': '█0█', '1': '█1█', '2': '█2█', '3': '█3█', '4': '█4█', '5': '█5█', '6': '█6█',
    '7': '█7█', '8': '█8█', '9': '█9█',
    ' ': '   ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '-', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => stencilChars[char] || char)
    .join('');
};
//Output: "█H█e█l█l█o█, █w█o█r█l█d█!"
export const BookAntiquaText = (text) => {
  const bookAntiquaChars = {
    'A': '𝔸', 'B': '𝔹', 'C': 'ℂ', 'D': '𝔻', 'E': '𝔼', 'F': '𝔽', 'G': '𝔾',
    'H': 'ℍ', 'I': '𝕀', 'J': '𝕁', 'K': '𝕂', 'L': '𝕃', 'M': '𝕄', 'N': 'ℕ',
    'O': '𝕆', 'P': 'ℙ', 'Q': 'ℚ', 'R': 'ℝ', 'S': '𝕊', 'T': '𝕋', 'U': '𝕌',
    'V': '𝕍', 'W': '𝕎', 'X': '𝕏', 'Y': '𝕐', 'Z': 'ℤ',
    'a': '𝕒', 'b': '𝕓', 'c': '𝕔', 'd': '𝕕', 'e': '𝕖', 'f': '𝕗', 'g': '𝕘',
    'h': '𝕙', 'i': '𝕚', 'j': '𝕛', 'k': '𝕜', 'l': '𝕝', 'm': '𝕞', 'n': '𝕟',
    'o': '𝕠', 'p': '𝕡', 'q': '𝕢', 'r': '𝕣', 's': '𝕤', 't': '𝕥', 'u': '𝕦',
    'v': '𝕧', 'w': '𝕨', 'x': '𝕩', 'y': '𝕪', 'z': '𝕫',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => bookAntiquaChars[char] || char)
    .join('');
};
// ℍ𝕖𝕝𝕝𝕠 𝕎𝕠𝕣𝕝𝕕! 
export const Backtick = (text) => {
  return text
    .split('')
    .map((char) => `\`${char}`)
    .join('');
};
//`T`e`x`t` `T`o` `F`a`c`e`b`o`o`k` `F`o`n`t
// Output: `H`e`l`l`o

export const Hyphen = (text) => {
  return text
    .split('')
    .map((char) => `-${char}`)
    .join('');
};
// Output: -H-e-l-l-o
export const Underscore = (text) => {
  return text
    .split('')
    .map((char) => `_${char}`)
    .join('');
};
// Output: _H_e_l_l_o
export const Asterisk = (text) => {
  return text
    .split('')
    .map((char) => `*${char}`)
    .join('');
};
// Output: *H*e*l*l*o
export const Hash = (text) => {
  return text
    .split('')
    .map((char) => `#${char}`)
    .join('');
};
// Output: #H#e#l#l#o
export const UnderlineText = (text) => {
  const underlineChars = {
    'A': '̲A', 'B': '̲B', 'C': '̲C', 'D': '̲D', 'E': '̲E', 'F': '̲F', 'G': '̲G',
    'H': '̲H', 'I': '̲I', 'J': '̲J', 'K': '̲K', 'L': '̲L', 'M': '̲M', 'N': '̲N',
    'O': '̲O', 'P': '̲P', 'Q': '̲Q', 'R': '̲R', 'S': '̲S', 'T': '̲T', 'U': '̲U',
    'V': '̲V', 'W': '̲W', 'X': '̲X', 'Y': '̲Y', 'Z': '̲Z',
    'a': '̲a', 'b': '̲b', 'c': '̲c', 'd': '̲d', 'e': '̲e', 'f': '̲f', 'g': '̲g',
    'h': '̲h', 'i': '̲i', 'j': '̲j', 'k': '̲k', 'l': '̲l', 'm': '̲m', 'n': '̲n',
    'o': '̲o', 'p': '̲p', 'q': '̲q', 'r': '̲r', 's': '̲s', 't': '̲t', 'u': '̲u',
    'v': '̲v', 'w': '̲w', 'x': '̲x', 'y': '̲y', 'z': '̲z',
    '0': '̲0', '1': '̲1', '2': '̲2', '3': '̲3', '4': '̲4', '5': '̲5', '6': '̲6',
    '7': '̲7', '8': '̲8', '9': '̲9',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => underlineChars[char] || char)
    .join('');
};

// Output: "̲H̲e̲l̲l̲o̲,̲ ̲w̲o̲r̲l̲d̲!"

export const GlitchText = (text) => {
  const glitchChars = {
    'A': 'A̸', 'B': 'B̸', 'C': 'C̸', 'D': 'D̸', 'E': 'E̸', 'F': 'F̸', 'G': 'G̸',
    'H': 'H̸', 'I': 'I̸', 'J': 'J̸', 'K': 'K̸', 'L': 'L̸', 'M': 'M̸', 'N': 'N̸',
    'O': 'O̸', 'P': 'P̸', 'Q': 'Q̸', 'R': 'R̸', 'S': 'S̸', 'T': 'T̸', 'U': 'U̸',
    'V': 'V̸', 'W': 'W̸', 'X': 'X̸', 'Y': 'Y̸', 'Z': 'Z̸',
    'a': 'a̸', 'b': 'b̸', 'c': 'c̸', 'd': 'd̸', 'e': 'e̸', 'f': 'f̸', 'g': 'g̸',
    'h': 'h̸', 'i': 'i̸', 'j': 'j̸', 'k': 'k̸', 'l': 'l̸', 'm': 'm̸', 'n': 'n̸',
    'o': 'o̸', 'p': 'p̸', 'q': 'q̸', 'r': 'r̸', 's': 's̸', 't': 't̸', 'u': 'u̸',
    'v': 'v̸', 'w': 'w̸', 'x': 'x̸', 'y': 'y̸', 'z': 'z̸',
    '0': '0̸', '1': '1̸', '2': '2̸', '3': '3̸', '4': '4̸', '5': '5̸', '6': '6̸',
    '7': '7̸', '8': '8̸', '9': '9̸',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => glitchChars[char] || char)
    .join('');
};
//Cursor Text: 

// Output: "H̸e̸l̸l̸o̸,̸ ̸w̸o̸r̸l̸d̸!"
export const StrikeThroughText = (text) => {
  const strikeThroughChars = {
    'A': 'A̶', 'B': 'B̶', 'C': 'C̶', 'D': 'D̶', 'E': 'E̶', 'F': 'F̶', 'G': 'G̶',
    'H': 'H̶', 'I': 'I̶', 'J': 'J̶', 'K': 'K̶', 'L': 'L̶', 'M': 'M̶', 'N': 'N̶',
    'O': 'O̶', 'P': 'P̶', 'Q': 'Q̶', 'R': 'R̶', 'S': 'S̶', 'T': 'T̶', 'U': 'U̶',
    'V': 'V̶', 'W': 'W̶', 'X': 'X̶', 'Y': 'Y̶', 'Z': 'Z̶',
    'a': 'a̶', 'b': 'b̶', 'c': 'c̶', 'd': 'd̶', 'e': 'e̶', 'f': 'f̶', 'g': 'g̶',
    'h': 'h̶', 'i': 'i̶', 'j': 'j̶', 'k': 'k̶', 'l': 'l̶', 'm': 'm̶', 'n': 'n̶',
    'o': 'o̶', 'p': 'p̶', 'q': 'q̶', 'r': 'r̶', 's': 's̶', 't': 't̶', 'u': 'u̶',
    'v': 'v̶', 'w': 'w̶', 'x': 'x̶', 'y': 'y̶', 'z': 'z̶',
    '0': '0̶', '1': '1̶', '2': '2̶', '3': '3̶', '4': '4̶', '5': '5̶', '6': '6̶',
    '7': '7̶', '8': '8̶', '9': '9̶',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => strikeThroughChars[char] || char)
    .join('');
};

// Output: "H̶e̶l̶l̶o̶,̶ ̶w̶o̶r̶l̶d̶!"

export const SquareBrackets = (text) => {
  return text
    .split('')
    .map((char) => `【${char}】`)
    .join('');
};
// Output: '【A】【B】【C】
export const CurlyBrackets = (text) => {
  return text
    .split('')
    .map((char) => `『${char}』`)
    .join('');
};
// Output: '『a』『c』『c』

export const FullWidthPeriod = (text) => {
  const fullWidthPeriod = '．';

  return text
    .split('')
    .map((char) => (char >= '0' && char <= '9') ? `${char}${fullWidthPeriod}` : char)
    .join('');
};
// Output: 'A．B．C．
export const RegularPeriod = (text) => {
  const regularPeriod = '.';

  return text
    .split('')
    .map((char) => (char >= '0' && char <= '9') ? `${char}${regularPeriod}` : char)
    .join('');
};
// Output: 'A.B.C.
export const Parentheses = (text) => {
  return text
    .split('')
    .map((char) => `(${char})`)
    .join('');
};
// Output: (H)(e)(l)(l)(o)
export const SquareBracketsNormal = (text) => {
  return text
    .split('')
    .map((char) => `[${char}]`)
    .join('');
};
// Output: [H][e][l][l][o]

export const CurlyBracketsNormal = (text) => {
  return text
    .split('')
    .map((char) => `{${char}}`)
    .join('');
};
// Output: {H}{e}{l}{l}{o}

export const Slash = (text) => {
  return text
    .split('')
    .map((char) => `/${char}`)
    .join('');
};
// Output: /H/e/l/l/o
export const Backslash = (text) => {
  return text
    .split('')
    .map((char) => `\\${char}`)
    .join('');
};
// Output: \H\e\l\l\o
export const Pipe = (text) => {
  return text
    .split('')
    .map((char) => `|${char}|`)
    .join('');
};
// Output: |H||e||l||l||o|
export const Exclamation = (text) => {
  return text
    .split('')
    .map((char) => `!${char}`)
    .join('');
};
// Output: !H!e!l!l!o

export const Tilde = (text) => {
  return text
    .split('')
    .map((char) => `~${char}`)
    .join('');
};
// Output: ~H~e~l~l~o


//Repeated Again
export const BoldText = (text) => {
  const boldChars = {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => boldChars[char] || char)
    .join('');
};
// Output: "𝗛𝗲𝗹𝗹𝗼, 𝘄𝗼𝗿𝗹𝗱!"
export const ItalicText = (text) => {
  const italicChars = {
    'A': '𝘈', 'B': '𝘉', 'C': '𝘊', 'D': '𝘋', 'E': '𝘌', 'F': '𝘍', 'G': '𝘎',
    'H': '𝘏', 'I': '𝘐', 'J': '𝘑', 'K': '𝘒', 'L': '𝘓', 'M': '𝘔', 'N': '𝘕',
    'O': '𝘖', 'P': '𝘗', 'Q': '𝘘', 'R': '𝘙', 'S': '𝘚', 'T': '𝘛', 'U': '𝘜',
    'V': '𝘝', 'W': '𝘞', 'X': '𝘟', 'Y': '𝘠', 'Z': '𝘡',
    'a': '𝘢', 'b': '𝘣', 'c': '𝘤', 'd': '𝘥', 'e': '𝘦', 'f': '𝘧', 'g': '𝘨',
    'h': '𝘩', 'i': '𝘪', 'j': '𝘫', 'k': '𝘬', 'l': '𝘭', 'm': '𝘮', 'n': '𝘯',
    'o': '𝘰', 'p': '𝘱', 'q': '𝘲', 'r': '𝘳', 's': '𝘴', 't': '𝘵', 'u': '𝘶',
    'v': '𝘷', 'w': '𝘸', 'x': '𝘹', 'y': '𝘺', 'z': '𝘻',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => italicChars[char] || char)
    .join('');
};
// Output: "𝘏𝘦𝘭𝘭𝘰, 𝘸𝘰𝘳𝘭𝘥!"
export const BoldItalicText = (text) => {
  const boldItalicChars = {
    'A': '𝘼', 'B': '𝘽', 'C': '𝘾', 'D': '𝘿', 'E': '𝙀', 'F': '𝙁', 'G': '𝙂',
    'H': '𝙃', 'I': '𝙄', 'J': '𝙅', 'K': '𝙆', 'L': '𝙇', 'M': '𝙈', 'N': '𝙉',
    'O': '𝙊', 'P': '𝙋', 'Q': '𝙌', 'R': '𝙍', 'S': '𝙎', 'T': '𝙏', 'U': '𝙐',
    'V': '𝙑', 'W': '𝙒', 'X': '𝙓', 'Y': '𝙔', 'Z': '𝙕',
    'a': '𝙖', 'b': '𝙗', 'c': '𝙘', 'd': '𝙙', 'e': '𝙚', 'f': '𝙛', 'g': '𝙜',
    'h': '𝙝', 'i': '𝙞', 'j': '𝙟', 'k': '𝙠', 'l': '𝙡', 'm': '𝙢', 'n': '𝙣',
    'o': '𝙤', 'p': '𝙥', 'q': '𝙦', 'r': '𝙧', 's': '𝙨', 't': '𝙩', 'u': '𝙪',
    'v': '𝙫', 'w': '𝙬', 'x': '𝙭', 'y': '𝙮', 'z': '𝙯',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => boldItalicChars[char] || char)
    .join('');
};
// Output: 𝘏𝘦𝘭𝘭𝘰, 𝘸𝘰𝘳𝘭𝘥! 𝘛𝘩𝘪𝘴 𝘪𝘴 123.
export const MonospaceText = (text) => {
  const monospaceChars = {
    'A': '𝙰', 'B': '𝙱', 'C': '𝙲', 'D': '𝙳', 'E': '𝙴', 'F': '𝙵', 'G': '𝙶',
    'H': '𝙷', 'I': '𝙸', 'J': '𝙹', 'K': '𝙺', 'L': '𝙻', 'M': '𝙼', 'N': '𝙽',
    'O': '𝙾', 'P': '𝙿', 'Q': '𝚀', 'R': '𝚁', 'S': '𝚂', 'T': '𝚃', 'U': '𝚄',
    'V': '𝚅', 'W': '𝚆', 'X': '𝚇', 'Y': '𝚈', 'Z': '𝚉',
    'a': '𝚊', 'b': '𝚋', 'c': '𝚌', 'd': '𝚍', 'e': '𝚎', 'f': '𝚏', 'g': '𝚐',
    'h': '𝚑', 'i': '𝚒', 'j': '𝚓', 'k': '𝚔', 'l': '𝚕', 'm': '𝚖', 'n': '𝚗',
    'o': '𝚘', 'p': '𝚙', 'q': '𝚚', 'r': '𝚛', 's': '𝚜', 't': '𝚝', 'u': '𝚞',
    'v': '𝚟', 'w': '𝚠', 'x': '𝚡', 'y': '𝚢', 'z': '𝚣',
    '0': '𝟶', '1': '𝟷', '2': '𝟸', '3': '𝟹', '4': '𝟺', '5': '𝟻', '6': '𝟼',
    '7': '𝟽', '8': '𝟾', '9': '𝟿',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => monospaceChars[char] || char)
    .join('');
};
// Output: "𝙷𝚎𝚕𝚕𝚘, 𝚠𝚘𝚛𝚕𝚍!"
export const BoldMonospaceText = (text) => {
  const boldMonospaceChars = {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => boldMonospaceChars[char] || char)
    .join('');
};
// Output: "𝗛𝗲𝗹𝗹𝗼, 𝘄𝗼𝗿𝗹𝗱!"
export const ItalicMonospaceText = (text) => {
  const italicMonospaceChars = {
    'A': '𝘼', 'B': '𝘽', 'C': '𝘾', 'D': '𝘿', 'E': '𝙀', 'F': '𝙁', 'G': '𝙂',
    'H': '𝙃', 'I': '𝙄', 'J': '𝙅', 'K': '𝙆', 'L': '𝙇', 'M': '𝙈', 'N': '𝙉',
    'O': '𝙊', 'P': '𝙋', 'Q': '𝙌', 'R': '𝙍', 'S': '𝙎', 'T': '𝙏', 'U': '𝙐',
    'V': '𝙑', 'W': '𝙒', 'X': '𝙓', 'Y': '𝙔', 'Z': '𝙕',
    'a': '𝙖', 'b': '𝙗', 'c': '𝙘', 'd': '𝙙', 'e': '𝙚', 'f': '𝙛', 'g': '𝙜',
    'h': '𝙝', 'i': '𝙞', 'j': '𝙟', 'k': '𝙠', 'l': '𝙡', 'm': '𝙢', 'n': '𝙣',
    'o': '𝙤', 'p': '𝙥', 'q': '𝙦', 'r': '𝙧', 's': '𝙨', 't': '𝙩', 'u': '𝙪',
    'v': '𝙫', 'w': '𝙬', 'x': '𝙭', 'y': '𝙮', 'z': '𝙯',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => italicMonospaceChars[char] || char)
    .join('');
};
// Output: "𝘏𝘦𝘭𝘭𝘰, 𝘸𝘰𝘳𝘭𝘥!"
export const BoldItalicMonospaceText = (text) => {
  const boldItalicMonospaceChars = {
    'A': '𝑨', 'B': '𝑩', 'C': '𝑪', 'D': '𝑫', 'E': '𝑬', 'F': '𝑭', 'G': '𝑮',
    'H': '𝑯', 'I': '𝑰', 'J': '𝑱', 'K': '𝑲', 'L': '𝑳', 'M': '𝑴', 'N': '𝑵',
    'O': '𝑶', 'P': '𝑷', 'Q': '𝑸', 'R': '𝑹', 'S': '𝑺', 'T': '𝑻', 'U': '𝑼',
    'V': '𝑽', 'W': '𝑾', 'X': '𝑿', 'Y': '𝒀', 'Z': '𝒁',
    'a': '𝒂', 'b': '𝒃', 'c': '𝒄', 'd': '𝒅', 'e': '𝒆', 'f': '𝒇', 'g': '𝒈',
    'h': '𝒉', 'i': '𝒊', 'j': '𝒋', 'k': '𝒌', 'l': '𝒍', 'm': '𝒎', 'n': '𝒏',
    'o': '𝒐', 'p': '𝒑', 'q': '𝒒', 'r': '𝒓', 's': '𝒔', 't': '𝒕', 'u': '𝒖',
    'v': '𝒗', 'w': '𝒘', 'x': '𝒙', 'y': '𝒚', 'z': '𝒛',
    '0': '𝟎', '1': '𝟏', '2': '𝟐', '3': '𝟑', '4': '𝟒', '5': '𝟓', '6': '𝟔',
    '7': '𝟕', '8': '𝟖', '9': '𝟗',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => boldItalicMonospaceChars[char] || char)
    .join('');
};
// Output: 𝑯𝒆𝒍𝒍𝒐, 𝒘𝒐𝒓𝒍𝒅! 𝑻𝒉𝒊𝒔 𝒊𝒔 123.
export const CursiveText = (text) => {
  const cursiveChars = {
    'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝒟', 'E': '𝐸', 'F': '𝐹', 'G': '𝒢',
    'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩',
    'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰',
    'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔',
    'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃',
    'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊',
    'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '❓', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => cursiveChars[char] || char)
    .join('');
};
// 𝒽𝑒𝓁𝓁𝑜, 𝓌𝑜𝓇𝓁𝒹! 𝒯𝒽𝒾𝓈 𝒾𝓈 123.
export const BoldCursiveText = (text) => {
  const boldCursiveChars = {
    'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔', 'F': '𝓕', 'G': '𝓖',
    'H': '𝓗', 'I': '𝓘', 'J': '𝓙', 'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝',
    'O': '𝓞', 'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣', 'U': '𝓤',
    'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
    'a': '𝓪', 'b': '𝓫', 'c': '𝓬', 'd': '𝓭', 'e': '𝓮', 'f': '𝓯', 'g': '𝓰',
    'h': '𝓱', 'i': '𝓲', 'j': '𝓳', 'k': '𝓴', 'l': '𝓵', 'm': '𝓶', 'n': '𝓷',
    'o': '𝓸', 'p': '𝓹', 'q': '𝓺', 'r': '𝓻', 's': '𝓼', 't': '𝓽', 'u': '𝓾',
    'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '❓', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => boldCursiveChars[char] || char)
    .join('');
};
//𝓗𝓮𝓵𝓵𝓸, 𝔀𝓸𝓻𝓵𝓭! 𝓣𝓱𝓲𝓼 𝓲𝓼 123.
export const DoubleStruckText = (text) => {
  const doubleStruckChars = {
    'A': '𝔸', 'B': '𝔹', 'C': 'ℂ', 'D': '𝔻', 'E': '𝔼', 'F': '𝔽', 'G': '𝔾',
    'H': 'ℍ', 'I': '𝕀', 'J': '𝕁', 'K': '𝕂', 'L': '𝕃', 'M': '𝕄', 'N': 'ℕ',
    'O': '𝕆', 'P': 'ℙ', 'Q': 'ℚ', 'R': 'ℝ', 'S': '𝕊', 'T': '𝕋', 'U': '𝕌',
    'V': '𝕍', 'W': '𝕎', 'X': '𝕏', 'Y': '𝕐', 'Z': 'ℤ',
    'a': '𝕒', 'b': '𝕓', 'c': '𝕔', 'd': '𝕕', 'e': '𝕖', 'f': '𝕗', 'g': '𝕘',
    'h': '𝕙', 'i': '𝕚', 'j': '𝕛', 'k': '𝕜', 'l': '𝕝', 'm': '𝕞', 'n': '𝕟',
    'o': '𝕠', 'p': '𝕡', 'q': '𝕢', 'r': '𝕣', 's': '𝕤', 't': '𝕥', 'u': '𝕦',
    'v': '𝕧', 'w': '𝕨', 'x': '𝕩', 'y': '𝕪', 'z': '𝕫',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => doubleStruckChars[char] || char)
    .join('');
};
//ℍ𝕖𝕝𝕝𝕠, 𝕨𝕠𝕣𝕝𝕕!
export const SansSerifText = (text) => {
  const sansSerifChars = {
    'A': '𝖠', 'B': '𝖡', 'C': '𝖢', 'D': '𝖣', 'E': '𝖤', 'F': '𝖥', 'G': '𝖦',
    'H': '𝖧', 'I': '𝖨', 'J': '𝖩', 'K': '𝖪', 'L': '𝖫', 'M': '𝖬', 'N': '𝖭',
    'O': '𝖮', 'P': '𝖯', 'Q': '𝖰', 'R': '𝖱', 'S': '𝖲', 'T': '𝖳', 'U': '𝖴',
    'V': '𝖵', 'W': '𝖶', 'X': '𝖷', 'Y': '𝖸', 'Z': '𝖹',
    'a': '𝖺', 'b': '𝖻', 'c': '𝖼', 'd': '𝖽', 'e': '𝖾', 'f': '𝖿', 'g': '𝗀',
    'h': '𝗁', 'i': '𝗂', 'j': '𝗃', 'k': '𝗄', 'l': '𝗅', 'm': '𝗆', 'n': '𝗇',
    'o': '𝗈', 'p': '𝗉', 'q': '𝗊', 'r': '𝗋', 's': '𝗌', 't': '𝗍', 'u': '𝗎',
    'v': '𝗏', 'w': '𝗐', 'x': '𝗑', 'y': '𝗒', 'z': '𝗓',
    '0': '𝟢', '1': '𝟣', '2': '𝟤', '3': '𝟥', '4': '𝟦', '5': '𝟧', '6': '𝟨',
    '7': '𝟩', '8': '𝟪', '9': '𝟫',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => sansSerifChars[char] || char)
    .join('');
};
// Output: "𝖧𝖾𝗅𝗅𝗈, 𝗐𝗈𝗋𝗅𝖽!"
export const SansSerifBoldText = (text) => {
  const sansSerifBoldChars = {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => sansSerifBoldChars[char] || char)
    .join('');
};
// Output: "𝗛𝗲𝗹𝗹𝗼, 𝘄𝗼𝗿𝗹𝗱!"
export const SansSerifItalicText = (text) => {
  const sansSerifItalicChars = {
    'A': '𝘈', 'B': '𝘉', 'C': '𝘊', 'D': '𝘋', 'E': '𝘌', 'F': '𝘍', 'G': '𝘎',
    'H': '𝘏', 'I': '𝘐', 'J': '𝘑', 'K': '𝘒', 'L': '𝘓', 'M': '𝘔', 'N': '𝘕',
    'O': '𝘖', 'P': '𝘗', 'Q': '𝘘', 'R': '𝘙', 'S': '𝘚', 'T': '𝘛', 'U': '𝘜',
    'V': '𝘝', 'W': '𝘞', 'X': '𝘟', 'Y': '𝘠', 'Z': '𝘡',
    'a': '𝘢', 'b': '𝘣', 'c': '𝘤', 'd': '𝘥', 'e': '𝘦', 'f': '𝘧', 'g': '𝘨',
    'h': '𝘩', 'i': '𝘪', 'j': '𝘫', 'k': '𝘬', 'l': '𝘭', 'm': '𝘮', 'n': '𝘯',
    'o': '𝘰', 'p': '𝘱', 'q': '𝘲', 'r': '𝘳', 's': '𝘴', 't': '𝘵', 'u': '𝘶',
    'v': '𝘷', 'w': '𝘸', 'x': '𝘹', 'y': '𝘺', 'z': '𝘻',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => sansSerifItalicChars[char] || char)
    .join('');
};
// 𝘏𝘦𝘭𝘭𝘰, 𝘸𝘰𝘳𝘭𝘥! 𝟭𝟮𝟯
export const SansSerifBoldItalicText = (text) => {
  const sansSerifBoldItalicChars = {
    'A': '𝘼', 'B': '𝘽', 'C': '𝘾', 'D': '𝘿', 'E': '𝙀', 'F': '𝙁', 'G': '𝙂',
    'H': '𝙃', 'I': '𝙄', 'J': '𝙅', 'K': '𝙆', 'L': '𝙇', 'M': '𝙈', 'N': '𝙉',
    'O': '𝙊', 'P': '𝙋', 'Q': '𝙌', 'R': '𝙍', 'S': '𝙎', 'T': '𝙏', 'U': '𝙐',
    'V': '𝙑', 'W': '𝙒', 'X': '𝙓', 'Y': '𝙔', 'Z': '𝙕',
    'a': '𝙖', 'b': '𝙗', 'c': '𝙘', 'd': '𝙙', 'e': '𝙚', 'f': '𝙛', 'g': '𝙜',
    'h': '𝙝', 'i': '𝙞', 'j': '𝙟', 'k': '𝙠', 'l': '𝙡', 'm': '𝙢', 'n': '𝙣',
    'o': '𝙤', 'p': '𝙥', 'q': '𝙦', 'r': '𝙧', 's': '𝙨', 't': '𝙩', 'u': '𝙪',
    'v': '𝙫', 'w': '𝙬', 'x': '𝙭', 'y': '𝙮', 'z': '𝙯',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => sansSerifBoldItalicChars[char] || char)
    .join('');
};
// Output: "𝙃𝙚𝙡𝙡𝙤, 𝙬𝙤𝙧𝙡𝙙!"
//GothicText
export const FrakturText = (text) => {
  const frakturChars = {
    'A': '𝔄', 'B': '𝔅', 'C': 'ℭ', 'D': '𝔇', 'E': '𝔈', 'F': '𝔉', 'G': '𝔊',
    'H': 'ℌ', 'I': 'ℑ', 'J': '𝔍', 'K': '𝔎', 'L': '𝔏', 'M': '𝔐', 'N': '𝔑',
    'O': '𝔒', 'P': '𝔓', 'Q': '𝔔', 'R': 'ℜ', 'S': '𝔖', 'T': '𝔗', 'U': '𝔘',
    'V': '𝔙', 'W': '𝔚', 'X': '𝔛', 'Y': '𝔜', 'Z': 'ℨ',
    'a': '𝔞', 'b': '𝔟', 'c': '𝔠', 'd': '𝔡', 'e': '𝔢', 'f': '𝔣', 'g': '𝔤',
    'h': '𝔥', 'i': '𝔦', 'j': '𝔧', 'k': '𝔨', 'l': '𝔩', 'm': '𝔪', 'n': '𝔫',
    'o': '𝔬', 'p': '𝔭', 'q': '𝔮', 'r': '𝔯', 's': '𝔰', 't': '𝔱', 'u': '𝔲',
    'v': '𝔳', 'w': '𝔴', 'x': '𝔵', 'y': '𝔶', 'z': '𝔷',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => frakturChars[char] || char)
    .join('');
};
// Output: "ℌ𝔢𝔩𝔩𝔬, 𝔴𝔬𝔯𝔩𝔡!"
export const BoldFrakturText = (text) => {
  const boldFrakturChars = {
    'A': '𝕬', 'B': '𝕭', 'C': '𝕮', 'D': '𝕯', 'E': '𝕰', 'F': '𝕱', 'G': '𝕲',
    'H': '𝕳', 'I': '𝕴', 'J': '𝕵', 'K': '𝕶', 'L': '𝕷', 'M': '𝕸', 'N': '𝕹',
    'O': '𝕺', 'P': '𝕻', 'Q': '𝕼', 'R': '𝕽', 'S': '𝕾', 'T': '𝕿', 'U': '𝖀',
    'V': '𝖁', 'W': '𝖂', 'X': '𝖃', 'Y': '𝖄', 'Z': '𝖅',
    'a': '𝖆', 'b': '𝖇', 'c': '𝖈', 'd': '𝖉', 'e': '𝖊', 'f': '𝖋', 'g': '𝖌',
    'h': '𝖍', 'i': '𝖎', 'j': '𝖏', 'k': '𝖐', 'l': '𝖑', 'm': '𝖒', 'n': '𝖓',
    'o': '𝖔', 'p': '𝖕', 'q': '𝖖', 'r': '𝖗', 's': '𝖘', 't': '𝖙', 'u': '𝖚',
    'v': '𝖛', 'w': '𝖜', 'x': '𝖝', 'y': '𝖞', 'z': '𝖟',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => boldFrakturChars[char] || char)
    .join('');
};
// Output: "𝕳𝖊𝖑𝖑𝖔, 𝖜𝖔𝖗𝖑𝖉!"
export const ScriptText = (text) => {
  const scriptChars = {
    'A': '𝒜', 'B': 'ℬ', 'C': '𝒞', 'D': '𝒟', 'E': 'ℰ', 'F': 'ℱ', 'G': '𝒢',
    'H': 'ℋ', 'I': 'ℐ', 'J': '𝒥', 'K': '𝒦', 'L': 'ℒ', 'M': 'ℳ', 'N': '𝒩',
    'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': 'ℛ', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰',
    'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': 'ℯ', 'f': '𝒻', 'g': 'ℊ',
    'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃',
    'o': 'ℴ', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊',
    'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => scriptChars[char] || char)
    .join('');
};
// Output: "ℋℯ𝓁𝓁ℴ, 𝓌ℴ𝓇𝓁𝒹!"
export const BoldScriptText = (text) => {
  const boldScriptChars = {
    'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔', 'F': '𝓕', 'G': '𝓖',
    'H': '𝓗', 'I': '𝓘', 'J': '𝓙', 'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝',
    'O': '𝓞', 'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣', 'U': '𝓤',
    'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
    'a': '𝓪', 'b': '𝓫', 'c': '𝓬', 'd': '𝓭', 'e': '𝓮', 'f': '𝓯', 'g': '𝓰',
    'h': '𝓱', 'i': '𝓲', 'j': '𝓳', 'k': '𝓴', 'l': '𝓵', 'm': '𝓶', 'n': '𝓷',
    'o': '𝓸', 'p': '𝓹', 'q': '𝓺', 'r': '𝓻', 's': '𝓼', 't': '𝓽', 'u': '𝓾',
    'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃',
    '0': '𝟘', '1': '𝟙', '2': '𝟚', '3': '𝟛', '4': '𝟜', '5': '𝟝', '6': '𝟞',
    '7': '𝟟', '8': '𝟠', '9': '𝟡',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => boldScriptChars[char] || char)
    .join('');
}
// Output: "𝓗𝓮𝓵𝓵𝓸, 𝔀𝓸𝓻𝓵𝓭!"
export const CircledText = (text) => {
  const circledChars = {
    'A': 'Ⓐ', 'B': 'Ⓑ', 'C': 'Ⓒ', 'D': 'Ⓓ', 'E': 'Ⓔ', 'F': 'Ⓕ', 'G': 'Ⓖ',
    'H': 'Ⓗ', 'I': 'Ⓘ', 'J': 'Ⓙ', 'K': 'Ⓚ', 'L': 'Ⓛ', 'M': 'Ⓜ', 'N': 'Ⓝ',
    'O': 'Ⓞ', 'P': 'Ⓟ', 'Q': 'Ⓠ', 'R': 'Ⓡ', 'S': 'Ⓢ', 'T': 'Ⓣ', 'U': 'Ⓤ',
    'V': 'Ⓥ', 'W': 'Ⓦ', 'X': 'Ⓧ', 'Y': 'Ⓨ', 'Z': 'Ⓩ',
    'a': 'ⓐ', 'b': 'ⓑ', 'c': 'ⓒ', 'd': 'ⓓ', 'e': 'ⓔ', 'f': 'ⓕ', 'g': 'ⓖ',
    'h': 'ⓗ', 'i': 'ⓘ', 'j': 'ⓙ', 'k': 'ⓚ', 'l': 'ⓛ', 'm': 'ⓜ', 'n': 'ⓝ',
    'o': 'ⓞ', 'p': 'ⓟ', 'q': 'ⓠ', 'r': 'ⓡ', 's': 'ⓢ', 't': 'ⓣ', 'u': 'ⓤ',
    'v': 'ⓥ', 'w': 'ⓦ', 'x': 'ⓧ', 'y': 'ⓨ', 'z': 'ⓩ',
    '0': '⓪', '1': '①', '2': '②', '3': '③', '4': '④', '5': '⑤', '6': '⑥',
    '7': '⑦', '8': '⑧', '9': '⑨',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => circledChars[char.toUpperCase()] || char)
    .join('');
};
//Cursor Text:
//Output: "ⓗⓔⓛⓛⓞ, ⓦⓞⓡⓛⓓⓞ!"
// Output: "ⒽⒺⓁⓁⓄ, ⓌⓄⓇⓁⒹⓄ!"
export const CircledTextBlack = (text) => {
  const circledChars = {
    'A': '🅐', 'B': '🅑', 'C': '🅒', 'D': '🅓', 'E': '🅔', 'F': '🅕', 'G': '🅖',
    'H': '🅗', 'I': '🅘', 'J': '🅙', 'K': '🅚', 'L': '🅛', 'M': '🅜', 'N': '🅝',
    'O': '🅞', 'P': '🅟', 'Q': '🅠', 'R': '🅡', 'S': '🅢', 'T': '🅣', 'U': '🅤',
    'V': '🅥', 'W': '🅦', 'X': '🅧', 'Y': '🅨', 'Z': '🅩',
    'a': '🅐', 'b': '🅑', 'c': '🅒', 'd': '🅓', 'e': '🅔', 'f': '🅕', 'g': '🅖',
    'h': '🅗', 'i': '🅘', 'j': '🅙', 'k': '🅚', 'l': '🅛', 'm': '🅜', 'n': '🅝',
    'o': '🅞', 'p': '🅟', 'q': '🅠', 'r': '🅡', 's': '🅢', 't': '🅣', 'u': '🅤',
    'v': '🅥', 'w': '🅦', 'x': '🅧', 'y': '🅨', 'z': '🅩',
    '0': '⓿', '1': '➊', '2': '➋', '3': '➌', '4': '➍', '5': '➎', '6': '➏',
    '7': '➐', '8': '➑', '9': '➒',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => circledChars[char] || char)
    .join('');
};
//Cursor Text:
// Output: "🅗🅔🅛🅛🅞, 🅦🅞🅡🅛🅓!"

export const SquaredText = (text) => {
  const squaredChars = {
    'A': '🄰', 'B': '🄱', 'C': '🄲', 'D': '🄳', 'E': '🄴', 'F': '🄵', 'G': '🄶',
    'H': '🄷', 'I': '🄸', 'J': '🄹', 'K': '🄺', 'L': '🄻', 'M': '🄼', 'N': '🄽',
    'O': '🄾', 'P': '🄿', 'Q': '🅀', 'R': '🅁', 'S': '🅂', 'T': '🅃', 'U': '🅄',
    'V': '🅅', 'W': '🅆', 'X': '🅇', 'Y': '🅈', 'Z': '🅉',
    'a': '🄰', 'b': '🄱', 'c': '🄲', 'd': '🄳', 'e': '🄴', 'f': '🄵', 'g': '🄶',
    'h': '🄷', 'i': '🄸', 'j': '🄹', 'k': '🄺', 'l': '🄻', 'm': '🄼', 'n': '🄽',
    'o': '🄾', 'p': '🄿', 'q': '🅀', 'r': '🅁', 's': '🅂', 't': '🅃', 'u': '🅄',
    'v': '🅅', 'w': '🅆', 'x': '🅇', 'y': '🅈', 'z': '🅉'

  };
  return text
    .split('')
    .map((char) => squaredChars[char] || char)
    .join('');
};
//Cursor Text:
// Output: "🄷🄴🄻🄻🄾, 🅆🄾🅁🄻🄳!" 
export const NegativeSquaredText = (text) => {
  const negativesquaredChars = {
    'A': '🅰', 'B': '🅱', 'C': '🅲', 'D': '🅳', 'E': '🅴', 'F': '🅵', 'G': '🅶',
    'H': '🅷', 'I': '🅸', 'J': '🅹', 'K': '🅺', 'L': '🅻', 'M': '🅼', 'N': '🅽',
    'O': '🅾', 'P': '🅿', 'Q': '🆀', 'R': '🆁', 'S': '🆂', 'T': '🆃', 'U': '🆄',
    'V': '🆅', 'W': '🆆', 'X': '🆇', 'Y': '🆈', 'Z': '🆉',
    'a': '🅰', 'b': '🅱', 'c': '🅲', 'd': '🅳', 'e': '🅴', 'f': '🅵', 'g': '🅶',
    'h': '🅷', 'i': '🅸', 'j': '🅹', 'k': '🅺', 'l': '🅻', 'm': '🅼', 'n': '🅽',
    'o': '🅾', 'p': '🅿', 'q': '🆀', 'r': '🆁', 's': '🆂', 't': '🆃', 'u': '🆄',
    'v': '🆅', 'w': '🆆', 'x': '🆇', 'y': '🆈', 'z': '🆉',
    '0': '0️⃣', '1': '1️⃣', '2': '2️⃣', '3': '3️⃣', '4': '4️⃣', '5': '5️⃣', '6': '6️⃣',
    '7': '7️⃣', '8': '8️⃣', '9': '9️⃣'
  };

  return text
    .split('')
    .map((char) => negativesquaredChars[char] || char)
    .join('');
};
//Cursor Text:
//  🅷🅴🅻🅻🅾 🆆🅾🆁🅻🅳

export const RegionalIndicatorText = (text) => {
  const regionalIndicatorChars = {
    'A': '🇦', 'B': '🇧', 'C': '🇨', 'D': '🇩', 'E': '🇪', 'F': '🇫', 'G': '🇬',
    'H': '🇭', 'I': '🇮', 'J': '🇯', 'K': '🇰', 'L': '🇱', 'M': '🇲', 'N': '🇳',
    'O': '🇴', 'P': '🇵', 'Q': '🇶', 'R': '🇷', 'S': '🇸', 'T': '🇹', 'U': '🇺',
    'V': '🇻', 'W': '🇼', 'X': '🇽', 'Y': '🇾', 'Z': '🇿',
    'a': '🇦', 'b': '🇧', 'c': '🇨', 'd': '🇩', 'e': '🇪', 'f': '🇫', 'g': '🇬',
    'h': '🇭', 'i': '🇮', 'j': '🇯', 'k': '🇰', 'l': '🇱', 'm': '🇲', 'n': '🇳',
    'o': '🇴', 'p': '🇵', 'q': '🇶', 'r': '🇷', 's': '🇸', 't': '🇹', 'u': '🇺',
    'v': '🇻', 'w': '🇼', 'x': '🇽', 'y': '🇾', 'z': '🇿',
    '0': '0️⃣', '1': '1️⃣', '2': '2️⃣', '3': '3️⃣', '4': '4️⃣', '5': '5️⃣', '6': '6️⃣',
    '7': '7️⃣', '8': '8️⃣', '9': '9️⃣',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => regionalIndicatorChars[char] || char)
    .join('');
};
export const ZalgoText = (text) => {
  const zalgoChars = [
    '\u0300', '\u0301', '\u0302', '\u0303', '\u0304', '\u0305', '\u0306', '\u0307',
    '\u0308', '\u0309', '\u030A', '\u030B', '\u030C', '\u030D', '\u030E', '\u030F',
    '\u0310', '\u0311', '\u0312', '\u0313', '\u0314', '\u0315', '\u0316', '\u0317',
    '\u0318', '\u0319', '\u031A', '\u031B', '\u031C', '\u031D', '\u031E', '\u031F',
    '\u0320', '\u0321', '\u0322', '\u0323', '\u0324', '\u0325', '\u0326', '\u0327',
    '\u0328', '\u0329', '\u032A', '\u032B', '\u032C', '\u032D', '\u032E', '\u032F',
    '\u0330', '\u0331', '\u0332', '\u0333', '\u0334', '\u0335', '\u0336', '\u0337',
    '\u0338', '\u0339', '\u033A', '\u033B', '\u033C', '\u033D', '\u033E', '\u033F',
    '\u0340', '\u0341', '\u0342', '\u0343', '\u0344', '\u0345', '\u0346', '\u0347',
    '\u0348', '\u0349', '\u034A', '\u034B', '\u034C', '\u034D', '\u034E', '\u034F',
    '\u0350', '\u0351', '\u0352', '\u0353', '\u0354', '\u0355', '\u0356', '\u0357',
    '\u0358', '\u0359', '\u035A', '\u035B', '\u035C', '\u035D', '\u035E', '\u035F'
  ];

  return text
    .split('')
    .map((char) => {
      const randomZalgo = zalgoChars[Math.floor(Math.random() * zalgoChars.length)];
      return char + randomZalgo;
    })
    .join('');
};

