import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { StyledTextareaAutosize, StyledClearButton, StyledButtonIcon, StyledCopyButton, DivInnerTextArea, Icon } from '../TagsStyle';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copyTextToClipboard from '../../../hooks/copyTextToClipboard';

const TextInnerDivHorizontal = ({
    showClearButton,
    handleClearClick,
    handleInputChange,
    inputText,
    error,
    showCopyButton,
    outputText,
    outputTextareaRef,
    initialOutputText,
    handleDownloadClick,
    handleCopyClick,
    isCopied,
    individualCopiedIndex, // Receive individualCopiedIndex as a prop
    setIndividualCopiedIndex, // Receive the setter function as a prop
    resetIsCopied, // for Reset Copy Button Main
}) => {

    const isOutputTextEmpty = outputText.length === 0 || outputText.every((text) => text === '');

    // New function to handle individual copy for function
    const handleIndividualCopyClick = (index) => {
        const textToCopy = outputText[index];
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = textToCopy;
        tempTextArea.style.position = 'fixed'; // Ensures it doesn't affect page layout
        tempTextArea.style.opacity = 0; // Make it invisible
        tempTextArea.style.width = '1px'; // Set width to ensure it's focusable
        tempTextArea.style.height = '1px'; // Set height to ensure it's focusable
        document.body.appendChild(tempTextArea);
        tempTextArea.focus(); // Focus to select content
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);
        setIndividualCopiedIndex(index);
        resetIsCopied();
    };

    const handleInitialTextCopyClick = (index) => {
        const textToCopy = initialOutputText[index];
        copyTextToClipboard(textToCopy);
        setIndividualCopiedIndex(index);
        resetIsCopied();
    };

    return (
        <>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {showClearButton && (
                            <StyledClearButton className="clear-button" onClick={handleClearClick} endIcon={<ClearIcon />} size="small">
                                Clear
                            </StyledClearButton>
                        )}
                        <StyledTextareaAutosize
                            className="input-textarea text-input"
                            placeholder="Start typing, or copy and paste your document here..."
                            value={inputText}
                            maxLength={30000}
                            onChange={handleInputChange}
                        />
                        {error && <p className="error-message">{error}</p>}
                        {/* The input text will appear in real-time in the output textarea */}
                    </Box>
                </Grid>
            </Grid>
            <Grid container className="buttons-case row" style={{ paddingBottom: '10px' }} spacing={{ xs: 0.5, sm: 1, md: 2, lg: 3 }}>
                {/* Place any additional buttons or elements here if needed */}
            </Grid>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {(showCopyButton || !isOutputTextEmpty) && (
                            <Box>
                                <StyledButtonIcon className="download-button" variant="contained" size="small" onClick={handleDownloadClick}>
                                    <FileDownloadIcon />
                                </StyledButtonIcon>
                                <StyledCopyButton
                                    className={`copy-button ${isCopied && !isOutputTextEmpty ? 'copied' : ''}`}
                                    onClick={handleCopyClick}
                                    endIcon={<CopyAllIcon />}
                                    size="small"
                                >
                                    {isCopied && !isOutputTextEmpty ? 'Copied' : 'Copy'}
                                </StyledCopyButton>
                            </Box>
                        )}
                        <DivInnerTextArea className="output-textarea text-input" id="copied" ref={outputTextareaRef}>
                            {outputText.map((allFunctionText, index) => (
                                <div key={index}>
                                    <Box className='copy-content'>
                                        {allFunctionText}
                                        <Icon aria-label="copy-contents" size="small" onClick={() => handleIndividualCopyClick(index)}>
                                            {individualCopiedIndex === index ? (
                                                <span className="Copied">Copied</span>
                                            ) : (
                                                <ContentCopyIcon className='copy-inner' fontSize="inherit" />
                                            )}

                                        </Icon>
                                    </Box>
                                </div>
                            ))}
                            {isOutputTextEmpty &&
                                initialOutputText.map((text, index) => (
                                    <div key={index}>
                                        <Box className='copy-content'>
                                            {text}
                                            <Icon aria-label="copy-contents" size="small"
                                                onClick={() => handleInitialTextCopyClick(index)}
                                            >
                                                {individualCopiedIndex === index ? (
                                                    <span className="Copied">Copied</span>
                                                ) : (
                                                    <ContentCopyIcon className='copy-inner' fontSize="inherit" />
                                                )}

                                            </Icon>
                                        </Box>
                                    </div>
                                ))}
                        </DivInnerTextArea>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TextInnerDivHorizontal;
