import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import TextTextAreaSingleAuto from '../../common/Layouts/TextTextAreaSingleAuto';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import ReplaceInputs from '../../common/Layouts/TextReplaceInputs';
import { TextReplacerfn } from '../../features/StringManipulation/TextManipuation'
// for seo
import SEOComponent from '../../common/SEOComponent';

const TextReplacer = () => {
    // State variables
    const [isCopied, setIsCopied] = useState(false);
    const [outputText, setOutputText] = useState('');
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    // All functions and Define the initial text
    const Placeholderoutput = "Start Typing or paste your text..";
    const [initialOutputText, setInitialOutputText] = useState(Placeholderoutput);
    //for replace
    const [find, setFind] = useState('');
    const [replace, setReplace] = useState('');
    const [caseSensitive, setCaseSensitive] = useState(false);

    // Automatically scroll the output textarea to the bottom when outputText changes
    useEffect(() => {
        const outputTextarea = outputTextareaRef.current;
        if (outputTextarea) {
            outputTextarea.scrollTop = outputTextarea.scrollHeight;
        }
    }, [outputText]);

    //Handle output text changes function
    const handleOutputChange = (event) => {
        const text = event.target.value;
        setOutputText(text);
        setIsCopied(false);
    };

    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        const outputTextarea = outputTextareaRef.current;
        if (outputDiv) {
            const outputText = outputDiv.textContent.trim(); // Remove leading/trailing spaces and newlines
            if (outputText) {
                const textArea = document.createElement('textarea');
                textArea.value = outputText;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                setIsCopied(true);
                outputTextarea.select();
            }
        }
    };

    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string
        const fileContent = outputText.replace(/,/g, '');

        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'TextReplace.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);

        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    //replace function
    const handleFind = (event) => {
        setFind(event.target.value);
    };

    const handleReplace = (event) => {
        setReplace(event.target.value);
    };

    const handleCaseSensitiveChange = (event) => {
        setCaseSensitive(event.target.checked);
    };

    const handleReplaceSubmit = (find, replace, caseSensitive) => {
        const replacedText = TextReplacerfn(outputText, find, replace, caseSensitive);
        setOutputText(replacedText);
    };


    // Calculate character count
    const characterCount = outputText.length;
    // Calculate word count
    const wordCount = outputText.trim().length > 0 ? outputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = outputText.length > 0 ? outputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Simplify Text Replacement with Our Online Tool</h1>
                        <p>Find and replace text effortlessly using our online text replacer. Save time and edit your documents with ease.</p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}

            <ReplaceInputs
                handleFind={handleFind}
                handleReplace={handleReplace}
                find={find}
                replace={replace}
                caseSensitive={caseSensitive}
                handleReplaceSubmit={handleReplaceSubmit}
                handleCaseSensitiveChange={handleCaseSensitiveChange}

            />

            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            <TextTextAreaSingleAuto
                error={error}
                showCopyButton={showCopyButton}
                outputText={outputText}
                initialOutputText={outputText.length === 0 ? initialOutputText : ""}
                handleDownloadClick={handleDownloadClick}
                handleCopyClick={handleCopyClick}
                isCopied={isCopied}
                outputTextareaRef={outputTextareaRef}
                handleOutputChange={handleOutputChange}
            />
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Text Replacer - Simplify Text Replacement Effortlessly</h2>
                        <p>Editing and replacing text in your documents just got easier with our Online Text Replacer. Whether you're looking to find and replace specific words or phrases or need to make widespread changes, our tool streamlines the process, saving you valuable time and effort.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default TextReplacer;