import React, { useState } from 'react';
import { Grid } from '@mui/material';

const InputPage = ({
    handleRepetitionsInputChange,
    handleSeparatorInputChange,
    repetitions,
    separator,
}) => {

    const labelStyle = {
        padding: '5px',
        paddingLeft: '18px',
        marginTop: '5px',
    };

    return (
        <Grid container className="row-min" spacing={2}>

            <div style={labelStyle}>
                <label htmlFor="repetitions">Times to Repeat:&nbsp;&nbsp;</label>
                <input
                    id="repetitions"
                    type="number"
                    min="1"
                    value={repetitions}
                    max="1000"
                    className="text-input"
                    onChange={handleRepetitionsInputChange}
                    style={{ padding: '5px' }}
                />
            </div>
            &nbsp;&nbsp;
            <div style={labelStyle}>
                <label htmlFor="separator">Separator:&nbsp;&nbsp;</label>
                <input
                    id="separator"
                    type="text"
                    value={separator}
                    className="text-input"
                    onChange={handleSeparatorInputChange}
                    style={{ padding: '5px' }}
                />
            </div>

        </Grid>
    );
}

export default InputPage;
