import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import TextCaseConvert from '../../common/Layouts/TextCaseConvert'; // Make sure to adjust the path
import {
  convertToSentenceCase,
  convertToLowercase,
  convertToUppercase,
  convertToCapitalizedCase,
  convertToAlternatingCase,
  convertToTitleCase,
  convertToInverseCase,
} from '../../features/StringManipulation/TextToCaseConvert.js';

// for seo
import SEOComponent from '../../common/SEOComponent';

const CaseConverter = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  // For Clear button show hide over autotextarea
  const [showButton, setShowButton] = useState(false);
  // For copy button show hide over autotextarea
  const [showCopyButton, setShowCopyButton] = useState(false);
  //error
  const [error, setError] = useState(null);

  const handleClearClick = () => {
    setInputText('');
    setOutputText('');
    setIsCopied(false);
    setShowButton(false);
    setError(null);
  };

  const handleButtonClick = async (buttonValue) => {
    setIsLoading(true);
    if (!inputText) {
      setError('Input text is required');
      return;
    } else {
      try {
        let convertedText = '';
        switch (buttonValue) {
          case 'sentence_case':
            convertedText = convertToSentenceCase(inputText);
            break;
          case 'lower_case':
            convertedText = convertToLowercase(inputText);
            break;
          case 'upper_case':
            convertedText = convertToUppercase(inputText);
            break;
          case 'capitalized_case':
            convertedText = convertToCapitalizedCase(inputText);
            break;
          case 'alternating_case':
            convertedText = convertToAlternatingCase(inputText);
            break;
          case 'title_case':
            convertedText = convertToTitleCase(inputText);
            break;
          case 'inverse_case':
            convertedText = convertToInverseCase(inputText);
            break;
          default:
            break;
        }
        setOutputText(convertedText);
        setIsCopied(false); // Reset the copied state when a new response is received
      } catch (error) {
        setOutputText('Error: Failed to fetch data from API');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCopyClick = () => {
    const outputTextArea = document.getElementById('copied');
    if (outputTextArea) {
      outputTextArea.select();
      document.execCommand('copy');
      setIsCopied(true);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputText(inputValue);
    setIsCopied(false);

    if (inputValue.length >= 30000) {
      setError('Input text should not exceed 30000 characters');
    } else {
      setError(null);
    }

    setShowButton(inputValue.trim().length > 0);
  };

  const handleOutputChange = (event) => {
    const OutputValue = event.target.value;
    setOutputText(OutputValue); // Update outputText state variable
    setShowCopyButton(OutputValue !== '' && OutputValue !== null);
  };
  //Dowload the file .txt
  const handleDownloadClick = () => {
    const element = document.createElement("a");
    const file = new Blob([outputText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "CaseTexts.txt";
    document.body.appendChild(element); // Required for Firefox
    element.click();
    document.body.removeChild(element); // Cleanup
    setIsCopied(false); // Reset the copied state
  };

  // Calculate character count
  const characterCount = inputText.length;
  // Calculate word count
  const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
  // Calculate line count
  const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

  return (
    <div className="main">
      {/* For Seo on page Head information */}
      <SEOComponent />
      {/* Google Ads Top --Start */}
      <AdsComponent adConfigurations={adTop} />
      {/* Google Ads Top --End */}
      {/* Page text Information  */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <h1>Text Transformation Simplified: Explore Our Case Converter Tools</h1>
            <p>
              From converting to title case to alternating case, our online case converter offers a variety of options to transform your text. Say goodbye to manual case changes!
            </p>
          </Box>
        </Grid>
      </Grid>
      {/* Google Ads Middle-1 --Start */}
      <AdsComponent adConfigurations={adMiddle1} />
      {/* Google Ads Middle-1 --End */}

      {/* Word Counter */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box className="count-section">
            <h3>
              <span>{characterCount} Characters</span>
              <span> {wordCount} Words </span>
              <span>{lineCount} Lines</span>
            </h3>
          </Box>
        </Grid>
      </Grid>

      <TextCaseConvert
        showClearButton={showButton}
        handleClearClick={handleClearClick}
        handleInputChange={handleInputChange}
        inputText={inputText}
        error={error}
        showCopyButton={showCopyButton}
        outputText={outputText}
        isCopied={isCopied}
        // ... other props
        handleButtonClick={handleButtonClick} // Pass handleButtonClick as a prop
        handleCopyClick={handleCopyClick} // Pass handleButtonClick as a prop
        handleOutputChange={handleOutputChange} // Pass handleButtonClick as a prop
        handleDownloadClick={handleDownloadClick}
      />

      {/* Google Ads Middle-2 --Start */}
      <AdsComponent adConfigurations={adMiddle2} />
      {/* Google Ads Middle-2 --End */}
      {/* Similar Links */}
      <SimilarLinkComponent />
      {/* Google Ads bottom --Start */}
      <AdsComponent adConfigurations={adBottom} />
      {/* Google Ads bottom --End */}
      {/* Page Information */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <h2>Case Converter - Transform Text Styles Effortlessly</h2>
            <p>Transforming text has never been easier! Dive into the world of effortless text transformation with our Case Converter tools. Whether you need title case, sentence case, uppercase, lowercase, or even alternating case, our online case converter has you covered. Bid farewell to the tedious task of manual case changes and embrace the simplicity of our text transformation tools.</p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CaseConverter;
