// TextToCaseConvert.js

export const convertToSentenceCase = (inputString) => {
    return inputString.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, (c) => c.toUpperCase());
};

export const convertToLowercase = (inputString) => {
    return inputString.toLowerCase();
};

export const convertToUppercase = (inputString) => {
    return inputString.toUpperCase();
};

export const convertToCapitalizedCase = (inputString) => {
    return inputString.toLowerCase().replace(/(^|\s)(\w)/g, (c) => c.toUpperCase());
};

export const convertToAlternatingCase = (inputString) => {
    let result = '';
    for (let i = 0; i < inputString.length; i++) {
        result += i % 2 === 0 ? inputString[i].toLowerCase() : inputString[i].toUpperCase();
    }
    return result;
};

export const convertToTitleCase = (inputString) => {
    return inputString.toLowerCase().replace(/(^|\s)\w/g, (c) => c.toUpperCase());
};

export const convertToInverseCase = (inputString) => {
    let result = '';
    for (let i = 0; i < inputString.length; i++) {
        const char = inputString[i];
        result += char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase();
    }
    return result;
};
