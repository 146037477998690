import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Grid, Box } from '@mui/material';
import TextInnerDivHorizontal from '../../common/Layouts/TextInnerDivHorizontal';
import TextInnerDivVertical from '../../common/Layouts/TextInnerDivVertical';
import SwapButtonComponent from '../../common/Layouts/SwapButtonComponent';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
import {
    DigitsBoldNew, DigitsDoubleStruck, DigitsSansSerif, DigitsSansSerifBold,
    DigitsMonoSpace, DigitDevanagari, DigitsRoman, DigitsBold,
    FullwidthDigits, SubscriptDigits, SuperscriptDigits, plainToUnicodeEthiopic,
    plainToUnicodeArabic, plainToUnicodeDigits, NumericEmojiText, DigitsStrikethrough,
    DigitsUnderline, CircledNumbers, DubbleCircles, AddDecoration33, AddDecoration34,
    DigitsSquareBrackets, DigitsFullWidthPeriod, DigitsRegularPeriod
} from '../../features/StringManipulation/TextToDigits';

// for seo
import SEOComponent from '../../common/SEOComponent';

const DigitsStyle = () => {
    // Use the useMediaQuery hook to check if the screen width is above or equal to "md"
    const isScreenAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // State variables
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [showClearButton, setShowClearButton] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [error, setError] = useState(null);
    const outputTextareaRef = useRef(null);
    const [isHorizontalLayout, setIsHorizontalLayout] = useState(false);
    const [individualCopiedIndex, setIndividualCopiedIndex] = useState(null);

    // All functions and Define the initial texts 
    const initialTexts = [
        "𝟷𝟸𝟹𝟺𝟻𝟼𝟽𝟾𝟿𝟶",
        "१२३४५६७८९०", "ⅰⅱⅲⅳⅴⅵⅶⅷⅸ0", "1️⃣2️⃣3️⃣4️⃣5️⃣6️⃣7️⃣8️⃣9️⃣0", "⓵⓶⓷⓸⓹⓺⓻⓼⓽⓪", "𝟙𝟚𝟛𝟜𝟝𝟞𝟟𝟠𝟡𝟘",
        "1̶2̶3̶4̶5̶6̶7̶8̶9̶0̶", "１２３４５６７８９０", "₁₂₃₄₅₆₇₈₉₀", "¹²³⁴⁵⁶⁷⁸⁹⁰",
        "፪፫፬፭፮፯፰፱፲፩", "١٢٣٤٥٦٧٨٩٠", "১২৩৪৫৬৭৮৯০",
        "㊁㊂㊃㊄㊅㊆㊇㊈㊉㊀",
        "₹ 1234567890", "₨ 1234567890", "𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵𝟬", "1̲2̲3̲4̲5̲6̲7̲8̲9̲0̲", "𝟏𝟐𝟑𝟒𝟓𝟔𝟕𝟖𝟗𝟎",
        "𝟣𝟤𝟥𝟦𝟧𝟨𝟩𝟪𝟫𝟢", "𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵𝟬",
        "【1】【2】【3】【4】【5】【6】【7】【8】【9】【0】", "1．2．3．4．5．6．7．8．9．0．",
        "1.2.3.4.5.6.7.8.9.0.",
    ];
    const [initialOutputText, setInitialOutputText] = useState(initialTexts);
    const TextConversion = (inputText) => {
        const AllFunctionValues = [

            DigitsMonoSpace(inputText),
            DigitDevanagari(inputText),
            DigitsRoman(inputText),
            NumericEmojiText(inputText),
            DubbleCircles(inputText),
            DigitsDoubleStruck(inputText),
            DigitsStrikethrough(inputText),
            FullwidthDigits(inputText),
            SubscriptDigits(inputText),
            SuperscriptDigits(inputText),
            plainToUnicodeEthiopic(inputText),
            plainToUnicodeArabic(inputText),
            plainToUnicodeDigits(inputText),
            CircledNumbers(inputText),
            AddDecoration33(inputText),
            AddDecoration34(inputText),
            DigitsBold(inputText),
            DigitsUnderline(inputText),
            DigitsBoldNew(inputText),
            DigitsSansSerif(inputText),
            DigitsSansSerifBold(inputText),
            DigitsSquareBrackets(inputText),
            DigitsFullWidthPeriod(inputText),
            DigitsRegularPeriod(inputText),

        ];
        // Return the output
        return AllFunctionValues;
    };

    // // Automatically scroll the output textarea to the bottom when outputText changes
    // useEffect(() => {
    //     const outputTextarea = outputTextareaRef.current;
    //     if (outputTextarea) {
    //         outputTextarea.scrollTop = outputTextarea.scrollHeight;
    //     }
    // }, [outputText]);

    // Handle swap button click to change layout
    const handleSwapClick = () => {
        setIsHorizontalLayout((prev) => !prev);
    };
    // Handle input text change and update output text
    const handleInputChange = (event) => {
        const text = event.target.value;
        setInputText(text);
        if (text.length >= 30000) {
            setError('Maximum character limit (30000) reached.');
        } else {
            setError(null);
        }
        // Function Call
        const AllFunctionValues = TextConversion(text);
        // Check if the inputText is empty or null and set outputText accordingly
        setOutputText(text.trim() === null || text.trim() === '' ? [] : AllFunctionValues);
        // Function Call
        setShowClearButton(text.length > 0);
        setIsCopied(false); // Reset the copied state when input text changes
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle clear button click
    const handleClearClick = () => {
        setInputText('');
        setOutputText([]);
        setIsCopied(false);
        setShowClearButton(false);
        setError(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
        setIndividualCopiedIndex(null); // Reset individualCopiedIndex when input text changes
    };
    // Handle copy button click
    const handleCopyClick = () => {
        const outputDiv = document.getElementById('copied');
        if (outputDiv) {
            const range = document.createRange();
            range.selectNode(outputDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            setIsCopied(true);
            setIndividualCopiedIndex(null);
        }
    };
    // Handle download button click
    const handleDownloadClick = () => {
        // Replace all commas with empty string and join with line breaks
        const fileContent = outputText.map(text => text.replace(/,/g, '')).join('\n\n');
        const element = document.createElement('a');
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'FacebookFont.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setIsCopied(false);
        setIndividualCopiedIndex(null);
        // Clear the selection after clicking the clear button
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };
    const resetIsCopied = () => {
        setIsCopied(false);
    };

    // Calculate character count
    const characterCount = inputText.length;
    // Calculate word count
    const wordCount = inputText.trim().length > 0 ? inputText.trim().split(/\s+/).length : 0;
    // Calculate line count
    const lineCount = inputText.length > 0 ? inputText.split('\n').length : 0;

    return (
        <div className="main">
            {/* For Seo on page Head information */}
            <SEOComponent />
            {/* Google Ads Top --Start */}
            <AdsComponent adConfigurations={adTop} />
            {/* Google Ads Top --End */}
            {/* Page text Information  */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h1>Enhance Numerical Display with Cool Number Fonts and Conversions</h1>
                        <p>From Roman numerals to Hindi counting, our tools provide diverse number font styles and conversions to add a unique touch to your documents and designs.</p>
                        <p>
                            Try Enhance readability by easily changing the layout. Just click the <b>Swap Icon</b>.
                        </p>
                    </Box>
                </Grid>
            </Grid>
            {/* Google Ads Middle-1 --Start */}
            <AdsComponent adConfigurations={adMiddle1} />
            {/* Google Ads Middle-1 --End */}
            {/* Swap Layout */}
            {isScreenAboveMd && (
                <SwapButtonComponent handleSwapClick={handleSwapClick} isHorizontalLayout={isHorizontalLayout} />
            )}
            {/* Word Counter */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box className="count-section">
                        <h3>
                            <span>{characterCount} Characters</span>
                            <span> {wordCount} Words </span>
                            <span>{lineCount} Lines</span>
                        </h3>
                    </Box>
                </Grid>
            </Grid>
            {/* Swap Layouts */}
            {isHorizontalLayout || !isScreenAboveMd ? (
                // Main Horizontal layout
                <TextInnerDivHorizontal
                    showClearButton={showClearButton}
                    handleClearClick={handleClearClick}
                    handleInputChange={handleInputChange}
                    inputText={inputText}
                    error={error}
                    showCopyButton={showCopyButton}
                    outputText={outputText}
                    initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                    handleDownloadClick={handleDownloadClick}
                    handleCopyClick={handleCopyClick}
                    isCopied={isCopied}
                    outputTextareaRef={outputTextareaRef}
                    setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivHorizontal
                    individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivHorizontal
                    resetIsCopied={resetIsCopied}
                />
            ) : (
                // Vertical layout and Only for desktop screens
                isScreenAboveMd && (
                    <TextInnerDivVertical
                        showClearButton={showClearButton}
                        handleClearClick={handleClearClick}
                        handleInputChange={handleInputChange}
                        inputText={inputText}
                        error={error}
                        showCopyButton={showCopyButton}
                        outputText={outputText}
                        initialOutputText={outputText.length === 0 ? initialOutputText : []} // Only show initialTexts when outputText is empty
                        handleDownloadClick={handleDownloadClick}
                        handleCopyClick={handleCopyClick}
                        isCopied={isCopied}
                        outputTextareaRef={outputTextareaRef}
                        setIndividualCopiedIndex={setIndividualCopiedIndex} // Pass the prop to LayoutTextInnerDivVertical
                        individualCopiedIndex={individualCopiedIndex} // Pass the individualCopiedIndex state to LayoutTextInnerDivVertical
                        resetIsCopied={resetIsCopied}
                    />
                )
            )}
            {/* Google Ads Middle-2 --Start */}
            <AdsComponent adConfigurations={adMiddle2} />
            {/* Google Ads Middle-2 --End */}
            {/* Similar Links */}
            <SimilarLinkComponent />
            {/* Google Ads bottom --Start */}
            <AdsComponent adConfigurations={adBottom} />
            {/* Google Ads bottom --End */}
            {/* Page Information */}
            <Grid container className="row-min" spacing={2}>
                <Grid item>
                    <Box>
                        <h2>Cool Number Fonts - Enhance Numerical Display</h2>
                        <p>Elevate your numerical display with our Cool Number Fonts and Conversions tools. Whether you're working with Roman numerals, Hindi counting, Arabic numbers, or any other number system, our versatile tools offer a wide range of number font styles and conversions to add a unique and captivating touch to your documents and designs.</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default DigitsStyle;
