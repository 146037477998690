// ReminderAlert.js
import React, { useState, useEffect, useRef } from 'react';
import { Grid, Paper, Box } from '@mui/material';
import ReminderForm from './ComponentReminderForm';
import ReminderList from './ComponentReminderList';
import RingtonePlayer from './ComponentRingtonePlayer';
import LocalStorageManager from './ComponentLocalStorageManager';
import AdsComponent from '../../adds/AdsPageComponent';
import { adTop, adMiddle1, adMiddle2, adBottom } from '../../adds/Ads';
import SimilarLinkComponent from '../../common/StringManipulation/SimilarLinkComponent';
// for seo
import SEOComponent from '../../common/SEOComponent';

const ReminderAlert = () => {
  const [reminders, setReminders] = useState([]);
  const [activeReminder, setActiveReminder] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const audioRef = useRef(null);
  const [allLocalReminders, setAllLocalReminders] = useState([]); // New state for all local reminders

  const activateReminder = (reminder) => {
    setActiveReminder(reminder);

    setTimeout(() => {
      setActiveReminder(null);
      deleteReminder(reminder.id);
    }, 60000);
  };


  useEffect(() => {
    const deviceID = LocalStorageManager.getDeviceID();
    const storedReminders = LocalStorageManager.getReminders(deviceID);
    setReminders(storedReminders);

    // Set all local reminders from local memory
    setAllLocalReminders(storedReminders);
  }, []);


  const addReminder = (reminder) => {
    const updatedReminders = [...reminders, reminder];
    setReminders(updatedReminders);

    const deviceID = LocalStorageManager.getDeviceID();
    LocalStorageManager.saveReminders(deviceID, updatedReminders);

    // Update all local reminders when a new reminder is added
    setAllLocalReminders(updatedReminders);
  };

  const deleteReminder = (id) => {
    const updatedReminders = reminders.filter((reminder) => reminder.id !== id);
    setReminders(updatedReminders);

    const deviceID = LocalStorageManager.getDeviceID();
    LocalStorageManager.saveReminders(deviceID, updatedReminders);

    // Update all local reminders when a reminder is deleted
    setAllLocalReminders(updatedReminders);
  };


  return (
    <div className="main">
      {/* For Seo on page Head information */}
      <SEOComponent />
      {/* Google Ads Middle-1 --Start */}
      <AdsComponent adConfigurations={adMiddle1} />
      {/* Google Ads Middle-1 --End */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <h1>Set Your Alarms Easily with Our Online Alarm Clock</h1>
            <p>Enjoy hassle-free alarm setting and wake up on time with our convenient online alarm clock.</p>
          </Box>
        </Grid>
      </Grid>
      <Grid container className="row-min" spacing={2}>
        <Grid item xs={12} md={8} >
          <Paper elevation={3}>
            {/* Pass addReminder function and reminders prop to ReminderForm */}
            <ReminderForm addReminder={addReminder} reminders={reminders} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} >
            {/* Pass reminders, deleteReminder, and activateReminder to ReminderList */}
            <ReminderList
              reminders={reminders}
              deleteReminder={deleteReminder}
              activateReminder={activateReminder}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        {/* Pass reminders, deleteReminder, and activateReminder to RingtonePlayer */}
        {/* <RingtonePlayer reminders={reminders} deleteReminder={deleteReminder} activateReminder={activateReminder} /> */}
        <RingtonePlayer reminders={allLocalReminders} deleteReminder={deleteReminder} activateReminder={activateReminder} />
      </Grid>
      {/* Google Ads Middle-2 --Start */}
      <AdsComponent adConfigurations={adMiddle2} />
      {/* Google Ads Middle-2 --End */}
      {/* Similar Links */}
      <SimilarLinkComponent />
      {/* Google Ads bottom --Start */}
      <AdsComponent adConfigurations={adBottom} />
      {/* Google Ads bottom --End */}
      <Grid container className="row-min" spacing={2}>
        <Grid item>
          <Box>
            <h2></h2>
            <p>Never oversleep again! Set your alarms effortlessly with our Online Alarm Clock. Enjoy hassle-free alarm setting and wake up on time, ensuring you start your day right.</p>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReminderAlert;
