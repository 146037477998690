// Popup.js
import React from 'react';

const popupStyles = {
  backgroundColor: 'white',
  width: '80vw',
  height: '60vh',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const buttonStyles = {
  marginTop: '20px',
};

const Popup = ({ activeReminderInfo, stopAlarm, deleteReminder }) => {
  const { id, title, time, expirationTime, deleteTime } = activeReminderInfo;

  const handleStopAlarm = () => {
    if (activeReminderInfo && activeReminderInfo.id) {
      // Optionally, you can add code here to stop the audio explicitly if needed.
  
      // Call the deleteReminder function to remove the reminder
      deleteReminder(activeReminderInfo.id);
  
      // Call the stopAlarm function to close the popup
      stopAlarm();
    }
  };
  

  return (
    <div className="popup" style={popupStyles}>
      <h2>{title}</h2>
      <p>Time: {time}</p>
      <p>Expires at: {expirationTime}</p>
      <p>Delete time: {deleteTime}</p>
      <button onClick={handleStopAlarm} style={buttonStyles}>
        Stop Alarm
      </button>
    </div>
  );
};

export default Popup;
