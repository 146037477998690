import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { StyledTextareaAutosize, StyledButtonIcon, StyledCopyButton } from '../TagsStyle';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TextTextAreaSingle = ({
    outputText,
    outputTextareaRef,
    initialOutputText,
    handleDownloadClick,
    handleCopyClick,
    isCopied,
    handleOutputChange,
}) => {

    const isOutputTextEmpty = outputText.length === 0 || outputText.trim() === '';
    // const isOutputTextEmpty = outputText.length === 0 || outputText.every((text) => text === '');

    return (
        <>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        <Box>
                            <StyledButtonIcon className="download-button" variant="contained" size="small" onClick={handleDownloadClick}>
                                <FileDownloadIcon />
                            </StyledButtonIcon>
                            <StyledCopyButton
                                className={`copy-button ${isCopied && !isOutputTextEmpty ? 'copied' : ''}`}
                                onClick={handleCopyClick}
                                endIcon={<CopyAllIcon />}
                                size="small"
                            >
                                {isCopied && !isOutputTextEmpty ? 'Copied' : 'Copy'}
                            </StyledCopyButton>
                        </Box>
                        {/* Output textarea now acts as both input and output */}
                        <StyledTextareaAutosize
                            className="text-input output-single"
                            id="copied"
                            ref={outputTextareaRef}
                            onChange={handleOutputChange}
                            placeholder={initialOutputText}
                            value={outputText}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TextTextAreaSingle;
