import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnNavigation = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);

    // Trigger the fade-in effect
    setActive(true);

    // After a short delay, trigger the fade-out effect
    const timeoutId = setTimeout(() => {
      setActive(false);
    }, 300); // Adjust the duration as needed

    // Clear the timeout when the component unmounts to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <div className={`fade-transition ${active ? 'active' : ''}`}>
      {/* Your page content goes here */}
    </div>
  );
};

export default ScrollToTopOnNavigation;
