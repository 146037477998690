import React from 'react';
import { Grid, Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Dropdownlist = ({
    handleDropdownChange,
    DropdownId,
    Dropdownlistvalue,
    DropdownLabel,
    selectedFontStyle, // Pass the selected font style as a prop
    applyFontStyle, // Pass the applyFontStyle function as a prop
}) => {
    return (
        <Grid container className="row-min" spacing={2}>
            <Grid item className="text-dorop">
                <Box>
                    <FormControl sx={{
                        minWidth: '16em',
                        '@media (max-width: 315px)': {
                            minWidth: '10em',
                        },
                    }} size="small">
                        <InputLabel id="demo-select-small-label">{DropdownLabel}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id={DropdownId}
                            value={Dropdownlistvalue}
                            label={DropdownLabel}
                            onChange={handleDropdownChange} // Call the passed function here
                        >
                            <MenuItem value="">
                                <em>-- Select --</em>
                            </MenuItem>
                            <MenuItem value="FontStyle3">卞ヨメ卞 卞回 乍回几卞 己卞としヨ</MenuItem>
                            <MenuItem value="FontStyle5">ƚҽxƚ ƚσ ϝσɳƚ ʂƚყʅҽ</MenuItem>
                            <MenuItem value="FontStyle6">꓄ꏂꉧ꓄ ꓄ꄲ ꊰꄲꋊ꓄ ꇙ꓄ꌦ꒒ꏂ</MenuItem>
                            <MenuItem value="FontStyle7">ꋖꈼꇒꋖ ꋖꂦ ꄞꂦꋊꋖ ꌚꋖꐞ꒒ꈼ</MenuItem>
                            <MenuItem value="FontStyle8">TE᙭T TO ᖴOᑎT ᔕTYᒪE</MenuItem>
                            <MenuItem value="FontStyle9">𝓉ℯ𝓍𝓉 𝓉ℴ 𝒻ℴ𝓃𝓉 𝓈𝓉𝓎𝓁ℯ</MenuItem>
                            <MenuItem value="GlitchText">T̸e̸x̸t̸ T̸o̸ F̸o̸n̸t̸ S̸t̸y̸l̸e̸</MenuItem>
                            <MenuItem value="TimesNewRomanText">𝕿𝖊𝖝𝖙 𝕿𝖔 𝕱𝖔𝖓𝖙 𝕾𝖙𝖞𝖑𝖊</MenuItem>
                            <MenuItem value="SquaredText">🅃🄴🅇🅃 🅃🄾 🄵🄾🄽🅃 🅂🅃🅈🄻🄴</MenuItem>
                            <MenuItem value="NegativeSquaredText">🆃🅴🆇🆃 🆃🅾 🅵🅾🅽🆃 🆂🆃🆈🅻🅴</MenuItem>
                            <MenuItem value="CircledTextBlack">🅣🅔🅧🅣 🅣🅞 🅕🅞🅝🅣 🅢🅣🅨🅛🅔</MenuItem>
                            <MenuItem value="CircledText">ⓉⒺⓍⓉ ⓉⓄ ⒻⓄⓃⓉ ⓈⓉⓎⓁⒺ</MenuItem>
                            <MenuItem value="FontStyle1">ㄒ乇乂ㄒ ㄒㄖ 千ㄖ几ㄒ 丂ㄒㄚㄥ乇</MenuItem>
                            <MenuItem value="FontStyle2">₮ɆӾ₮ ₮Ø ₣Ø₦₮ ₴₮ɎⱠɆ</MenuItem>
                            <MenuItem value="plainToIPA">ʈɛχʈ ʈɒ ɸɒɲʈ ʃʈʏʟɛ</MenuItem>
                            <MenuItem value="MirrorVisibleText">ǝlʎʇS ʇuoℲ o⊥ ʇxǝ⊥</MenuItem>
                            <MenuItem value="BackwardsText">elytS tnoF oT txeT</MenuItem>
                            <MenuItem value="strikethroughText">T̶e̶x̶t̶ ̶T̶o̶ ̶F̶o̶n̶t̶ ̶S̶t̶y̶l̶e̶</MenuItem>
                            <MenuItem value="SmallCapsText">ᴛᴇxᴛ ᴛᴏ ꜰᴏɴᴛ ꜱᴛʏʟᴇ</MenuItem>
                            <MenuItem value="SuperscriptGenerator">ᵀᵉˣᵗ ᵀᵒ ᶠᵒⁿᵗ ˢᵗʸˡᵉ</MenuItem>
                            <MenuItem value="mixCharacters1">𝓣𝐞𝔁𝓽 𝓣σ Ƒσ𝓃𝓽 𝓢𝓽𝔂𝓁𝐞</MenuItem>
                            <MenuItem value="mixCharacters2">Ṭҽ×է Ṭօ Ƒօղէ ʂէꂖӀҽ</MenuItem>
                            <MenuItem value="mixCharacters3">тєχт тσ ƒσηт ѕтуℓє</MenuItem>
                            <MenuItem value="mixCharacters4">†𝐞𝐱𝐭 †𝐨 Ϝ𝐨𝐧𝐭 ∫𝐭𝐲𝓵𝐞</MenuItem>
                            <MenuItem value="mixCharacters5">†Ǝ✘† †ø ƒøИ† Ꭶ†¥ℓƎ</MenuItem>
                            <MenuItem value="mixCharacters6">꓄ꏂꉼ꓄ ꓄ꉻ ꄟꉻꂚ꓄ ꑄ꓄ꂖ꒒ꏂ</MenuItem>
                            <MenuItem value="mixCharacters7">ꋖꏹꋚꋖ ꋖꁏ ꄙꁏꋊꋖ ꌗꋖꂖ꒒ꏹ</MenuItem>
                            <MenuItem value="LatinDoubleStruck">𝕋𝕖𝕩𝕥 𝕋𝕠 𝔽𝕠𝕟𝕥 𝕊𝕥𝕪𝕝𝕖</MenuItem>
                            <MenuItem value="LatinMonoSpace">𝚃𝚎𝚡𝚝 𝚃𝚘 𝙵𝚘𝚗𝚝 𝚂𝚝𝚢𝚕𝚎</MenuItem>
                            <MenuItem value="LatinFraktur">𝔗𝔢𝔵𝔱 𝔗𝔬 𝔉𝔬𝔫𝔱 𝔖𝔱𝔶𝔩𝔢</MenuItem>
                            <MenuItem value="LatinCalligraphy">𝒯ℯ𝓍𝓉 𝒯ℴ ℱℴ𝓃𝓉 𝒮𝓉𝓎𝓁ℯ</MenuItem>
                            <MenuItem value="BoldText">𝗧𝗲𝘅𝘁 𝘁𝗼 𝗙𝗼𝗻𝘁 𝗦𝘁𝘆𝗹𝗲</MenuItem>
                            <MenuItem value="ItalicText">𝘛𝘦𝘹𝘵 𝘵𝘰 𝘍𝘰𝘯𝘵 𝘚𝘵𝘺𝘭𝘦</MenuItem>
                            <MenuItem value="BoldItalicText">𝙏𝙚𝙭𝙩 𝙩𝙤 𝙁𝙤𝙣𝙩 𝙎𝙩𝙮𝙡𝙚</MenuItem>
                            <MenuItem value="BoldItalicMonospaceText">𝑻𝒆𝒙𝒕 𝒕𝒐 𝑭𝒐𝒏𝒕 𝑺𝒕𝒚𝒍𝒆</MenuItem>
                            <MenuItem value="SansSerifText">𝖳𝖾𝗑𝗍 𝗍𝗈 𝖥𝗈𝗇𝗍 𝖲𝗍𝗒𝗅𝖾</MenuItem>
                            <MenuItem value="convertToSingleUnderlined">T̲e̲x̲t̲ t̲o̲ F̲o̲n̲t̲ S̲t̲y̲l̲e̲</MenuItem>
                            <MenuItem value="convertToDoubleUnderlined">T̲̲e̲̲x̲̲t̲̲ t̲̲o̲̲ F̲̲o̲̲n̲̲t̲̲ S̲̲t̲̲y̲̲l̲̲e̲̲</MenuItem>
                            <MenuItem value="fullWidthCharacters">Ｔｅｘｔ ｔｏ Ｆｏｎｔ Ｓｔｙｌｅ</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Dropdownlist;
