import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import {
    StyledButton, StyledTextareaAutosize, StyledClearButton,
    StyledButtonIcon, StyledCopyButton
} from '../TagsStyle';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TextCaseConvert = ({
    showClearButton,
    handleClearClick,
    handleInputChange,
    inputText,
    error,
    showCopyButton,
    outputText,
    handleDownloadClick,
    handleCopyClick,
    isCopied,
    handleOutputChange,
    handleButtonClick,
}) => {

    const isOutputTextEmpty = outputText.trim() === '';

    return (
        <>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {showClearButton && (
                            <StyledClearButton className="clear-button" onClick={handleClearClick} endIcon={<ClearIcon />} size="small">
                                Clear
                            </StyledClearButton>
                        )}
                        <StyledTextareaAutosize
                            className="input-textarea text-input"
                            placeholder="Start typing, or copy and paste your document here..."
                            value={inputText}
                            maxLength={30000}
                            onChange={handleInputChange}
                        />
                        {error && <p className="error-message">{error}</p>}
                        {/* The input text will appear in real-time in the output textarea */}
                    </Box>
                </Grid>
            </Grid>
            <Grid container className='buttons-case row btn-pd-mob' style={{ paddingBottom: '20px' }} spacing={{ xs: .5 }} justifyContent="center">
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('sentence_case')}>
                        Sentence case
                    </StyledButton>
                </Grid>
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('lower_case')}>
                        lower case
                    </StyledButton>
                </Grid>
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('upper_case')}>
                        UPPER CASE
                    </StyledButton>
                </Grid>
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('capitalized_case')}>
                        Capitalized Case
                    </StyledButton>
                </Grid>
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('alternating_case')}>
                        AlTeRnAtInG CaSe
                    </StyledButton>
                </Grid>
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('title_case')}>
                        Title Case
                    </StyledButton>
                </Grid>
                <Grid item md="auto">
                    <StyledButton className='button-primary' variant="outlined" size="small" onClick={() => handleButtonClick('inverse_case')}>
                        iNVERSE cASE
                    </StyledButton>
                </Grid>
            </Grid>
            <Grid className="row" container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ position: 'relative' }}>
                        {(showCopyButton || !isOutputTextEmpty) && (
                            <Box>
                                <StyledButtonIcon className="download-button" variant="contained" size="small" onClick={handleDownloadClick}>
                                    <FileDownloadIcon />
                                </StyledButtonIcon>
                                <StyledCopyButton
                                    className={`copy-button ${isCopied && !isOutputTextEmpty ? 'copied' : ''}`}
                                    onClick={handleCopyClick}
                                    endIcon={<CopyAllIcon />}
                                    size="small"
                                >
                                    {isCopied && !isOutputTextEmpty ? 'Copied' : 'Copy'}
                                </StyledCopyButton>
                            </Box>
                        )}
                        <StyledTextareaAutosize
                            className="output-textarea text-input"
                            id="copied"
                            value={outputText}
                            onChange={handleOutputChange}
                            placeholder='Result..'
                            readOnly
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TextCaseConvert;
