
// Function to convert plain text to IPA symbols
export const plainToIPA = (text) => {
  const plainToIPAMapper = {
    'a': 'ɐ', 'b': 'ʙ', 'c': 'ç', 'd': 'ɖ', 'e': 'ɛ', 'f': 'ɸ', 'g': 'ɡ', 'h': 'ɦ',
    'i': 'ɪ', 'j': 'ʝ', 'k': 'ʞ', 'l': 'ʟ', 'm': 'ɯ', 'n': 'ɲ', 'o': 'ɒ', 'p': 'ʋ',
    'q': 'ɋ', 'r': 'ɹ', 's': 'ʃ', 't': 'ʈ', 'u': 'ʊ', 'v': 'ʌ', 'w': 'ʍ', 'x': 'χ',
    'y': 'ʏ', 'z': 'ʒ',
    'A': 'ɑ', 'B': 'ʙ', 'C': 'ç', 'D': 'ɖ', 'E': 'ɛ', 'F': 'ɸ', 'G': 'ɡ', 'H': 'ɦ',
    'I': 'ɪ', 'J': 'ʝ', 'K': 'ʞ', 'L': 'ʟ', 'M': 'ɯ', 'N': 'ɲ', 'O': 'ɒ', 'P': 'ʋ',
    'Q': 'ɋ', 'R': 'ɹ', 'S': 'ʃ', 'T': 'ʈ', 'U': 'ʊ', 'V': 'ʌ', 'W': 'ʍ', 'X': 'χ',
    'Y': 'ʏ', 'Z': 'ʒ'
  };

  return text
    .split('')
    .map((char) => plainToIPAMapper[char] || char)
    .join('');
};

//Cursor Text: 
// convert plain text into binary code using the ASCII representation 
export const TextToBinaryCodeASCII = (text) => {
  const binaryMap = {
    ' ': '00100000',
    '\n': '00001010'
  };

  let binaryResult = '';

  const lines = text.split('\n');
  for (let i = 0; i < lines.length; i++) {
    const words = lines[i].split(' ');
    for (let j = 0; j < words.length; j++) {
      const word = words[j];
      for (let k = 0; k < word.length; k++) {
        const char = word[k];
        if (binaryMap[char]) {
          binaryResult += binaryMap[char] + ' ';
        } else {
          const binaryChar = char.charCodeAt(0).toString(2);
          binaryResult += binaryChar.padStart(8, '0') + ' ';
        }
      }
      if (j < words.length - 1) {
        binaryResult += binaryMap[' '] + ' ';
      }
    }
    if (i < lines.length - 1) {
      binaryResult += binaryMap['\n'] + ' ';
    }
  }

  return binaryResult.trim();
};

//  converts binary code back to plain text using the ASCII representation:
export const BinaryCodeToTextASCII = (binaryCode) => {
  const binaryMap = {
    '00100000': ' ',
    '00001010': '\n'
  };

  let textResult = '';
  const binarySegments = binaryCode.split(' ');

  for (let i = 0; i < binarySegments.length; i++) {
    const binarySegment = binarySegments[i];
    if (binaryMap[binarySegment]) {
      textResult += binaryMap[binarySegment];
    } else {
      const charCode = parseInt(binarySegment, 2);
      const char = String.fromCharCode(charCode);
      textResult += char;
    }
  }

  return textResult;
};

export const TextToHex = (text) => {
  let hexResult = '';

  for (let j = 0; j < text.length; j++) {
    const charCode = text.charCodeAt(j);
    if (charCode === 32 || charCode === 10) {
      hexResult += charCode.toString(16);
    } else {
      const hexChar = charCode.toString(16);
      hexResult += hexChar;
    }
    hexResult += ' ';
  }

  return hexResult.trim();
};

export const HexToText = (hex) => {
  const hexArray = hex.split(' ');
  let textResult = '';

  for (let i = 0; i < hexArray.length; i++) {
    if (hexArray[i] === '') {
      continue;
    }

    const charCode = parseInt(hexArray[i], 16);
    const character = String.fromCharCode(charCode);
    textResult += character;
  }

  return textResult;
};

//Invisible Text Converter
export const InvisibleText = (text) => {
  let invisibleResult = '';

  for (let i = 0; i < text.length; i++) {
    invisibleResult += '\u3164';
  }

  return invisibleResult;
};

//function of the JSON Stringify
export const JSONStringify = (text) => {
  // Remove extra spaces
  const cleanedText = text.replace(/[ ]+/g, ' ');

  const jsonString = JSON.stringify(cleanedText);
  return jsonString;
};
// function that not only mirrors the input text but also mirrors the output content
//may be error 
export const MirrorVisibleText = (text) => {
  const mirrorCharsMapper = {
    'a': 'ɒ', 'b': 'd', 'c': 'ɔ', 'd': 'b', 'e': 'ǝ', 'f': 'ɟ', 'g': 'ƃ', 'h': 'ɥ',
    'i': 'ᴉ', 'j': 'ɾ', 'k': 'ʞ', 'l': 'l', 'm': 'ɯ', 'n': 'u', 'o': 'o', 'p': 'p',
    'q': 'b', 'r': 'ɹ', 's': 's', 't': 'ʇ', 'u': 'n', 'v': 'ʌ', 'w': 'ʍ', 'x': 'x',
    'y': 'ʎ', 'z': 'z',
    'A': '∀', 'B': 'B', 'C': 'Ɔ', 'D': 'D', 'E': 'Ǝ', 'F': 'Ⅎ', 'G': 'פ', 'H': 'H',
    'I': 'I', 'J': 'ſ', 'K': 'K', 'L': '˥', 'M': 'W', 'N': 'N', 'O': 'O', 'P': 'Ԁ',
    'Q': 'Q', 'R': 'ᴚ', 'S': 'S', 'T': '⊥', 'U': '∩', 'V': 'Λ', 'W': 'M', 'X': 'X',
    'Y': '⅄', 'Z': 'Z',
    '0': '0', '1': 'Ɩ', '2': 'ᄅ', '3': 'Ɛ', '4': 'ㄣ', '5': 'ϛ',
    '6': '9', '7': 'ㄥ', '8': '8', '9': '6'
  };

  const mirroredText = text
    .split('')
    .map((char) => mirrorCharsMapper[char] || char)
    .join('');

  return mirroredText.split('').reverse().join('');
};
//Cursor Text: 

//  function MorseCodeAndMorseAudio includes both the Morse Code Translator logic and the Morse Audio Player (placeholder function) logic
export const MorseCodeAndMorseAudio = (text) => {
  const morseCodeMapper = {
    'A': '.-', 'B': '-...', 'C': '-.-.', 'D': '-..', 'E': '.',
    'F': '..-.', 'G': '--.', 'H': '....', 'I': '..', 'J': '.---',
    'K': '-.-', 'L': '.-..', 'M': '--', 'N': '-.', 'O': '---',
    'P': '.--.', 'Q': '--.-', 'R': '.-.', 'S': '...', 'T': '-',
    'U': '..-', 'V': '...-', 'W': '.--', 'X': '-..-', 'Y': '-.--',
    'Z': '--..',
    'a': '.-', 'b': '-...', 'c': '-.-.', 'd': '-..', 'e': '.',
    'f': '..-.', 'g': '--.', 'h': '....', 'i': '..', 'j': '.---',
    'k': '-.-', 'l': '.-..', 'm': '--', 'n': '-.', 'o': '---',
    'p': '.--.', 'q': '--.-', 'r': '.-.', 's': '...', 't': '-',
    'u': '..-', 'v': '...-', 'w': '.--', 'x': '-..-', 'y': '-.--',
    'z': '--..',
    '0': '-----', '1': '.----', '2': '..---', '3': '...--', '4': '....-', '5': '.....',
    '6': '-....', '7': '--...', '8': '---..', '9': '----.',
    '.': '.-.-.-', ',': '--..--', '?': '..--..', "'": '.----.', '!': '-.-.--',
    '/': '-..-.', '(': '-.--.', ')': '-.--.-', '&': '.-...', ':': '---...',
    ';': '-.-.-.', '=': '-...-', '+': '.-.-.', '-': '-....-', '_': '..--.-',
    '"': '.-..-.', '$': '...-..-', '@': '.--.-.', ' ': '/'
  };

  // Function to translate text to Morse code
  const translateToMorseCode = (text) => {
    return text.split('').map(char => {
      return morseCodeMapper[char] || ''; // Return empty string for invalid characters
    }).join(' ');
  };

  // Placeholder function for Morse Audio Player (no actual audio playback)
  const playMorseCodeAsAudio = (morseCode) => {
    console.log(`Playing Morse code as audio: ${morseCode}`);
  };

  // Translate text to Morse code
  const morseCode = translateToMorseCode(text);

  // Play Morse code as audio (placeholder function)
  playMorseCodeAsAudio(morseCode);

  return morseCode;
};

// Quickly Translate Words to the NATO Phonetic Alphabet
export const NatoPhoneticAlphabet = (text) => {
  const natoPhoneticAlphabetMapper = {
    'a': 'Alpha', 'b': 'Bravo', 'c': 'Charlie', 'd': 'Delta', 'e': 'Echo',
    'f': 'Foxtrot', 'g': 'Golf', 'h': 'Hotel', 'i': 'India', 'j': 'Juliett',
    'k': 'Kilo', 'l': 'Lima', 'm': 'Mike', 'n': 'November', 'o': 'Oscar',
    'p': 'Papa', 'q': 'Quebec', 'r': 'Romeo', 's': 'Sierra', 't': 'Tango',
    'u': 'Uniform', 'v': 'Victor', 'w': 'Whiskey', 'x': 'X-ray', 'y': 'Yankee',
    'z': 'Zulu',

    'A': 'Alpha', 'B': 'Bravo', 'C': 'Charlie', 'D': 'Delta', 'E': 'Echo',
    'F': 'Foxtrot', 'G': 'Golf', 'H': 'Hotel', 'I': 'India', 'J': 'Juliett',
    'K': 'Kilo', 'L': 'Lima', 'M': 'Mike', 'N': 'November', 'O': 'Oscar',
    'P': 'Papa', 'Q': 'Quebec', 'R': 'Romeo', 'S': 'Sierra', 'T': 'Tango',
    'U': 'Uniform', 'V': 'Victor', 'W': 'Whiskey', 'X': 'X-ray', 'Y': 'Yankee',
    'Z': 'Zulu',

    // Numbers
    '0': 'Zero', '1': 'One', '2': 'Two', '3': 'Three', '4': 'Four', '5': 'Five',
    '6': 'Six', '7': 'Seven', '8': 'Eight', '9': 'Nine',

    // Symbols
    '!': 'Exclamation Point', '@': 'At Sign', '#': 'Pound Sign', '$': 'Dollar Sign',
    '%': 'Percent Sign', '&': 'Ampersand', "'": 'Single Quote', '(': 'Left Parenthesis',
    ')': 'Right Parenthesis', '*': 'Asterisk', '+': 'Plus', ',': 'Comma', '-': 'Hyphen',
    '.': 'Period', '/': 'Slash', ':': 'Colon', ';': 'Semicolon', '<': 'Less Than',
    '=': 'Equals Sign', '>': 'Greater Than', '?': 'Question Mark', '[': 'Left Bracket',
    '\\': 'Backslash', ']': 'Right Bracket', '^': 'Caret', '_': 'Underscore',
    '`': 'Grave Accent', '{': 'Left Brace', '|': 'Pipe', '}': 'Right Brace', '~': 'Tilde',
  };

  // Function to translate text to NATO Phonetic Alphabet
  const translateToNATOAlphabet = (text) => {
    return text.split('').map(char => {
      return natoPhoneticAlphabetMapper[char] || char;
    }).join(' ');
  };

  return translateToNATOAlphabet(text);
};

export const ReverseNatoPhoneticAlphabet = (natoText) => {
  const reverseNatoPhoneticAlphabetMapper = {
    // ... your existing mapper ...
    'Alpha': 'a', 'Bravo': 'b', 'Charlie': 'c', 'Delta': 'd', 'Echo': 'e',
    'Foxtrot': 'f', 'Golf': 'g', 'Hotel': 'h', 'India': 'i', 'Juliett': 'j',
    'Kilo': 'k', 'Lima': 'l', 'Mike': 'm', 'November': 'n', 'Oscar': 'o',
    'Papa': 'p', 'Quebec': 'q', 'Romeo': 'r', 'Sierra': 's', 'Tango': 't',
    'Uniform': 'u', 'Victor': 'v', 'Whiskey': 'w', 'X-ray': 'x', 'Yankee': 'y',
    'Zulu': 'z',

    // Numbers
    'Zero': '0', 'One': '1', 'Two': '2', 'Three': '3', 'Four': '4', 'Five': '5',
    'Six': '6', 'Seven': '7', 'Eight': '8', 'Nine': '9',

    // Symbols
    'Exclamation Point': '!', 'At Sign': '@', 'Pound Sign': '#', 'Dollar Sign': '$',
    'Percent Sign': '%', 'Ampersand': '&', 'Single Quote': "'", 'Left Parenthesis': '(',
    'Right Parenthesis': ')', 'Asterisk': '*', 'Plus': '+', 'Comma': ',', 'Hyphen': '-',
    'Period': '.', 'Slash': '/', 'Colon': ':', 'Semicolon': ';', 'Less Than': '<',
    'Equals Sign': '=', 'Greater Than': '>', 'Question Mark': '?', 'Left Bracket': '[',
    'Backslash': '\\', 'Right Bracket': ']', 'Caret': '^', 'Underscore': '_',
    'Grave Accent': '`', 'Left Brace': '{', 'Pipe': '|', 'Right Brace': '}', 'Tilde': '~',
  };

  const words = natoText.split(' ');

  let output = '';
  let isSpace = false;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const combinedWord = word + ' ' + words[i + 1];
    const char = reverseNatoPhoneticAlphabetMapper[word] || reverseNatoPhoneticAlphabetMapper[combinedWord];

    if (char) {
      if (isSpace) {
        output += ' ';
        isSpace = false;
      }
      output += char;
    } else if (word === '') {
      isSpace = true;
    }
  }

  return output;
};

export const RepeatText = (inputText, timesToRepeat, separator = null) => {
  if (timesToRepeat <= 0) {
    return '';
  }

  let repeatedText = inputText;

  for (let i = 1; i < timesToRepeat; i++) {
    if (separator !== null) {
      repeatedText += separator;
    }
    repeatedText += inputText;
  }

  return repeatedText;
};

//Text Replacer function that finds and replaces text in a given input text
export const TextReplacerfn = (outputText, findText, replaceText, caseSensitive) => {
  // If caseSensitive is true, create a regular expression with the "g" flag (global match)
  // and the "i" flag (case-insensitive)
  const regex = caseSensitive ? new RegExp(findText, 'gi') : new RegExp(findText, 'g');

  // Use the replace method to replace all occurrences of findText with replaceText
  const replacedText = outputText.replace(regex, replaceText);

  return replacedText;
};

//Backwards/ReverseText function that generates the backward text
export const BackwardsText = (inputText) => {
  const reverseText = inputText.split('').reverse().join('');
  return reverseText;
};
//Cursor Text: 

//  Roman Numeral Date Converter "day-month-year" instead of "day/month/year"
export const RomanNumeralDate = (input) => {
  const convertToRoman = (num) => {
    const romanNumerals = {
      1: 'I', 4: 'IV', 5: 'V', 9: 'IX', 10: 'X',
      40: 'XL', 50: 'L', 90: 'XC', 100: 'C',
      400: 'CD', 500: 'D', 900: 'CM', 1000: 'M'
    };

    let result = '';
    const sortedKeys = Object.keys(romanNumerals).sort((a, b) => b - a);

    for (const value of sortedKeys) {
      while (num >= value) {
        result += romanNumerals[value];
        num -= value;
      }
    }

    return result;
  };

  const output = input.replace(/\d+/g, match => convertToRoman(parseInt(match)));

  return output;
};

// SlugifyURL generator function
export const SlugifyURL = (input) => {
  const slug = input
    .replace(/[^\w\s-]+/g, '')   // Replace consecutive non-word characters with a single hyphen
    .replace(/\s+/g, '-')        // Replace spaces with hyphens
    .replace(/[-_]+/g, '-')      // Replace consecutive hyphens or underscores with a single hyphen
    .replace(/^-|-$/g, '')       // Remove leading and trailing hyphens
    .toLowerCase();              // Convert to lowercase

  return slug;
};

export const PlainTextDate = (input) => {
  const romanNumerals = {
    M: 1000, CM: 900, D: 500, CD: 400, C: 100,
    XC: 90, L: 50, XL: 40, X: 10, IX: 9,
    V: 5, IV: 4, I: 1
  };

  const convertToPlain = (roman) => {
    let result = 0;
    let i = 0;

    while (i < roman.length) {
      const currentSymbol = roman[i];
      const currentValue = romanNumerals[currentSymbol];

      if (i + 1 < roman.length) {
        const nextSymbol = roman[i + 1];
        const nextValue = romanNumerals[nextSymbol];

        if (currentValue >= nextValue) {
          result += currentValue;
          i++;
        } else {
          result += nextValue - currentValue;
          i += 2;
        }
      } else {
        result += currentValue;
        i++;
      }
    }

    return result;
  };

  const output = input.replace(/[IVXLCDM]+/g, match => convertToPlain(match));

  return output;
};

export const TextToBold = (text) => {
  const boldChars = {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲',
    '7': '𝟳', '8': '𝟴', '9': '𝟵',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };
  return text
    .split('')
    .map((char) => boldChars[char] || char)
    .join('');
};

export const TextToItalic = (text) => {
  const italicCharsMapper = {
    'A': '𝘈', 'B': '𝘉', 'C': '𝘊', 'D': '𝘋', 'E': '𝘌', 'F': '𝘍', 'G': '𝘎',
    'H': '𝘏', 'I': '𝘐', 'J': '𝘑', 'K': '𝘒', 'L': '𝘓', 'M': '𝘔', 'N': '𝘕',
    'O': '𝘖', 'P': '𝘗', 'Q': '𝘘', 'R': '𝘙', 'S': '𝘚', 'T': '𝘛', 'U': '𝘜',
    'V': '𝘝', 'W': '𝘞', 'X': '𝘟', 'Y': '𝘠', 'Z': '𝘡',
    'a': '𝘢', 'b': '𝘣', 'c': '𝘤', 'd': '𝘥', 'e': '𝘦', 'f': '𝘧', 'g': '𝘨',
    'h': '𝘩', 'i': '𝘪', 'j': '𝘫', 'k': '𝘬', 'l': '𝘭', 'm': '𝘮', 'n': '𝘯',
    'o': '𝘰', 'p': '𝘱', 'q': '𝘲', 'r': '𝘳', 's': '𝘴', 't': '𝘵', 'u': '𝘶',
    'v': '𝘷', 'w': '𝘸', 'x': '𝘹', 'y': '𝘺', 'z': '𝘻',
    '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6',
    '7': '7', '8': '8', '9': '9'
  };

  return text
    .split('')
    .map((char) => italicCharsMapper[char] || char)
    .join('');
};

export const fullWidthCharacters = (text) => {
  const unicodeFullWidth = {
    '0': '０', '1': '１', '2': '２', '3': '３', '4': '４',
    '5': '５', '6': '６', '7': '７', '8': '８', '9': '９',
    'A': 'Ａ', 'B': 'Ｂ', 'C': 'Ｃ', 'D': 'Ｄ', 'E': 'Ｅ',
    'F': 'Ｆ', 'G': 'Ｇ', 'H': 'Ｈ', 'I': 'Ｉ', 'J': 'Ｊ',
    'K': 'Ｋ', 'L': 'Ｌ', 'M': 'Ｍ', 'N': 'Ｎ', 'O': 'Ｏ',
    'P': 'Ｐ', 'Q': 'Ｑ', 'R': 'Ｒ', 'S': 'Ｓ', 'T': 'Ｔ',
    'U': 'Ｕ', 'V': 'Ｖ', 'W': 'Ｗ', 'X': 'Ｘ', 'Y': 'Ｙ',
    'Z': 'Ｚ',
    'a': 'ａ', 'b': 'ｂ', 'c': 'ｃ', 'd': 'ｄ', 'e': 'ｅ',
    'f': 'ｆ', 'g': 'ｇ', 'h': 'ｈ', 'i': 'ｉ', 'j': 'ｊ',
    'k': 'ｋ', 'l': 'ｌ', 'm': 'ｍ', 'n': 'ｎ', 'o': 'ｏ',
    'p': 'ｐ', 'q': 'ｑ', 'r': 'ｒ', 's': 'ｓ', 't': 'ｔ',
    'u': 'ｕ', 'v': 'ｖ', 'w': 'ｗ', 'x': 'ｘ', 'y': 'ｙ',
    'z': 'ｚ',
    ',': '，', '.': '．', ':': '：', ';': '；', '!': '！',
    '?': '？', "'": '＇', '"': '＂', '`': '｀', '^': '＾',
    '~': '～', '￣': '￣', '_': '＿', '&': '＆', '@': '＠',
    '#': '＃', '%': '％', '+': '＋', '-': '－', '*': '＊',
    '=': '＝', '<': '＜', '>': '＞', '(': '（', ')': '）',
    '[': '［', ']': '］', '{': '｛', '}': '｝', '｟': '｟',
    '｠': '｠', '|': '｜', '￤': '￤', '/': '／', '\\': '＼',
    '￢': '￢', '$': '＄', '￡': '￡', '￠': '￠', '￦': '￦',
    '￥': '￥'
  };

  return text
    .split('')
    .map((char) => unicodeFullWidth[char] || char)
    .join('');
};
//Unicode

export const wideTextGenerator = (inputText) => {
  const wideChars = {
    'a': 'ａ', 'b': 'ｂ', 'c': 'ｃ', 'd': 'ｄ', 'e': 'ｅ', 'f': 'ｆ', 'g': 'ｇ', 'h': 'ｈ',
    'i': 'ｉ', 'j': 'ｊ', 'k': 'ｋ', 'l': 'ｌ', 'm': 'ｍ', 'n': 'ｎ', 'o': 'ｏ', 'p': 'ｐ',
    'q': 'ｑ', 'r': 'ｒ', 's': 'ｓ', 't': 'ｔ', 'u': 'ｕ', 'v': 'ｖ', 'w': 'ｗ', 'x': 'ｘ',
    'y': 'ｙ', 'z': 'ｚ',
    'A': 'Ａ', 'B': 'Ｂ', 'C': 'Ｃ', 'D': 'Ｄ', 'E': 'Ｅ', 'F': 'Ｆ', 'G': 'Ｇ', 'H': 'Ｈ',
    'I': 'Ｉ', 'J': 'Ｊ', 'K': 'Ｋ', 'L': 'Ｌ', 'M': 'Ｍ', 'N': 'Ｎ', 'O': 'Ｏ', 'P': 'Ｐ',
    'Q': 'Ｑ', 'R': 'Ｒ', 'S': 'Ｓ', 'T': 'Ｔ', 'U': 'Ｕ', 'V': 'Ｖ', 'W': 'Ｗ', 'X': 'Ｘ',
    'Y': 'Ｙ', 'Z': 'Ｚ',
    '0': '０', '1': '１', '2': '２', '3': '３', '4': '４', '5': '５', '6': '６', '7': '７',
    '8': '８', '9': '９'
  };

  const words = inputText.split(' '); // Split input into words
  const wideWords = words.map((word) => {
    const wideWord = word
      .split('')
      .map((char) => wideChars[char] || char)
      .join('');
    return wideWord;
  });

  const wideText = wideWords.join(' '); // Join words with space
  return wideText;
};

export const convertToSentenceCase = (text) => {
  return text
    .split('\n')
    .map(line => line.charAt(0).toUpperCase() + line.slice(1).toLowerCase())
    .join('\n');
};

export const convertToTitleCase = (text) => {
  return text
    .replace(/\s+/g, ' ')             // Replace multiple spaces with a single space
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const convertToSingleUnderlined = (text) => {
  const underlinedText = text
    .split('\n')
    .map(line => {
      return line
        .split('')
        .map(char => {
          if (char.trim() === '') {
            return char;
          }
          const underlineChar = '\u0332'; // Unicode combining underscore character
          return char + underlineChar;
        })
        .join('');
    })
    .join('\n');

  return underlinedText;
};
//not Used
export const convertToDoubleUnderlined = (text) => {
  const underlinedText = text
    .split('\n')
    .map(line => {
      return line
        .split('')
        .map(char => {
          if (char.trim() === '') {
            return char;
          }
          const underlineChar = '\u0332'; // Unicode combining underscore character
          return char + underlineChar + underlineChar;
        })
        .join('');
    })
    .join('\n');

  return underlinedText;
};
//  function for the Upside Down Text Generator
export const upsideDownText = (inputText) => {
  const flipChars = {
    a: 'ɐ', b: 'q', c: 'ɔ', d: 'p', e: 'ǝ', f: 'ɟ', g: 'ƃ', h: 'ɥ', i: 'ᴉ', j: 'ɾ', k: 'ʞ',
    l: 'l', m: 'ɯ', n: 'u', o: 'o', p: 'd', q: 'b', r: 'ɹ', s: 's', t: 'ʇ', u: 'n', v: 'ʌ',
    w: 'ʍ', x: 'x', y: 'ʎ', z: 'z',
    'A': '∀', 'B': 'ᗺ', 'C': 'Ɔ', 'D': 'ᗡ', 'E': 'Ǝ', 'F': 'Ⅎ', 'G': '⅁', 'H': 'H', 'I': 'I',
    'J': 'ſ', 'K': 'ʞ', 'L': '˥', 'M': 'W', 'N': 'N', 'O': 'O', 'P': 'Ԁ', 'Q': 'Ὁ', 'R': 'ᴚ',
    'S': 'S', 'T': '⊥', 'U': '∩', 'V': 'Λ', 'W': 'M', 'X': 'X', 'Y': '⅄', 'Z': 'Z',
    '1': 'Ɩ', '2': 'ᄅ', '3': 'Ɛ', '4': 'ㄣ', '5': 'ϛ', '6': '9', '7': 'ㄥ', '8': '8', '9': '6',
    '0': '0',
    '!': '¡', '@': '@', '#': '#', '$': '$', '%': '%', '^': '^', '&': '⅋', '*': '*', '(': ')',
    ')': '(', '_': '‾', '+': '+', '-': '-', '=': '=', '[': ']', ']': '[', '{': '}', '}': '{',
    ';': '؛', ':': ':', "'": ',', ',': "'", '.': '˙', '<': '>', '>': '<', '?': '¿', '/': '/',
    '`': '‵', '\\': '\\', '|': '|'
  };

  const upsideDownText = inputText
    .split('')
    .map((char) => flipChars[char] || char)
    .reverse()
    .join('');

  return upsideDownText;
};

export const strikethroughText = (inputText) => {
  const strikethroughText = inputText
    .split('')
    .map((char) => char + '\u0336')
    .join('');

  return strikethroughText;
};
//Cursor Text: 
export const SmallCapsText = (text) => {
  const smallCapsChars = {
    'A': 'ᴀ', 'B': 'ʙ', 'C': 'ᴄ', 'D': 'ᴅ', 'E': 'ᴇ', 'F': 'ꜰ', 'G': 'ɢ',
    'H': 'ʜ', 'I': 'ɪ', 'J': 'ᴊ', 'K': 'ᴋ', 'L': 'ʟ', 'M': 'ᴍ', 'N': 'ɴ',
    'O': 'ᴏ', 'P': 'ᴘ', 'Q': 'ǫ', 'R': 'ʀ', 'S': 'ꜱ', 'T': 'ᴛ', 'U': 'ᴜ',
    'V': 'ᴠ', 'W': 'ᴡ', 'X': 'x', 'Y': 'ʏ', 'Z': 'ᴢ',
    'a': 'ᴀ', 'b': 'ʙ', 'c': 'ᴄ', 'd': 'ᴅ', 'e': 'ᴇ', 'f': 'ꜰ', 'g': 'ɢ',
    'h': 'ʜ', 'i': 'ɪ', 'j': 'ᴊ', 'k': 'ᴋ', 'l': 'ʟ', 'm': 'ᴍ', 'n': 'ɴ',
    'o': 'ᴏ', 'p': 'ᴘ', 'q': 'ǫ', 'r': 'ʀ', 's': 'ꜱ', 't': 'ᴛ', 'u': 'ᴜ',
    'v': 'ᴠ', 'w': 'ᴡ', 'x': 'x', 'y': 'ʏ', 'z': 'ᴢ',
    '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6',
    '7': '7', '8': '8', '9': '9',
    ' ': ' ', ',': ',', ';': ';', ':': ':', '?': '?', '!': '!',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '(', ')': ')',
    '/': '/', '\\': '\\', '-': '−', '=': '=', '+': '+'
  };

  return text
    .split('')
    .map((char) => smallCapsChars[char] || char)
    .join('');
};
//Cursor Text: 

// Small Text Generator functions for Superscript
export const SuperscriptGenerator = (input) => {
  const superscriptMap = {
    'a': 'ᵃ', 'b': 'ᵇ', 'c': 'ᶜ', 'd': 'ᵈ', 'e': 'ᵉ', 'f': 'ᶠ', 'g': 'ᵍ', 'h': 'ʰ', 'i': 'ⁱ', 'j': 'ʲ',
    'k': 'ᵏ', 'l': 'ˡ', 'm': 'ᵐ', 'n': 'ⁿ', 'o': 'ᵒ', 'p': 'ᵖ', 'q': 'ᵠ', 'r': 'ʳ', 's': 'ˢ', 't': 'ᵗ',
    'u': 'ᵘ', 'v': 'ᵛ', 'w': 'ʷ', 'x': 'ˣ', 'y': 'ʸ', 'z': 'ᶻ',
    'A': 'ᴬ', 'B': 'ᴮ', 'C': 'ᶜ', 'D': 'ᴰ', 'E': 'ᴱ', 'F': 'ᶠ', 'G': 'ᴳ', 'H': 'ᴴ', 'I': 'ᴵ', 'J': 'ᴶ',
    'K': 'ᴷ', 'L': 'ᴸ', 'M': 'ᴹ', 'N': 'ᴺ', 'O': 'ᴼ', 'P': 'ᴾ', 'Q': 'ᵠ', 'R': 'ᴿ', 'S': 'ˢ', 'T': 'ᵀ',
    'U': 'ᵁ', 'V': 'ⱽ', 'W': 'ᵂ', 'X': 'ˣ', 'Y': 'ʸ', 'Z': 'ᶻ',
    '0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴', '5': '⁵', '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹',
  };

  // Split the input string into an array of characters, map each character to its superscript equivalent, and join them back together.
  const superscriptText = input.split('').map(char => superscriptMap[char] || char).join('');

  return superscriptText;
};
//Cursor Text: 

// function to both encode and decode UTF-8 text. If you set encode to true
export function UTF8Encoder(text) {
  try {
    const encodedUnicode = [...text]
      .map(char => `\\u${char.charCodeAt(0).toString(16).padStart(4, '0')}`)
      .join('');

    return encodedUnicode;
  } catch (error) {
    return error.message;
  }
}

export function UTF8Decoder(hexData) {
  try {
    const hexValues = hexData.split('\\u').filter(hexVal => hexVal !== '');
    const decodedText = hexValues
      .map(hexVal => String.fromCharCode(parseInt(hexVal, 16)))
      .join('');

    return decodedText;
  } catch (error) {
    return error.message;
  }
}

export const WingdingsText = (plainText) => {
  const plainChars = {
    A: '✌︎', B: '👌︎', C: '👍︎', D: '👎︎',
    E: '☜︎', F: '☞︎', G: '☝︎', H: '☟︎',
    I: '✋︎', J: '☺︎', K: '😐︎', L: '☹︎',
    M: '💣︎', N: '☠︎', O: '⚐︎', P: '🏱︎',
    Q: '✈︎', R: '☼︎', S: '💧︎', T: '❄︎',
    U: '🕆︎', V: '✞︎', W: '🕈︎', X: '✠︎',
    Y: '✡︎', Z: '☪︎',
    a: '♋︎', b: '♌︎', c: '♍︎', d: '♎︎',
    e: '♏︎', f: '♐︎', g: '♑︎', h: '♒︎',
    i: '♓︎', j: '🙰', k: '🙵', l: '●︎',
    m: '❍︎', n: '■︎', o: '□︎', p: '◻︎',
    q: '❑︎', r: '❒︎', s: '⬧︎', t: '⧫︎',
    u: '◆︎', v: '❖︎', w: '⬥︎', x: '⌧︎',
    y: '⍓︎', z: '⌘︎',
    0: '📁︎', 1: '📂︎', 2: '📄︎', 3: '🗏︎',
    4: '🗐︎', 5: '🗄︎', 6: '⌛︎', 7: '🖮︎',
    8: '🖰︎', 9: '🖲︎',
    '!': '✏︎', '"': '✂︎', '#': '✁︎', '$': '👓︎',
    '%': '🕭︎', '&': '🕮︎', '\'': 'ॐ︎', '(': '🕿︎',
    ')': '✆︎', '*': '🖂︎', '+': '🖃︎', ',': '📪︎',
    '-': '📫︎', '.': '📬︎', '/': '📭︎', ':': '🖴︎',
    ';': '🖫︎', '<': '🖬︎', '=': '✇︎', '>': '✍︎',
    '@': '@', '[': '☯︎', '\\': '\\', ']': '☸︎',
    '^': '♈︎', '_': '♉︎', '`': '♊︎', '{': '❀︎',
    '|': '✿︎', '}': '❝︎', '~': '❞︎'
  };

  let wingdingsText = '';

  for (let i = 0; i < plainText.length; i++) {
    const char = plainText[i];
    if (plainChars.hasOwnProperty(char)) {
      wingdingsText += plainChars[char];
    } else {
      wingdingsText += char;
    }
  }

  return wingdingsText;
};

export const WingdingsToPlainConverter = (wingdingsText) => {
  const wingdingsChars = {
    '✌︎': 'A', '👌︎': 'B', '👍︎': 'C', '👎︎': 'D',
    '☜︎': 'E', '☞︎': 'F', '☝︎': 'G', '☟︎': 'H',
    '✋︎': 'I', '☺︎': 'J', '😐︎': 'K', '☹︎': 'L',
    '💣︎': 'M', '☠︎': 'N', '⚐︎': 'O', '🏱︎': 'P',
    '✈︎': 'Q', '☼︎': 'R', '💧︎': 'S', '❄︎': 'T',
    '🕆︎': 'U', '✞︎': 'V', '🕈︎': 'W', '✠︎': 'X',
    '✡︎': 'Y', '☪︎': 'Z',
    '♋︎': 'a', '♌︎': 'b', '♍︎': 'c', '♎︎': 'd',
    '♏︎': 'e', '♐︎': 'f', '♑︎': 'g', '♒︎': 'h',
    '♓︎': 'i', '🙰': 'j', '🙵': 'k', '●︎': 'l',
    '❍︎': 'm', '■︎': 'n', '□︎': 'o', '◻︎': 'p',
    '❑︎': 'q', '❒︎': 'r', '⬧︎': 's', '⧫︎': 't',
    '◆︎': 'u', '❖︎': 'v', '⬥︎': 'w', '⌧︎': 'x',
    '⍓︎': 'y', '⌘︎': 'z',
    '📁︎': '0', '📂︎': '1', '📄︎': '2', '🗏︎': '3',
    '🗐︎': '4', '🗄︎': '5', '⌛︎': '6', '🖮︎': '7',
    '🖰︎': '8', '🖲︎': '9',
    '✏︎': '!', '✂︎': '"', '✁︎': '#', '👓︎': '$',
    '🕭︎': '%', '🕮︎': '&', 'ॐ︎': '\'', '🕿︎': '(',
    '✆︎': ')', '🖂︎': '*', '🖃︎': '+', '📪︎': ',',
    '📫︎': '-', '📬︎': '.', '📭︎': '/', '🖴︎': ':',
    '🖫︎': ';', '🖬︎': '<', '✇︎': '=', '✍︎': '>',
    '@': '@', '☯︎': '[', '\\': '\\', '☸︎': ']',
    '^': '^^', '_': '_', '`': '`', '{': '{',
    '|': '|', '}': '}', '~': '~'
  };

  let currentIndex = 0;
  let plainText = '';

  while (currentIndex < wingdingsText.length) {
    let found = false;

    for (let len = 6; len >= 1; len--) {
      const substring = wingdingsText.substr(currentIndex, len);
      if (wingdingsChars.hasOwnProperty(substring)) {
        plainText += wingdingsChars[substring];
        currentIndex += len;
        found = true;
        break;
      }
    }

    if (!found) {
      plainText += wingdingsText[currentIndex];
      currentIndex++;
    }
  }

  return plainText;
};

export const VaporwaveText = (text) => {
  const vaporwaveChars = {
    'A': 'Ａ', 'B': 'Ｂ', 'C': 'Ｃ', 'D': 'Ｄ', 'E': 'Ｅ', 'F': 'Ｆ', 'G': 'Ｇ',
    'H': 'Ｈ', 'I': 'Ｉ', 'J': 'Ｊ', 'K': 'Ｋ', 'L': 'Ｌ', 'M': 'Ｍ', 'N': 'Ｎ',
    'O': 'Ｏ', 'P': 'Ｐ', 'Q': 'Ｑ', 'R': 'Ｒ', 'S': 'Ｓ', 'T': 'Ｔ', 'U': 'Ｕ',
    'V': 'Ｖ', 'W': 'Ｗ', 'X': 'Ｘ', 'Y': 'Ｙ', 'Z': 'Ｚ',
    'a': 'ａ', 'b': 'ｂ', 'c': 'ｃ', 'd': 'ｄ', 'e': 'ｅ', 'f': 'ｆ', 'g': 'ｇ',
    'h': 'ｈ', 'i': 'ｉ', 'j': 'ｊ', 'k': 'ｋ', 'l': 'ｌ', 'm': 'ｍ', 'n': 'ｎ',
    'o': 'ｏ', 'p': 'ｐ', 'q': 'ｑ', 'r': 'ｒ', 's': 'ｓ', 't': 'ｔ', 'u': 'ｕ',
    'v': 'ｖ', 'w': 'ｗ', 'x': 'ｘ', 'y': 'ｙ', 'z': 'ｚ',
    '0': '０', '1': '１', '2': '２', '3': '３', '4': '４', '5': '５', '6': '６',
    '7': '７', '8': '８', '9': '９',
    ' ': '　', ',': '，', ';': '；', ':': '：', '?': '？', '!': '！',
    '‘': '‘', '“': '“', '”': '”', '’': '’', '(': '（', ')': '）',
    '/': '／', '\\': '＼', '-': '－', '=': '＝', '+': '＋'
  };

  return text
    .split('')
    .map((char) => vaporwaveChars[char] || char)
    .join('');
};
//Cursor Text: 
// Output: Ｈｅｌｌｏ， ｗｏｒｌｄ！

export const PlaintoEmoji = (text) => {
  const emojiChars = {
    // Uppercase Letters
    A: '🅰️', B: '🅱️', C: '©️', D: '🇩', E: '🇪', F: '🇫', G: '🇬', H: '🇭',
    I: 'ℹ️', J: '🇯', K: '🇰', L: '🇱', M: '🇲', N: '🇳', O: '🅾️', P: '🅿️',
    Q: '🇶', R: '®️', S: '💲', T: '🇹', U: '🇺', V: '🇻', W: '🇼', X: '❌',
    Y: '🇾', Z: '🇿',

    // Lowercase Letters
    a: '🅰️', b: '🅱️', c: '©️', d: '🇩', e: '🇪', f: '🇫', g: '🇬', h: '🇭',
    i: 'ℹ️', j: '🇯', k: '🇰', l: '🇱', m: '🇲', n: '🇳', o: '🅾️', p: '🅿️',
    q: '🇶', r: '®️', s: '💲', t: '🇹', u: '🇺', v: '🇻', w: '🇼', x: '❌',
    y: '🇾', z: '🇿',

    // Numbers
    '0': '0️⃣', '1': '1️⃣', '2': '2️⃣', '3': '3️⃣', '4': '4️⃣',
    '5': '5️⃣', '6': '6️⃣', '7': '7️⃣', '8': '8️⃣', '9': '9️⃣',

    // Symbols
    '!': '❗️', '@': '📧', '#': '#️⃣', '$': '💲', '%': '💯',
    '^': '⬆️', '&': '➡️', '*': '✳️', '(': '🙃', ')': '🙃',
    '-': '➖', '_': '_', '=': '➡️', '+': '➕', '[': '🙃',
    ']': '🙃', '{': '🙃', '}': '🙃', ';': '😉', "'": '📜',
    '"': '📜', ',': '📜', '.': '📜', '/': '📜', '?': '❓',
    '<': '⬅️', '>': '➡️'
  };

  return text
    .split('')
    .map((char) => emojiChars[char] || char)
    .join('');
};
//Unicode

//Remain--
export const plainToBraille = (text) => {
  const plainToBrailleMapper = {
    'a': '⠁', 'b': '⠃', 'c': '⠉', 'd': '⠙', 'e': '⠑',
    'f': '⠋', 'g': '⠛', 'h': '⠓', 'i': '⠊', 'j': '⠚',
    'k': '⠅', 'l': '⠇', 'm': '⠍', 'n': '⠝', 'o': '⠕',
    'p': '⠏', 'q': '⠟', 'r': '⠗', 's': '⠎', 't': '⠞',
    'u': '⠥', 'v': '⠧', 'w': '⠺', 'x': '⠭', 'y': '⠽',
    'z': '⠵', 'A': '⠨⠁', 'B': '⠨⠃', 'C': '⠨⠉',
    'D': '⠨⠙', 'E': '⠨⠑', 'F': '⠨⠋', 'G': '⠨⠛',
    'H': '⠨⠓', 'I': '⠨⠊', 'J': '⠨⠚', 'K': '⠨⠅',
    'L': '⠨⠇', 'M': '⠨⠍', 'N': '⠨⠝', 'O': '⠨⠕',
    'P': '⠨⠏', 'Q': '⠨⠟', 'R': '⠨⠗', 'S': '⠨⠎',
    'T': '⠨⠞', 'U': '⠨⠥', 'V': '⠨⠧', 'W': '⠨⠺',
    'X': '⠨⠭', 'Y': '⠨⠽', 'Z': '⠨⠵', '1': '⠼⠁',
    '2': '⠼⠃', '3': '⠼⠉', '4': '⠼⠙', '5': '⠼⠑',
    '6': '⠼⠋', '7': '⠼⠛', '8': '⠼⠓', '9': '⠼⠊',
    '0': '⠼⠚', ',': '⠂', ';': '⠆', ':': '⠒', '⠲': '.',
    '?': '⠦', '!': '⠖', '‘': '⠄', '“': '⠄⠶', '”': '⠘⠦',
    '’': '⠄⠴', '(': '⠐⠣', ')': '⠐⠜', '/': '⠸⠌',
    '\\': '⠸⠡', '-': '⠠⠤', '—': '⠐⠠⠤'
    // Add more mappings here
  };

  return text
    .split('')
    .map((char) => plainToBrailleMapper[char] || char)
    .join('');
};

function convertToCurrencySymbols(text) {
  const currencyMap = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', AUD: 'A$', CAD: 'C$',
    CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$', KRW: '₩', INR: '₹',
    RUB: '₽', TRY: '₺', ZAR: 'R', BRL: 'R$', SAR: '﷼', AED: 'د.إ',
    SGD: 'S$', HKD: 'HK$', TWD: 'NT$', MYR: 'RM', THB: '฿', IDR: 'Rp',
    PHP: '₱', PLN: 'zł', HUF: 'Ft', CZK: 'Kč', MXN: 'Mex$', ILS: '₪',
    CLP: 'CLP$', COP: 'Col$', ARS: 'AR$', VND: '₫', NGN: '₦', UAH: '₴',
    BGN: 'лв', DKK: 'kr', NOK: 'kr', RON: 'lei', KRW: '₩', LKR: '₨',
    // Add more currency codes and symbols as needed
  };

  const regex = /\b(USD|EUR|GBP|JPY|AUD|CAD|CHF|CNY|SEK|NZD|KRW|INR|RUB|TRY|ZAR|BRL|SAR|AED|SGD|HKD|TWD|MYR|THB|IDR|PHP|PLN|HUF|CZK|MXN|ILS|CLP|COP|ARS|VND|NGN|UAH|BGN|DKK|NOK|RON|KRW|LKR)\b/g;

  return text.replace(regex, match => currencyMap[match] || match);
}


export const RainbowText = (text) => {
  const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'];
  let rainbowText = '';

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const colorIndex = i % colors.length;
    const color = colors[colorIndex];

    rainbowText += `<span style="color: ${color};">${char}</span>`;
  }

  return rainbowText;
};

