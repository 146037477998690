import React, { useEffect, useState, useRef } from 'react';
import ReminderTone from './Ringtone/ReminderTone.wav';
import Popup from './ComponentPopup';

const RingtonePlayer = ({ reminders, deleteReminder, activateReminder }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [activeReminderInfo, setActiveReminderInfo] = useState(null);
  const [playingReminder, setPlayingReminder] = useState(null);

  const audioRef = useRef(null);
  const timeoutRef = useRef(null);

  // const stopAlarm = () => {
  //   if (audioRef.current) {
  //     audioRef.current.pause();
  //     audioRef.current.currentTime = 0;
  //   }
  //   setIsPopupVisible(false);
  //   console.log('Alarm stopped');
  // };

  const stopAlarm = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.removeEventListener('ended', onAudioEnded); // Remove the 'ended' event listener
      audioRef.current = null; // Remove the reference to the audio element
    }
    setIsPopupVisible(false);
    console.log('Alarm stopped');
  };

  const onAudioEnded = () => {
    audioRef.current = null; // Remove the reference to the audio element when it ends
    console.log('Audio ended');
    setIsPopupVisible(false);
    // Do any other cleanup if needed
  };


  const handleStopClick = (id) => {
    console.log('Clicked on id:', id); // Log the clicked id
    stopAlarm();
    if (id) {
      console.log('Deleting reminder with id:', id); // Log the id being deleted
      deleteReminder(id);
      setActiveReminderInfo(null);
    }
  };

  useEffect(() => {
    const playNextReminder = () => {
      const nextReminder = reminders.find((reminder) => reminder.id !== playingReminder?.id);

      if (nextReminder) {
        playRingtone(nextReminder);
      } else {
        stopAlarm();
      }
    };

    const playRingtone = (reminder) => {
      const audio = new Audio(ReminderTone);

      audio.addEventListener('ended', () => {
        audio.pause();
        audio.currentTime = 0;
        console.log('Audio ended');
        handleStopClick(reminder.id);
        playNextReminder(); // Play the next reminder, if available
      });

      audioRef.current = audio;
      setActiveReminderInfo({ ...reminder, deleteTime: new Date().toLocaleString() });
      activateReminder(reminder);
      setIsPopupVisible(true);

      // Check if the audio is playing before attempting to pause it
      if (!audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }

      audio.play();
      console.log('Reminder activated:', reminder);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      setPlayingReminder(reminder);
    };

    reminders.forEach((reminder) => {
      const targetTime = new Date(`${reminder.date}T${reminder.time}`).getTime();
      const now = new Date().getTime();
      const timeDifference = targetTime - now;

      if (timeDifference <= 0) {
        playRingtone(reminder);
      } else {
        const countdown = Math.max(0, timeDifference);
        setTimeout(() => {
          playRingtone(reminder);
        }, countdown);
      }
    });

    return () => {
      if (playingReminder) {
        timeoutRef.current = setTimeout(() => {
          if (activeReminderInfo && activeReminderInfo.id === playingReminder.id) {
            handleStopClick(playingReminder.id);
          }
        }, 60000);
      }
    };
  }, [reminders, playingReminder]);

  return (
    <>
      {isPopupVisible && (
        <Popup
          activeReminderInfo={activeReminderInfo}
          stopAlarm={() => handleStopClick(activeReminderInfo.id)}
          deleteReminder={deleteReminder}
        />
      )}
    </>
  );
};

export default RingtonePlayer;
