import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const ReplaceInputs = ({ handleFind, handleReplace, find, replace, caseSensitive, handleReplaceSubmit, handleCaseSensitiveChange }) => {

    const labelStyle = {
        padding: '5px',
        paddingLeft: '18px',
        marginTop: '5px',
    };
    const checkStyle = {
        padding: '5px',
        paddingLeft: '18px',

    };
    return (
        <Grid container className="row-min" spacing={2}>

            <div style={labelStyle}>
                <label htmlFor="find">Find:&nbsp;&nbsp;</label>
                <input
                    id="find"
                    type="text"
                    value={find}
                    className="text-input"
                    onChange={handleFind}
                    style={{ padding: '5px' }}
                />
            </div>
            &nbsp;&nbsp;
            <div style={labelStyle}>
                <label htmlFor="replace">Replace:&nbsp;&nbsp;</label>

                <input
                    id="replace"
                    type="text"
                    value={replace}
                    className="text-input"
                    onChange={handleReplace}
                    style={{ padding: '5px' }}
                />
            </div>
            <div style={checkStyle}>
                <FormControlLabel
                    control={<Checkbox checked={caseSensitive} onChange={handleCaseSensitiveChange} />}
                    label="Case Sensitive"
                />
            </div>
            <div style={labelStyle}>
                <Button onClick={() => handleReplaceSubmit(find, replace, caseSensitive)} variant="contained" size="small">
                    Replace
                </Button>

            </div>

        </Grid>
    );
}

export default ReplaceInputs;
