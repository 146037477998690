import React from 'react';
import { Container } from '@mui/material';
import Header from './components/common/Header';
import Footer from './components/common/Footer';

const MasterPage = ({ children }) => {
  return (
    <div>
      <Header />
      <Container maxWidth="lg">
        <main>{children}</main>
      </Container>
      <Footer />
    </div>
  );
};

export default MasterPage;
