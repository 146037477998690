import React from 'react';
import { styled } from '@mui/material';
import { Button, TextareaAutosize } from '@mui/material';
import DOMPurify from 'dompurify';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const StyledTextareaAutosize = styled(TextareaAutosize)`
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  resize: none;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const StyledClearButton = styled(Button)`
  border: 1px solid rgba(176, 176, 176, 0.5);
  text-transform: none;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.6);
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Light red */
    border-color: rgba(255, 0, 0, 0.4); /* Low red */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }


`;

export const StyledCopyButton = styled(Button)` 
  border: 1px solid rgba(176, 176, 176, 0.5);
  text-transform: none;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.6);
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 255, 0, 0.1); /* Light green */
    border-color: rgba(0, 255, 0, 0.4); /* Low green */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }

  &.copied {
    color: #4caf50;
    background-color: rgba(76, 175, 80, 0.04);
    border-color: rgba(76, 175, 80, 0.23);

    &:hover {
      background-color: rgba(76, 175, 80, 0.1);
    }
  }
`;

export const StyledButtonIcon = styled(Button)`
  border: none;
  outline: none;
  background-color: transparent;
  color: #4caf50;
  box-shadow: none;
  min-width: 40px;
  padding: 2px;
  border-radius: 4px;
  margin-right: 5px;

  &:hover {
    background-color: transparent;
    color: #000000;
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`;

export const StyledButton = styled(Button)`
   text-transform: none;
`;
//Copy button for string only
export const Icon = styled(IconButton)`
   text-transform: none;
`;
//Styled Function Div Similar like TextArea
const StyledDivTextArea = styled('div')`
  /* Your existing styles here */
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  resize: none;

  white-space: pre-wrap;
  word-break: break-word;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

// function Allow only this Html Tag Provided Security --Not Tested
export const sanitizeHTML = (html) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['p', 'a', 'b', 'i', 'em', 'strong', 'ul', 'li', 'mark'], // Add more tags as needed
  });
};

//Style div similar TextArea with html support Editble --Not Tested
export const HtmlTextArea = React.forwardRef(
  ({ htmlContent, onHtmlChange, className, id, placeholder, maxLength }, ref) => {
    const handleBlur = (event) => {
      const newHtmlContent = event.target.innerHTML;
      onHtmlChange(newHtmlContent);
    };

    return (
      <StyledDivTextArea
        contentEditable
        // Set the initial text content directly, preserving whitespace and line breaks
        ref={ref}
        onBlur={handleBlur}
        className={className}
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        dangerouslySetInnerHTML={{ __html: htmlContent }} // Set the initial HTML content
      >
      </StyledDivTextArea>
    );
  }
);

//Style div similar TextArea Support htmlContent(Direct pass html use htmlContent) 
export const DivTextArea = React.forwardRef(({ htmlContent, className, id }, ref) => {
  return (
    <StyledDivTextArea
      dangerouslySetInnerHTML={{ __html: htmlContent || '' }}
      className={className}
      id={id}
      ref={ref}
    />
  );
});

//Style div similar TextArea but Can Add Inner Text
export const DivInnerTextArea = React.forwardRef(({ className, id, children }, ref) => {
  return (
    <StyledDivTextArea
      className={className}
      id={id}
      ref={ref}
    >
      {
        children
      }
    </StyledDivTextArea>
  );
});
